@import "~@/erp/styles/variables/variables.scss";























































































































































































































































































































































































































.custom-dialog {
  .wrap {
    overflow-y: auto;
    max-height: 360px;

    .container {
      border: 1px solid #ebeef5;
      border-radius: 4px;

      .top {
        padding: 0 20px;
        /* line-height: 39px; */
        border-bottom: 1px solid #ebeef5;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: baseline;

        .left {
          flex-shrink: 1;
          /* 当空间不足时，.left可以缩小 */
          word-wrap: break-word;
          /* 文本超长时自动换行 */
          line-height: 20px;
          padding: 5px 0;
          display: flex;

          .left-content {
            flex-shrink: 1;
            /* 当空间不足时，.left可以缩小 */
            word-wrap: break-word;
          }

          .left-label {
            flex-shrink: 0;
          }
        }

        .center,
        .right {
          flex-grow: 1;
          /* 当空间有多余时，.right可以放大 */
          flex-shrink: 0;
          /* 当空间不足时，.right不缩小 */
          /* 可以设置min-width等样式来确保最小宽度 */

        }

        .right {
          margin-left: 10px;
          text-align: right;
        }
      }

      .result {
        text-align: right;

        em {
          font-style: normal;
        }
      }

      .intro {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;

        // max-height: 120px;
        // overflow-y: auto;
        >div {
          width: 50%;

          img {
            width: 100%;
            padding: 0 120px 20px 0;
          }
        }
      }

      .custom-table {
        display: table;
      }

      .form {
        padding: 0 15px;
      }

      .images {
        img {
          display: inline-block;
          width: 90px;
          height: 90px;
          cursor: pointer;
          border-radius: 4px;
          margin-right: 10px;
        }
      }
    }
  }

  p {
    margin: 10px 0;
  }
}

::v-deep {
  .el-upload {
    margin-bottom: 10px;
    width: 90px;
    height: 90px;

    i {
      display: block;
      line-height: 90px;
    }
  }

  .el-upload-list__item {
    width: 90px;
    height: 90px;
    object-fit: cover;
  }
}

.viewer-container {
  z-index: 99999 !important;
}
