@import "~@/erp/styles/variables/variables.scss";



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.custom-dialog {
  .el-form-item {
    margin-bottom: 18px;
  }
  p {
    margin: 10px 0;
  }
  .stock {
    position: relative;
    padding-right: 30px;
    .el-button {
      width: 26px;
      height: 26px;
      position: absolute;
      top: -10px;
      right: -5px;
    }
  }
  .column-sty {
    display: flex;
    .el-input {
      padding-right: 5px;
    }
    i {
      font-size: 24px;
      cursor: pointer;
      margin-top: 5px;
      color: #409eff;
    }
  }
}
::v-deep {
  .el-dialog__body {
    margin-top: -15px;
  }
  .icon-bangding {
    line-height: 0;
    font-size: 14px;
    margin-left: -1px;
  }
  .expand-all {
    cursor: pointer;
  }
  &.active {
    transform: rotate(90deg);
  }
}
.expand-table {
  margin: 10px 0 10px 0;
  ::v-deep tr {
    height: auto;
    th.el-table__cell {
      height: 32px;
      padding: 4px 0;
    }
    td.el-table__cell {
      height: 40px;
    }
    .el-form-item {
      padding: 5px 0 !important;
    }
  }
}
