@import "~@/erp/styles/variables/variables.scss";
















































































































































::v-deep {
  .font-class {
    text-align: right;
    line-height: 18px;
    padding-right: 10px;
    margin-top: 10px;
  }
}
