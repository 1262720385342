@import "~@/erp/styles/variables/variables.scss";

















































































































































































.drawer-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.drawer-content {
  padding: 20px 0px 20px 40px;
  .nameAndTime {
    .time {
      margin-left: 10px;
    }
  }
  .content {
    margin-top: 5px;
    width: 300px;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; // 控制多行的行数
    -webkit-box-orient: vertical;
  }
}
.popperStyle {
  display: inline-block;
  max-width: 350px !important;
  line-height: 18px;
}
