@import "~@/erp/styles/variables/variables.scss";








































































































































































































































































































































































































































































































.oper {
  padding: 20px 10px;
  height: calc(100vh - 45px);
  overflow-y: scroll;
  .item-title {
    text-align: right;
    padding: 0px !important;
    transform: translateX(10px);
  }
  .item-title::before {
    content: '';
    border-right: 7px solid blue;
    margin-right: 5px;
  }
  .m30 {
    margin-top: 30px;
  }
  .stick30 {
    position: sticky;
    bottom: 30px;
  }
  .m10 {
    margin-top: 10px;
  }
}
