@import "~@/erp/styles/variables/variables.scss";































































































































































































































































.pro-container {
  .left-h6 {
    display: flex;
    font-size: 16px;
    font-family: 'PingFang Bold';
  }
  .left-h6::before {
    content: '';
    display: block;
    height: 16px;
    width: 2px;
    background: #282c34;
    margin-right: 5px;
    margin-top: 4px;
  }
  .right-content {
    height: 100%;
    border: 1px solid #e4e7ed;
    padding: 20px !important;
    border-radius: 3px;
  }
}
