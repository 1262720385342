@import "~@/erp/styles/variables/variables.scss";



































.erp-mail {
  display: flex;
  background: #f5f7fa;
  height: calc(100vh - 65px - 60px);
  overflow: hidden;

  .content-board {
    flex: 1;
    width: 0;
    height: 100%;
  }
}
