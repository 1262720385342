@import "~@/erp/styles/variables/variables.scss";





















































































































































































::v-deep {
  .el-input__inner {
    padding-right: 50px !important;
  }
}

.multiple-search-wrapper {
  position: relative;

  .mr5 {
    margin-right: 5px;
  }

  i {
    cursor: pointer;
  }
  .down {
    position: absolute;
    top: 37px;
    z-index: 999;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #dcdfe6;
    padding: 10px;
    box-sizing: border-box;
  }
  .tr {
    margin-top: 8px;
    text-align: right;
  }
  .textarea {
    .el-textarea__inner {
      height: 170px;
    }
  }
  ::v-deep {
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }
}
