@import "~@/erp/styles/variables/variables.scss";



































































































































































.aside {
  display: flex;
  height: 100%;
  flex-direction: column;
  border-right: 1px solid #f5f7fa;
  .aside-head-wrapper {
    padding: 10px;
  }
  .aside-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .label {
      font-size: 14px;
      color: #666;
      flex: 1;
    }
  }
  .user-list {
    min-height: 300px;
    flex: 1;
    overflow-y: auto;
    .item {
      display: flex;
      align-items: center;
      height: 40px;
      margin-bottom: 6px;
      cursor: pointer;
      user-select: none;
      padding: 10px 10px;
      line-height: 20px;
      &:hover,
      &.active {
        background-color: #f8f8f8;
      }
    }
    .name {
      flex: 1;
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      color: #333;
      &.leave {
        color: #f00;
      }
    }
    .count {
      min-width: 16px;
      height: 16px;
      text-align: center;
      padding: 0 4px;
      border-radius: 10px;
      line-height: 16px;
      background-color: #f33;
      color: #fff;
      font-size: 12px;
      font-style: normal;
    }
  }
}
