@import "~@/erp/styles/variables/variables.scss";


























































































































































































































































































































































































































































































































































































































































































.main-height {
  height: calc(100% - 52px);
}
.table-height-box {
  height: calc(100% - 4px);
  overflow: hidden;
  overflow-y: auto;
}
.public-col {
  height: 87vh;
  border: 1px solid #ededed;
  padding: 10px;
  .el-table {
    height: calc(100% - 62px);
    overflow-y: auto;
    margin-bottom: 10px;
    border-bottom: 1px solid #ededed;
  }
  p {
    line-height: 32px;
  }
}
.nature-row {
  height: 40vh;
}
.public-col-2 {
  height: 45vh;
  margin-top: 20px;
}
::v-deep {
  .el-tag {
    border: none;
  }
  .el-tag--plain {
    background: none;
  }
}
