@import "~@/erp/styles/variables/variables.scss";








































































































































































































































.index-wrapper {
  overflow-x: auto;
}
.index-container.index-wrapper {
  height: 100%;
  overflow: auto !important;
  background-color: #f5f7fa !important;
  padding: 11px !important;
}
.card-wrapper {
  margin-top: 10px;
}
