@import "~@/erp/styles/variables/variables.scss";


























































::v-deep {
  .dialog-footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
