@import "~@/erp/styles/variables/variables.scss";








































































::v-deep {
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .el-dialog__header {
    > .el-dialog__title {
      font-size: 14px;
    }
  }
  .el-dialog__footer {
    padding-top: 0;
    text-align: center;
  }
}
