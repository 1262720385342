@import "~@/erp/styles/variables/variables.scss";











































































































































































.import-dialog {
  .row {
    display: flex;
    margin-bottom: 20px;
    dt {
      font-size: 20px;
      color: #666;
    }
    dd {
      flex: 1;
    }
    .el-link {
      font-size: 16px;
      line-height: 32px;
    }
    .wait-upload {
      display: flex;
      max-width: 100%;
      align-items: center;
      margin-top: 10px;
      .label {
        margin-right: 10px;
      }
      .name {
        font-size: 14px;
        color: #333;
      }
      .icon-close {
        color: #ccc;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        margin-left: 10px;
        &:hover {
          color: #1890ff;
        }
      }
    }
    .desc {
      font-size: 14px;
      color: #999;
      line-height: 24px;
      margin-top: 4px;
    }
  }
  .tips {
    font-size: 14px;
    color: #999;
    line-height: 24px;
  }
  .dialog-footer {
    text-align: center;
  }
}
