@import "~@/erp/styles/variables/variables.scss";





























































































































































































































































































































































































































































































































































































































































































































































































































.custom-container {
  overflow-y: hidden !important;
}

.page-header {
  height: 33px;
}

::v-deep {
  .page-header {
    .el-form-item {
      margin-bottom: 0;
    }
  }
}

.divider {
  height: 1px;
  background-color: #f5f7fa;
  margin: 9px 0 15px 0;
}

i {
  font-size: 18px;
  margin: 0 6px;
}

.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}

.error-color {
  color: #f56c6c;
}

.success-color {
  color: #67c23a;
}

.warning-color {
  color: #e6a23c;
}

.pro-container {
  overflow-y: hidden !important;
}
