@import "~@/erp/styles/variables/variables.scss";

















































































































































































































































































































































































































































































.platform-product {
  .search-form {
    /deep/.el-form-item__content {
      width: 170px;
    }
  }
  .prod_code {
    color: #409eff;
    text-decoration: underline;
    cursor: pointer;
  }
}
