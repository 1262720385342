@import "~@/erp/styles/variables/variables.scss";
























































































































































































































































































.order-product {
  .container {
    height: 550px;
    overflow-y: auto;
  }
}
