@import "~@/erp/styles/variables/variables.scss";












































































































































































































































































































.custom-dialog {
  .container {
    min-height: 300px;
    .el-table {
      max-height: 360px;
      overflow-y: auto;
    }
    .el-form-item {
      margin-bottom: 18px;
    }
    p {
      text-align: left;
      line-height: 24px;
    }
  }
  .tips {
    color: #999;
  }
  .empty {
    text-align: center;
    p {
      margin: 80px 0;
    }
  }

  .img-wrap {
    position: relative;
    padding: 3px 0;
    width: 50px;
    margin: 0 auto;
    .el-image {
      width: 50px;
      height: 50px;
      object-fit: cover;
      display: block;
    }
    .el-badge {
      position: absolute;
      top: 3px;
      right: -10px;
      z-index: 2;
    }
  }
}
