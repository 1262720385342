@import "~@/erp/styles/variables/variables.scss";











































































































































































































































































































































































































































































































































































































































































::v-deep {
  .el-table .el-form-item__content {
    margin-left: 0 !important;
  }

  .topTable.el-table .el-table__row td {
    padding-bottom: 0;
  }

  .el-table .el-form-item__error {
    top: 106%;
  }

  .middleTable {
    margin-top: 10px;
  }

  .label label {
    margin: 10px 0;
    text-align: left !important;
  }

  .label .el-form-item__content {
    margin-left: 0 !important;
  }

  .el-dialog__footer {
    text-align: center;
    padding-top: 0;
  }

  .upload {
    label {
      width: 100% !important;
      text-align: left;
    }

    .el-form-item__content {
      margin-left: 0 !important;
    }
  }

  .el-table.topTable th .cell::before {
    content: '*';
    color: red;
    position: relative;
    top: 0;
    left: -6px;
  }

  .el-table.topTable th:nth-child(5) .cell::before {
    content: '';
  }
}

.lineheight {
  position: relative;
  top: -5px;
}
