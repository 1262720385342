@import "~@/erp/styles/variables/variables.scss";





























































.ctn {
  margin-top: 20px;
  width: 100%;
  min-height: 100vh;
}
