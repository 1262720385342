@import "~@/erp/styles/variables/variables.scss";






































































































































































































































































































































































































































































































































































































































































































































































































































































.track-node {
  padding-left: 50px;
  .node {
    display: flex;
    .L {
      width: 40px;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      .el-step__icon {
        width: 28px;
        height: 28px;
        font-size: 16px;
        background: #fff;
        color: #409eff;
        border-radius: 50%;
        border: 2px solid;
        margin-top: 10px;
        i {
          display: inline-block;
          text-align: center;
          font-weight: 600;
          line-height: 1;
        }
      }
      .oper {
        margin-top: 5px;
        i {
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
    .R {
      padding: 0 0 10px 20px;
      width: 1000px;
      position: relative;
      .state {
        position: absolute;
        top: 35px;
        right: -120px;
        font-size: 24px;
        font-weight: 600;
        transform: rotate(30deg);
        text-shadow: 3px 3px 3px #ddd;
        z-index: 1;
      }
      .state.arrange {
        color: #aaa;
      }
      .state.success {
        color: #67c23a;
      }
      .state.dange {
        color: #f56c6c;
      }
      .tips {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .file_name {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.el-form-item {
  margin: 0;
}
::v-deep {
  .el-input-group__append {
    background: none;
    padding: 0 10px;
  }
  .radio-style {
    color: #fff;
    margin-left: 15px;
    .el-radio__label {
      margin-left: 30px;
    }
  }
}
