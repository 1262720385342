@import "~@/erp/styles/variables/variables.scss";






















































































































































































































































.inline-block {
  display: inline-block;
  margin-right: 30px;
}
