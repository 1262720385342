@import "~@/erp/styles/variables/variables.scss";


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.audit-row {
  height: 100%;
  overflow: hidden;

  .audit-left {
    height: 100%;
    padding: 10px;
    overflow-y: auto;

    h4 {
      border-bottom: 1px solid #ededed;
      padding-bottom: 12px;
      font-size: 15px;
    }

    ul {
      li {
        height: 40px;
        line-height: 40px;
        padding-left: 10%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          display: inline-block;
          line-height: 15px;
          font-size: 12px;
          text-align: center;
          background: #f56c6c;
          color: #fff;
          padding: 2px 5px;
          border-radius: 4px;
        }
      }

      li.current {
        color: #1890ff;
      }
    }
  }

  .audit-right {
    position: relative;

    .area-radio {
      position: absolute;
      right: 20px;
      top: 12px;
    }
  }
}

.span-ovh {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.blue-text {
  text-decoration: underline;
}

.supplierName-row {
  .supplierName-span {
    width: 90%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .supplierName-p {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
  }
}

::v-deep {
  .el-tabs__nav-wrap::after {
    height: 1px;
  }
}

.dot-span {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  font-size: 12px;
  background: #409eff;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 5px;
}

.audit-content-wrapper {
  padding-bottom: 100px;
  > h4 {
    padding-left: 30px;
    color: #0486fe;
  }
}
.drawer-title{
  display: flex;
  align-items: center;
  span{
    margin-left: 6px;
  }
}
