@import "~@/erp/styles/variables/variables.scss";



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.pro-container {
  .left-h6 {
    display: flex;
    font-size: 16px;
    font-family: 'PingFang Bold';
  }

  .left-h6::before {
    content: '';
    display: block;
    height: 16px;
    width: 2px;
    background: #282c34;
    margin-right: 5px;
    margin-top: 4px;
  }

  .right-content {
    height: 100%;
    border: 1px solid #e4e7ed;
    padding: 20px;
    border-radius: 3px;
  }
}

.draggable-row {
  >div {
    text-align: left;
  }
}

::v-deep {
  .el-tag {
    margin-top: 5px;
  }

  .el-tag+.el-tag {
    margin-left: 10px;
  }

  .saveTagInput-row {
    .el-input--small .el-input__inner {
      height: 32px !important;
    }
  }

  .down-tag {
    font-size: 12px;
    margin-top: 10px;
  }
}

.wrapper-box {
  .wrapper-add {
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    border: 1px dotted #c0cbda;
    border-radius: 3px;
    cursor: pointer;

    .el-row {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      span {
        font-size: 12px;
        color: #9e9e9e;
      }

      i {
        color: #9e9e9e;
      }
    }
  }
}

.sort {
  text-align: center;
  cursor: all-scroll;

  i {
    font-size: 18px;
  }
}

.iconfont {
  cursor: pointer;
  font-size: 14px;
}

.icon-xiajia {
  color: #1890ff;
}

.ml-10 {
  margin-left: -10px;
}
