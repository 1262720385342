@import "~@/erp/styles/variables/variables.scss";












































































































































































.unread-mails-item {
  font-size: 14px;
  .mails-item--top {
    margin-bottom: 6px;
    word-break: break-all;
    color: #000;
  }
  .mails-item--middle {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    p {
      color: #5e5e5e;
      font-size: 12px;
    }
  }
  .mails-item--bottom {
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    margin-top: 5px;
  }
  .noline {
    text-decoration: none;
  }
}
