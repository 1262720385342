@import "~@/erp/styles/variables/variables.scss";













































































































































































































.other-setting-wrapper {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 52px;
  .top {
    flex: 1;
  }
  .bottom {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 10px;
  }
  .configuration-list-box {
    margin-top: 20px;
  }
  ::v-deep .dispatch-mode {
    .el-checkbox__label {
      font-size: 15px;
      color: #000;
    }
  }
}
