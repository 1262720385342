@import "~@/erp/styles/variables/variables.scss";


















































































































































.dialog-content {
  .el-form {
    width: 95%;
    margin: 0 auto;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
.text-hidden {
  width: 150px;
}
.link {
  color: #1890ff;
}
.warehouseLocationVOList-row {
  p {
    border-bottom: 1px solid #ededed;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  span {
    display: inline-block;
    width: 100px;
    text-align: center;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
