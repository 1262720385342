@import "~@/erp/styles/variables/variables.scss";




























































































.pointer {
  cursor: pointer;
}
