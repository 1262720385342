@import "~@/erp/styles/variables/variables.scss";



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.order-content {
  padding: 0 20px 90px;

  h4 {
    font-family: 'PingFang Bold';
    font-size: 14px;
    margin: 20px 0 10px 0;
  }

  .el-input,
  .el-select {
    width: 100%;
  }

  .el-form-item {
    margin-bottom: 0;
  }
}

td .link {
  cursor: pointer;
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.custom-table tbody td {
  padding: 18px 10px;
  position: relative;
}

// ::v-deep .el-input-number--small {
//   width: 100px !important;
// }
// ::v-deep .el-input-number--small .el-input__inner {
//   padding: 0 10px !important;
// }
// ::v-deep .el-input-number--small .el-input-number__increase,
// ::v-deep .el-input-number--small .el-input-number__decrease {
//   display: none !important;
// }
// .print-image {
//   display: flex;
//   // flex-direction: column;
//   align-items: center;
// }
.fileAnnex-span {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  background: #9e9e9e;
  color: #fff;
  border-radius: 50%;
  margin-left: 5px;
  font-size: 12px;
}

.head-check {
  min-width: auto;
}

::v-deep .product-img-box {
  .el-tag {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 2;

    &.el-tag-other {
      background-color: #fff7e8;
      border-color: #ffefd1;
      color: #ffae18;
    }
  }

  .imgs-sty {
    height: auto;
  }
}

.selectFlex {
  /deep/ .el-form-item__content {
    display: flex;
    align-items: center;
  }
}

.custom-container {

  // 是否退税确认弹框
  /deep/ .drawbackDialog {
    .el-dialog__body {
      padding-top: 0;

      .content {
        display: flex;

        i {
          color: #ffba00;
          font-size: 24px;
          margin-right: 16px;
        }

        p:first-of-type {
          font-weight: bold;
        }
      }
    }
  }
}
