@import "~@/erp/styles/variables/variables.scss";



























































































































































.content {
  padding: 15px;
}

h4 {
  margin-top: 20px;
}
