@import "~@/erp/styles/variables/variables.scss";







































































































































































































































































































































































































.wrapper {
  display: flex;

  .el-form {
    display: flex;
    flex: 1;
    .el-form-item.item {
      /* flex: 1; */
      /* max-width: 300px; */
      .el-input,
      .el-select,
      .el-date-editor {
        width: 100%;
      }
      margin: 0 5px;
      .el-date-editor {
        height: 35px;
      }
    }
    .el-form-item.area,
    .el-form-item.user,
    .el-form-item.productType {
      width: 120px;
    }
  }
  .btn-wrapper {
    width: 200px;
    margin-left: 10px;
  }
}
