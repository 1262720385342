@import "~@/erp/styles/variables/variables.scss";
























































































































































































































































































.condition-box {
  height: calc(100vh - 240px);
  > div {
    max-height: calc(100vh - 280px);
    overflow-y: auto;
    ul {
      margin: 0 20px;
      li {
        width: 100%;
        height: 44px;
        line-height: 44px;
        border-bottom: 1px dashed #eee;
        list-style: none;
        display: flex;
        .index {
          width: 40px;
          font-size: 16px;
        }
        .text {
          width: calc(100% - 80px);
        }
      }
    }
  }
}
.oper-btn {
  position: sticky;
  bottom: 0;
  text-align: center;
  background: white;
  padding: 10px 0;
}
::v-deep {
  .el-select {
    width: 100%;
  }
  .el-select-group__title {
    font-size: 14px;
    font-weight: 600;
    color: #333;
  }
  .el-select-group li {
    text-indent: 10px;
  }
}
