@import "~@/erp/styles/variables/variables.scss";


























































































































































































































































































































































































































































































































































































.pro-container {
  margin-bottom: 50px;
}
.product_info_table {
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    height: 50px;
    text-align: center;
    border: 1px solid #dcdfe6;
  }
}
.price-red {
  color: red;
}
.c_pointer.active {
  background-color: #e8f4ff;
  border-color: #d1e9ff;
  color: #1890ff;
}
::v-deep {
  .el-checkbox-group {
    .el-checkbox-button + .el-checkbox-button {
      margin-left: 10px;
      border-left: 1px solid #f2f2f2;
    }
    .el-checkbox-button__inner {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      background-color: #f2f2f2;
      border-color: rgba(228, 228, 228, 0.93);
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    color: #1890ff;
    background-color: #e8f4ff;
    border-color: #e8f4ff;
    box-shadow: -1px 0 0 0 #e8f4ff;
  }
}
