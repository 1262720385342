@import "~@/erp/styles/variables/variables.scss";


















































































































































































































.my-audit-item {
  .content {
    display: flex;
    cursor: pointer;
    .left {
      display: flex;
      flex-direction: column;
      color: #000;
      flex: 1;
      .top {
        margin-bottom: 10px;
        color: #000;
      }
      .bottom {
        color: #5e5e5e;
        font-size: 12px;
        .user {
          margin-right: 7px;
        }
      }
    }
    .right {
      padding-left: 10px;
      display: flex;
      align-items: flex-start;
    }
  }
}
.audit-content-wrapper {
  padding-bottom: 100px;
  > h4 {
    padding-left: 20px;
    color: #0486fe;
  }
}
.index-card-time{
  line-height: 24px;
  width: 78px;
}
.drawer-title{
  display: flex;
  align-items: center;
  span{
    margin-left: 6px;
  }
}
