@import "~@/erp/styles/variables/variables.scss";


















































































































































































































































































































































































































































































































































































.dialog-content {
  height: 65vh;
  overflow: hidden;
  overflow-y: auto;
  .el-form {
    width: 98.5%;
    .charging-p {
      color: #9e9e9e;
    }
  }
  .operate-row {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #ededed;
    border-top: none;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
  .el-tag--medium {
    height: 32px;
    line-height: 32px;
  }
  .join-table {
    .el-form-item__content {
      margin-left: 0 !important;
    }
    .el-form-item--small.el-form-item {
      margin-bottom: 0 !important;
    }
  }
}
.w50 {
  width: 50%;
}
.join-table {
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    height: 38px;
    border: 1px solid #ededed;
  }
  th {
    height: 38px;
    line-height: 38px !important;
    font-weight: normal;
  }
  td:nth-child(even) {
    font-family: 'PingFang Bold';
    max-width: 300px;
    overflow: hidden;
    padding: 0 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  td:nth-child(odd) {
    width: 100px;
    text-align: center;
  }
}
.custom-table thead th {
  padding: 0;
  min-width: 50px;
}
