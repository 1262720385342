@import "~@/erp/styles/variables/variables.scss";





















































































































































































































.flex-row {
  align-items: center;
  margin-bottom: 15px;
}
