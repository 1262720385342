@import "~@/erp/styles/variables/variables.scss";













































































































































































































.customer-charge-item {
  position: relative;
  .content {
    display: flex;
    cursor: pointer;
    .left {
      display: flex;
      flex-direction: column;
      color: #000;
      flex: 1;
      .top {
        margin-bottom: 10px;
      }
      .bottom {
        color: #5e5e5e;
        font-size: 12px;
        .user {
          margin-right: 13px;
        }
        .remove {
          position: absolute;
          bottom: 8px;
          right: 0;
          color: #409eff;
        }
      }
    }
    .right {
      padding-left: 10px;
      display: flex;
      align-items: flex-start;
    }
  }
}
