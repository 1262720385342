@import "~@/erp/styles/variables/variables.scss";



























































.business-transfer-detail-table-wrapper {
  width: 520px;
}
