@import "~@/erp/styles/variables/variables.scss";




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.detail-msg {
  padding: 20px;
  border-left: 1px solid #d7d7d7;
  min-height: 700px;
}
.base-box {
  ::v-deep.el-col{
    min-height:32px;
  }
  label {
    color: #666;
  }
  .col-span {
    color: #000;
  }
}
.fileAnnex-span {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  background: #9e9e9e;
  color: #fff;
  border-radius: 50%;
  margin-left: 5px;
  font-size: 12px;
}
.w90 {
  width: 90%;
}
.table-pay {
  width: 80%;
}
.table-pay th {
  font-weight: normal;
}
.table-pay td {
  height: 36px;
}
// 邮箱链接
.prod-code {
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
}
.input-span {
  display: inline-block;
  height: 48px;
  line-height: 48px;
}
::v-deep {
  .change-text {
    color: #d9001b;
    font-weight: 700;
  }
  .cell {
    &:has(.change-img) {
      position: relative;
      &::before {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #d9001b;
        content: '';
        position: absolute;
        top: 0px;
        right: 4px;
        z-index: 9999;
      }
    }
  }
}
