@import "~@/erp/styles/variables/variables.scss";












































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.custom-table-checkbox {
  overflow-y: scroll !important;
}
