@import "~@/erp/styles/variables/variables.scss";

















































































































































































































































































































.table-container {
  padding: 20px !important;

  & .title {
    display: flex;
    align-items: center;
  }

  & .detail-fields {
    .el-row {
      & .label {
        color: #282c34;
        width: 100px;
        text-align: right;
        display: inline-block;
        margin-right: 15px;
      }

      & .content {
        color: #000016;
      }
    }
  }
}

.header {
  font-size: 18px;
  color: #606266;
  font-family: 'PingFang Bold';
}

.pointer {
  cursor: pointer;
}

.mb20 {
  margin-bottom: 20px;
}

.money-sum {
  color: #666;
  text-align: right;
  padding: 5px;

  span {
    margin: 0 5px;
  }
}
