@import "~@/erp/styles/variables/variables.scss";







































































































































































.form {
  .w100 {
    width: 100%;
  }
}
