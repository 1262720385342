@import "~@/erp/styles/variables/variables.scss";



































































































































































































.custom-dialog {
  h4 {
    margin: 0 0 10px;
  }
  p {
    padding: 0 0 10px;
    line-height: 24px;
    font-size: 14px;
    word-wrap: break-word;
  }

  label {
    font-size: 14px;
    color: #666;
  }
}
.text-row {
  p {
    margin-top: 10px;
  }
}
