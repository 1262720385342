@import "~@/erp/styles/variables/variables.scss";





































































































.collection-notice-item {
  font-size: 14px;

  .mails-item--top {
    margin-bottom: 6px;
    color: #000;

    .mail-code {
      font-family: Semibold;
    }

    .sender {
      font-family: Regular;
    }

    .topic {
      font-family: Semibold;
    }
  }

  .mails-item--bottom {
    display: flex;
    justify-content: space-between;
    font-size: 12px;

    p {
      color: #5e5e5e;
      font-family: Regular;

      .label {
        margin-right: 5px;
      }
    }
  }
}

.erpdialog-detail-style {
  .el-row {
    label {
      color: #666;
    }

    p {
      color: #000;
      font-size: 16px;
      white-space: nowrap;
      margin-top: 5px;
    }
  }

  .link {
    color: #46a6ff;
    cursor: pointer;
    text-decoration: underline;
  }
}

.green-bold {
  color: green;
  font-weight: bold;
}

.no-text {
  color: #999 !important;
}
