@import "~@/erp/styles/variables/variables.scss";





























































































































































































































































































.custom-dialog {
  .container {
    min-height: 360px;
    max-height: 600px;
    overflow-y: scroll;
    i {
      font-size: 16px;
      color: #ffba00;
      font-weight: 600;
    }
    .el-row {
      em {
        width: 75px;
      }
    }
    .img-wrap {
      position: relative;
      .el-image {
        width: 56px;
        height: 56px;
        object-fit: cover;
        display: block;
        margin: 0 auto;
        border-radius: 2px;
      }
      .el-badge {
        position: absolute;
        top: -4px;
        right: 12px;
        z-index: 2;
      }
    }
  }
}
