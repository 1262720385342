@import "~@/erp/styles/variables/variables.scss";
























































































































































































































.custom-dialog {
  .container {
    min-height: 200px;
  }
}
