@import "~@/erp/styles/variables/variables.scss";






















































































































































































































































































































































































































































































































































































































































.audit-progress {
  .el_timeline {
    .el-row-content {
      .el-row-left {
        width: 40%;
        .el-row-left-text {
          text-align: justify;
          line-height: 20px;
        }
      }
      .el-row-right {
        flex: 1;
        justify-content: flex-end;
        .el-row-right-content {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          p.user-name {
            white-space: nowrap;
          }
        }

        .choose-row {
          width: 35px;
          height: 35px;
          border-radius: 4px;
          border: 1.5px dashed #1890ff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: relative;
          .require-icon {
            position: absolute;
            display: inline-block;
            color: red;
            font-size: 20px;
            right: -4px;
            top: -2px;
            height: 10px;
            line-height: 10px;
            width: 10px;
            background: #fff;
          }
        }
      }
    }
  }
}

.dialog-content {
  min-height: 360px;
}

.el_timeline {
  width: 95%;
  margin: 0 auto;
}
.choose-row i {
  top: 0;
}
::v-deep {
  .el_timeline {
    .el-timeline-item__wrapper {
      .el-timeline-item__timestamp.is-top {
        font-size: 14px;
        color: #000 !important;
      }
    }
    .el-timeline-item__tail {
      border-left: 1px solid #1890ff;
    }
    // .el-timeline-item__node--normal {
    //   left: -3px;
    //   width: 15px;
    //   height: 16px;
    // }
  }
}
.close {
  position: absolute;
  top: 0;
  right: 9px;
  color: #c0c4cc;
  cursor: pointer;
  font-size: 12px;
}
.user-area {
  width: 80px;
  overflow: hidden;
  p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.user-area:hover .close {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #46a6ff;
  text-align: center;
  line-height: 15px;
  color: #fff;
}
