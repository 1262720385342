@import "~@/erp/styles/variables/variables.scss";


























































































































































































































































.dialog-content {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;

  >.el-row {
    width: 98%;
    margin: 0 auto;
  }

  ::v-deep {
    .el-dialog__body {
      padding: 10px 20px 30px;
    }

    .el-dialog__title {
      font-size: 16px;
    }
  }
}
