@import "~@/erp/styles/variables/variables.scss";




















































































































































































































.container {
  max-height: 500px;
  overflow: auto;
  .flex {
    display: flex;
    .el-icon-warning {
      margin-right: 10px;
      color: #ffba00;
      font-size: 24px;
    }
    .content {
      line-height: 1.6;
      text-align: justify;
      word-break: break-word; // 不要截断单词
      > div:last-of-type {
        margin-top: 10px;
      }
    }
  }
  .fileList {
    margin-top: 10px;
    padding-left: 34px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    img {
      margin-bottom: 8px;
      width: 175px;
      height: 100px;
      cursor: pointer;
    }
    .pdf {
      display: flex;
      align-items: center;
      height: 175px;
      line-height: 175px;
      text-align: center;
      .pdfName {
        width: 70%;
        margin-left: 8px;
        color: #1890ff;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
/deep/.el-dialog__footer {
  text-align: center;
  .el-button--primary {
    margin-left: 10px;
  }
}
