@import "~@/erp/styles/variables/variables.scss";











































































































































































































































































































































.offer-top {
  display: flex;
  justify-content: space-between;
}

.offer-container {
  height: auto;
  padding: 0 5px 20px 0;
  overflow-y: auto;

  .box {
    .top {
      display: flex;

      >div {
        height: 40px;
        line-height: 40px;
        display: inline-block;
        margin-right: 20px;

        span {
          font-weight: 400;
        }
      }
    }

    .base-info {
      display: flex;

      >div {
        border: 1px solid rgba(234, 235, 237, 1);
        width: calc(50% - 8px);
        padding: 10px;
        line-height: 26px;

        em {
          font-weight: 500;
          font-style: normal;
        }
      }

      >div:last-child {
        margin-left: 16px;
      }
    }
  }

  .wrapper-img {
    display: inline-block;
    margin: 0 5px;

    img {
      width: 90px;
      height: 90px;
      object-fit: cover;
      display: block;
      cursor: pointer;
    }
  }

  p {
    text-align: left;
  }

  .pro-item {
    border: 1px solid #eee;
    margin-top: 10px;
    padding: 5px;
  }

  .item-wrapper {
    .img-wrapper {
      display: flex;
    }

    margin-bottom: 10px;
    margin-top: 10px;

    .info-wrapper {
      .label {
        font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold',
          'PingFang SC', sans-serif;
        font-weight: 650;
        font-style: normal;
        font-size: 14px;
        color: #666666;
        text-align: left;
      }

      .el-row {
        display: flex;

        .el-col {
          border: 1px solid #eee;
          display: flex;
          align-items: center;
          padding: 5px;

          &.nobr {
            border-right: none;
          }

          &.nobt {
            border-top: none;
          }

          &.nobb {
            border-bottom: none;
          }

          &.nobl {
            border-right: none;
          }
        }
      }
    }
  }
}
