@import "~@/erp/styles/variables/variables.scss";











































































































































































































































































































































































































































.stock-check {
  box-sizing: border-box;
}

//自定义显示
.custom-table-checkbox {
  .el-checkbox {
    display: block !important;
    margin: 8px 0 8px 10px !important;
  }
}

.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}

.el-tooltip__popper {
  max-width: 400px;
}
