@import "~@/erp/styles/variables/variables.scss";






























































































::v-deep {
  .el-select {
    width: 100%;
  }
}
