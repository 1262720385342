@import "~@/erp/styles/variables/variables.scss";







































































































































































































































































































































































































































































































































































.content {
  padding: 0 20px 0px;

  .detail-header {
    >.el-col {
      p {
        margin-bottom: 14px;
      }

      >span {
        font-size: 16px;
        color: #000;
      }
    }
  }

  .remark {
    >.el-col {
      p {
        margin-bottom: 14px;
      }

      >span {
        font-size: 16px;
        color: #000;
      }
    }
  }

  /deep/ .el-divider--horizontal {
    margin: 30px 0;
  }
}

.link {
  text-decoration: underline;
  color: #409eff;
  cursor: pointer;
}

::v-deep {
  .el-dialog__header {
    padding-bottom: 0;

    >.el-dialog__title {
      font-size: 16px;
    }
  }
}
