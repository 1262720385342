@import "~@/erp/styles/variables/variables.scss";







































































































































































































































































































































































































































































































































































































.search_form {
  height: 90%;
  padding: 0 20px;
  overflow-y: auto;
  .operate {
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #ededed;
    padding: 20px;
  }
}
.search_form .el-date-editor--datetimerange.el-input__inner {
  width: 100%;
}
::v-deep {
  .el-cascader {
    width: 100%;
  }
  .el-date-editor {
    width: 100%;
  }
  .el-divider {
    margin: 70px 0 28px 0 !important;
  }
  .el-radio {
    margin-bottom: 5px;
  }
}
