@import "~@/erp/styles/variables/variables.scss";
























































































































































































































































.new-btn {
  width: 100%;
  border-top: none;
  color: #409eff;
}
::v-deep {
  .el-form {
    height: calc(100vh - 150px);
    border-left: none;
    overflow-y: auto;
  }
  .el-select {
    width: 100%;
  }
}
