@import "~@/erp/styles/variables/variables.scss";






































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.page-header-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .page-header-left {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }

  .page-header-right {
    width: 300px;
  }

  /deep/ .el-button,
  /deep/ .el-form-item {
    margin-bottom: 10px !important;
  }
}

.offer-icon {
  width: 40px;
  height: 32px;
  border: 1px solid #ccc;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  border-left: none;
  border-radius: 4px;
  margin-left: -5px;
  padding-left: 5px;
  cursor: pointer;
}

.warn-span {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  background: #d21e06;
  color: #fff;
  font-size: 12px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
  text-align: center;
}

.offer-dialog {
  .container {
    .el-input-number {
      width: 120px;
      margin: 0 5px;
    }

    .el-checkbox-group {
      line-height: 20px;
    }
  }

  .btn {
    margin-top: 20px;
    text-align: right;
  }
}

.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}

::v-deep {
  .page-header {
    .el-input {
      width: 160px;
    }
  }

  .el-table__row .el-button {
    margin: 0 5px;
  }

  .enquiryCode .cell,
  .lastInquiryNumber .cell,
  .priceSheetCode .cell,
  .customerEmail .cell {
    width: auto !important;
  }
}

.text-left {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.w90 {
  width: 90%;
}

.replay {
  /deep/.el-input {
    width: 200px;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
