@import "~@/erp/styles/variables/variables.scss";



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.product-ricing {
  height: calc(100vh - 65px);
  box-sizing: border-box;
  padding: 20px 40px;
  overflow-y: auto;
  .form-box {
    .el-form-item {
      .el-input {
        width: 140px !important;
      }
    }
  }
  .d-flex {
    display: flex;
  }
  .pl40 {
    box-sizing: border-box;
    padding-left: 40px;
  }
  .label {
    color: #999 !important;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .unit-price {
    font-size: 14px;
    color: #db9200;
    line-height: 28px;
    display: flex;
    align-items: center;
  }
  .product-ricing-tit {
    padding: 18px 0;
  }
  .product-ricing-search {
    .removed {
      position: relative;
      &::after {
        content: 'Removed from online store.';
        font-size: 14px;
        color: #db9200;
        position: absolute;
        left: 90px;
        top: 40px;
      }
      .el-input__inner {
        border-color: #db9200 !important;
      }
    }
  }
  .pricing-remark {
    display: flex;
    margin-bottom: 20px;
    .pricing-remark-tit {
      width: 108px !important;
      flex-shrink: 1;
      color: rgba(217, 0, 27, 0.6);
      &.product-owner-tit {
        width: 140px !important;
        margin-left: -32px;
      }
    }
    .pricing-remark-cont {
      flex: 1;
      .el-textarea__inner {
        background: #fff !important;
        color: #515a6e;
      }
    }
  }
  .pricing {
    .grand-total-box {
      padding-left: 34px;
      .grand-total {
        height: 70px;
        border: 1px solid #db9200;
        border-radius: 4px;
        text-align: center;
        line-height: 70px;
        background: rgba(219, 146, 0, 0.05);
        margin: 10px 0 20px;
        .num {
          font-size: 24px;
          color: 3000;
          font-weight: 700;
        }
      }
    }
  }
  .delivery-time {
    .img-box {
      position: relative;
      padding-right: 200px;
      .img-cont {
        position: absolute;
        right: 0;
        top: -40px;
        width: 180px;
        height: 180px;
        // background: #f3f3f3;
        padding: 10px;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .product-details {
    .label {
      width: 88px;
      text-align: right;
    }
  }
  .product-details-size {
    .label {
      display: inline-block;
      width: 120px;
      text-align: right;
    }
  }
  .product-details-table {
    margin: 20px 0;
  }
  .el-dropdown-box {
    display: flex;
    .el-dropdown {
      height: 20px;
    }
  }
  .wid108 {
    width: 108px !important;
    display: inline-block;
    text-align: right;
  }
  .zip-code {
    .el-form-item__label {
      margin-left: -3px;
    }
    .el-form-item__content {
      .el-form-item__error {
        margin-left: 100px;
      }
    }
  }
}
