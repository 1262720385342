@import "~@/erp/styles/variables/variables.scss";





































































































































































































































































































































































































.el-select {
  width: 100%;
}

.input-group {
  display: flex;
  align-items: center;

  >span {
    margin-bottom: 18px;
  }
}

.table-foot {
  text-align: center;
  color: #1890ff;
  font-size: 14px;
  line-height: 2;
  cursor: pointer;
}
