@import "~@/erp/styles/variables/variables.scss";










































































.wrapper {
  display: inline-block;
  margin: 0 5px;
}
