@import "~@/erp/styles/variables/variables.scss";


































































































































































































































































































































































.template-config {
  box-sizing: border-box;
  padding: 10px 0px;
  width: 100%;
  height: calc(100vh - 55px);
  overflow-y: scroll;
  .title {
    display: inline-block;
    text-align: right;
  }
  .title::before {
    content: '';
    border-right: 7px solid blue;
    margin-right: 5px;
  }
  .template-table {
    margin-top: 15px;
  }
  .addTemplate {
    text-align: center;
    margin-top: 15px;
  }
  .stick30 {
    position: sticky;
    bottom: 30px;
  }
}
