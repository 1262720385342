@import "~@/erp/styles/variables/variables.scss";






































































































































































.form {
  .w100 {
    width: 100%;
  }
  .el-radio-group {
    display: flex;
    flex-direction: column;
    .el-radio {
      margin-bottom: 10px;
    }
  }
}
.p-tip {
  font-size: 12px;
  color: #909399;
  margin-bottom: 25px;
  margin-top: 5px;
}
.p-tip-disabled {
  color: #c0c4cc !important;
}
