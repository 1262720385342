@import "~@/erp/styles/variables/variables.scss";














































































































































































































































































































































































































































































































































































































































































.customer-charge-item {
  .content {
    display: flex;
    cursor: pointer;

    .left {
      display: flex;
      flex-direction: column;
      color: #000;
      flex: 1;

      .top {
        margin-bottom: 10px;
      }

      .bottom {
        color: #008000;
        font-size: 12px;

        .user {
          margin-right: 13px;
        }
      }
    }

    .right {
      padding-left: 10px;
      display: flex;
      align-items: flex-start;
    }
  }
}

.green-bold {
  color: green;
  font-weight: bold;
}

.custom-table {
  width: 100%;
}

::v-deep {
  .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
}
