@import "~@/erp/styles/variables/variables.scss";










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.custom-dialog {
  .img-wrap {
    position: relative;
    padding: 3px 0;
    width: 50px;
    margin: 0 auto;

    .el-image {
      width: 50px;
      height: 50px;
      object-fit: cover;
      display: block;
    }

    .el-badge {
      position: absolute;
      top: 3px;
      right: -10px;
      z-index: 2;
    }
  }

  .wrapper-box {
    text-align: center;
    position: relative;

    .wrapper-add {
      display: inline-block;
      width: 64px;
      height: 64px;
      line-height: 64px;
      text-align: center;
      border: 1px dotted #c0cbda;
      border-radius: 4px;
      cursor: pointer;

      i {
        font-size: 18px;
      }
    }

    .wrapper-image {
      img {
        width: 64px;
        height: 64px;
        margin-top: 8px;
        border-radius: 2px;
        object-fit: cover;
        cursor: pointer;
      }
    }

    .el-badge {
      position: absolute;
      top: 4px;
      right: 8px;
    }
  }

  .el-form-item {
    .el-input {
      width: 100%;
    }
  }

  p {
    margin: 10px 0;

    span {
      font-size: 16px;
      font-family: 'PingFang Bold';
    }
  }

  .add-btn {
    width: 100%;
    text-align: center;
    line-height: 40px;

    span {
      color: #409eff;
      cursor: pointer;
    }
  }
}

/* 带表单验证的table */
.form-rule-table {
  /deep/ {
    .cell {
      overflow: initial;
      text-align: center;

      .el-form-item__content {
        padding: 5px 0;

        .el-form-item__error {
          top: 38px;
          margin-left: 2px;
        }
      }
    }
  }
}

::v-deep {
  .input-full {
    .el-select .el-input {
      width: 120px;
    }
  }
}
