@import "~@/erp/styles/variables/variables.scss";


























































































































































































































































































































































































.d-wrapper {
  height: 100%;
}

.tab-wrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .status-item {
    box-sizing: border-box;
    padding: 3px 5px;
    margin-right: 15px;
  }

  .status--active {
    border-bottom: 1px solid red;
    color: red;
  }

  .status-item:hover {
    cursor: pointer;
    color: red;
  }
}

tr.row-hide {
  display: none !important;
}

.el-button--text {
  cursor: pointer;
}

.log-p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.red {
  color: red;
  margin-right: 5px;
}

.text-ellipsis {
  -webkit-line-clamp: 3;
  /*（用来限制在一个块元素显示的文本的行数，3 表示最多显示3 行。为了实现该效果，它需要组合其他的 WebKit 属性）*/
  display: -webkit-box;
  /*（和 webkit-line-clamp 结合使用，将对象作为弹性伸缩盒子模型显示 ）*/
  -webkit-box-orient: vertical;
  /*（和 webkit-line-clamp 结合使用 ，设置或检索伸缩盒对象的子元素的排列方式 ）*/
  overflow: hidden;
  /*（文本溢出限定的宽度就隐藏内容）*/
  text-overflow: ellipsis;
  /*（多行文本的情况下，用省略号 “…” 隐藏溢出范围的文本)*/
}
