@import "~@/erp/styles/variables/variables.scss";






























































































































































































































































.left-col {
  height: 100%;
  overflow-y: auto;
}
.right-col {
  height: 100%;
  padding: 0 20px;
}
.text-hidden {
  width: 80%;
}

::v-deep {
  .el-tabs__content {
    height: calc(100% - 55px) !important;
    .el-tab-pane {
      height: 100%;
    }
  }
  .el-tabs {
    height: 100%;
  }
  .el-tree-node__content {
    height: 35px;
    padding: 0 10px;
  }
  .custom-tree-node {
    span:first-child {
      font-size: 14px;
    }
  }
  .right-col-top {
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .el-tree {
    .is-current {
      > .el-tree-node__content {
        background: #ededed;
      }
    }
  }
  .el-select {
    width: 100%;
  }

  .el-tree-node__content:hover {
    background: #ededed;
  }
  .pagination-container {
    padding: 10px;
  }
  .el-pagination {
    margin-top: 0 !important;
  }
  input[type='number'] {
    -moz-appearance: textfield !important;
  }
}
.test {
  color: #46a6ff;
}
