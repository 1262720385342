@import "~@/erp/styles/variables/variables.scss";
































































































































































































































































.content {
  padding: 0 20px 0px;
  .detail-header {
    > .el-col {
      p {
        margin-bottom: 14px;
      }
      .span-text {
        font-size: 16px;
        color: #000;
      }
    }
  }
  .table-custom {
    border-collapse: collapse;
    text-align: left;
    td {
      border: #ebeef5 1px solid;
      text-align: center;
    }
    td:nth-child(odd) {
      background-color: #f5f7fa;
    }
  }
}
.page-link {
  color: #409eff !important;
}
