@import "~@/erp/styles/variables/variables.scss";

































































































.business-transfer-detail-table-wrapper {
  max-width: 90%;
}
