@import "~@/erp/styles/variables/variables.scss";


















































































































































































































































































































































































































































































.dialog-content {
  ::v-deep {
    .el-dialog__body {
      padding: 10px 20px 30px;
    }

    .el-dialog__title {
      font-size: 16px;
    }
  }
}

.price {
  color: green;
  font-weight: bold;
}

::v-deep .el-table__row.expanded + tr {
  height: auto;
}

::v-deep .el-table__cell.el-table__expanded-cell:empty {
  height: 0;
  padding: 0;
}
