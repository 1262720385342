@import "~@/erp/styles/variables/variables.scss";/* 自定义样式 */

.el-tooltip__popper.is-light {
  border: 1px solid #fff !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .popper__arrow {
    border-top-color: #fff !important;
    border-bottom-color: #fff !important;
  }
}

// .el-tooltip__popper .popper__arrow,
// .el-tooltip__popper .popper__arrow::after {
//   border: 1px solid #fff !important;
// }

// 列表页头部，输入框，下拉框，按钮，自定义显示，统一高度36px
.pub-h-36 {
  height: 32px !important;
}

// 表格头部高度38px，其他单元格高度统一50x，如表格有图片，图片设置50px
.el-table__header tr,
.el-table__header th {
  padding: 0;
  height: 38px;
}

.el-table__body tr,
.el-table__body td {
  padding: 0;
  height: 50px;
}

//颜色666
.c-666 {
  color: #666;
}

//危险色
.c-danger {
  color: #f56c6c;
}

.required {
  color: #f56c6c;
  font-style: normal;
  margin-right: 3px;
}

.required-th {
  .cell:before {
    content: '*';
    color: #f56c6c;
    margin-right: 3px;
  }
}

//textarea字数限制
// .el-textarea .el-input__count {
//   bottom: -15px !important;
// }

/* input 至 input */
.to_input {
  display: flex;

  span {
    padding: 0 30px;
  }
}

// 高级搜索一行两个input
.line_input {
  display: flex;
}

.line_input .el-form-item {
  margin-bottom: 0 !important;
}

.line_input span {
  margin: 0 10px 0 5px;
}

.line_input .el-input__inner {
  padding-right: 50px !important;
}

.el-input--small .el-input__inner {
  // height: 36px !important;
}

.no-wrap {
  white-space: nowrap;
}

.el-tooltip__popper {
  max-width: 50% !important;
}

// 页面头部搜索新增等功能
.page_header {
  display: flex;
  height: auto;
  overflow: hidden;

  .el-form-item {
    margin-bottom: 0 !important;
  }

  .page_header_left {
    display: flex;
    flex: 1;
  }

  .el-form-item {
    margin-bottom: 10px !important;
  }

  .el-select {
    margin-bottom: 10px;
  }
}

::v-deep {
  .el-button {
    height: 36px !important;
    margin-bottom: 10px;
  }

  .el-button--small {
    padding: 11px 15px !important;
  }
}

// 产品新增
.el_form_item_w48 {
  width: 48%;
}

.el_form_item_w31 {
  width: 31%;
}

.el_form_item_w100 {
  width: 100%;
}

.input_and_btn {
  .w90 {
    width: 80%;
  }

  button {
    margin-left: 10px;
  }
}

.pro_floor {
  display: flex;
  margin-top: 50px;
}

.pro_floor,
.pro_floor_test {
  .pro_floor_tab {
    width: 120px;

    strong {
      font-size: 15px;
      white-space: nowrap;
    }
  }

  .pro_floor_tab:before {
    content: '';
    display: inline-block;
    width: 2px;
    height: 13px;
    background: #515a6e;
    margin-right: 8px;
  }

  .pro_floor_content {
    flex: 1;
    border: 1px solid #ededed;
    padding: 20px;
    border-radius: 2.5px;
  }
}

.pro_floor,
.pro_floor_test {
  .pro_floor1 {
    display: flex;
    margin-top: 50px;

    .pro_floor_tab {
      width: 120px;

      strong {
        font-size: 15px;
        white-space: nowrap;
      }
    }

    .pro_floor_tab:before {
      content: '';
      display: inline-block;
      width: 2px;
      height: 13px;
      background: #515a6e;
      margin-right: 8px;
    }

    .pro_floor_content {
      flex: 1;
      border: 1px solid #ededed;
      padding: 20px;
      border-radius: 2.5px;
    }
  }
}

// 弹框按钮组
.dialog_btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  .el-button {
    display: block !important;
  }
}

// 分页
.el-pagination {
  position: relative;
  padding: 0 20px;

  span.el-pagination__total {
    position: absolute;
    left: 20px;
  }

  span.el-pagination__sizes {
    position: absolute;
    left: 75px;
    margin-left: 20px;
  }

  span.el-pagination__jump {
    position: absolute;
    right: 20px;
  }
}

// 图片上传展示区域
.upload_box {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-top: -10px;

  >div {
    position: relative;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .img_show {
    position: relative;
    width: 110px;
    height: 110px;
    overflow: hidden;
    border-radius: 6px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }

    .modal {
      position: absolute;
      width: 110px;
      height: 110px;
      text-align: center;
      line-height: 110px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      display: none;

      i {
        font-size: 18px;
        color: #fff;
      }

      i+i {
        margin-left: 10px;
      }
    }
  }

  .img_show:hover {
    .modal {
      display: block;
    }
  }

  .up_area {
    width: 110px;
    height: 110px;
    border: 1px dotted #d8d8d8;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    border-radius: 6px;

    .el-icon-plus {
      font-size: 24px;
      color: #d8d8d8;
    }
  }

  .up_area:hover {
    border: 1px dashed #5b87ff;

    .el-icon-plus {
      color: #5b87ff;
    }
  }
}

//产品设置
.pro_set {
  .card {
    height: 175px;
    position: relative;
    background: rgba(245, 246, 247, 0.5);

    .el-card__body {
      div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        label {
          color: #999;
          font-size: 12px;
          display: inline-block;
          line-height: 24px;
        }

        p {
          flex: 1;
          display: inline-block;
          line-height: 24px;
          text-align: justify;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          cursor: pointer;
          word-wrap: break-word;
          word-break: break-all;
        }
      }

      .operate {
        position: absolute;
        width: 100%;
        bottom: 10px;
        text-align: center;
      }
    }

    p+p {
      margin-top: 10px;
    }
  }
}

/*在Chrome下移除input[number]的上下箭头*/
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none !important;
}

/*在firefox下移除input[number]的上下箭头*/
input[type='number'] {
  -moz-appearance: textfield;
}

//自定义显示
.custom-table-checkbox {
  .el-checkbox {
    display: block !important;
    margin: 8px 0 8px 10px !important;
  }
}

.custom-save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}

.operation-btn {
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 26px;
}

// 详情通用样式
.custom-wrap {
  padding: 9px 20px;
  min-height: calc(100vh - 63px);
  height: calc(100vh - 63px);
  background: #fff;
  overflow: hidden;
  overflow-y: auto;

  .custom-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;
    line-height: 33px;

    .el-page-header {
      margin: 0 !important;
      line-height: inherit;
    }

    .el-tag {
      margin-top: 6px;
    }
  }

  h4 {
    margin: 20px 0 10px;
    font-family: 'PingFang-Bold';
  }

  .el-divider {
    margin: 0 !important;
    background-color: #ebeef5;
  }
}

// 列表菜单通用样式
.custom-container {
  h4 {
    margin: 20px 0 10px;
    font-family: 'PingFang-Bold';
  }

  .page-header-left {
    .el-button {
      height: 34px;
    }
  }

  .page-header {

    // height: 36px;
    .el-form-item--small.el-form-item {
      margin-bottom: 0;
    }
  }

  .el-input--small .el-input__inner {
    // height: 32px !important;
    // line-height: 34px;
  }

  .el-dropdown-menu__item {
    width: 80px;
    text-align: center;
  }

  .el-dropdown {
    .el-button-group {
      .el-button:first-child {}

      .el-button:last-child {
        padding-top: 9px;
      }
    }
  }
}

/* 页面通用table */
.c-table-wrap {
  overflow: auto;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;

  thead {
    width: 100%;
    height: 39px;
    line-height: 39px;
    border-right: none;
    background: #f5f7fa;

    th {
      padding: 8px 0;
      border: 1px solid #ebeef5;
      font-size: 14px;
      color: #606266;
      line-height: 20px;
      min-width: 120px;
      font-weight: normal;
      font-family: PingFang Bold;

      span {
        position: relative;

        i {
          color: red;
          font-size: 16px;
          position: absolute;
          left: -12px;
          font-weight: 500;
        }
      }
    }

    th.index {
      min-width: 55px;
      width: 55px;
    }

    th.xl {
      min-width: 140px;
    }

    th.more {
      min-width: 240px;
    }

    th.operate {
      min-width: 80px;
      width: 80px;
    }
  }

  tbody {
    tr:hover {
      background: #f5f7fa;
    }

    td {
      padding: 15px 10px;
      text-align: center;
      border: 1px solid #ebeef5;
      line-height: 20px;
      word-break: break-all;

      .el-input,
      .el-select,
      .el-autocomplete {
        height: 32px;
        width: 100%;
      }
    }

    .empty {
      width: 100%;
      border-bottom: 1px solid #ebeef5;
      text-align: center;
      color: #909399;
      height: 50px;
      line-height: 50px;
    }
  }

  tbody.short {
    tr {
      height: 48px;
      line-height: 48px;
    }
  }
}

/* 订单管理安排订单弹窗特殊处理 */
.plan-order {
  .el-date-editor {
    .el-input__inner {
      height: 30px !important;
    }
  }

  .line {
    .el-form-item__error {
      margin-top: -10px;
    }
  }
}

/* 强制form表单label对齐 */
.label-left {
  .el-form-item__label {
    text-align: left;
  }
}

.label-right {
  .el-form-item__label {
    text-align: right;
  }
}

/* 去除表格间隙 */
.no-space {
  .el-form-item {
    margin-bottom: 0 !important;
  }
}

/* 带按钮的导航 */
.flex-btn {
  display: flex;
  justify-content: flex-start;
  height: 34px;

  h4 {
    margin: 0;
    line-height: 34px;
  }

  .el-button {
    margin-left: 12px;
  }

  .el-checkbox {
    margin-left: 12px;
    line-height: 34px;
  }
}

// 新版ui详情页详情字段样式
.detail-block {
  border: 1px solid #ebeef5;
  padding: 20px 30px 0 30px;

  .detail-item {
    // height: 36px;
    height: auto;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    & span {
      flex: 1;
      font-size: 14px;
    }

    & .detail-label {
      color: #999999;
    }

    & .detail-content {
      font-family: 'PingFang-Bold';
      color: #303133;
    }
  }
}

// 去除el-row 左右-12px margin
// .el-row {
//   margin-left: 0px !important;
//   margin-right: 0px !important;
// }

//底部按钮居右
.footer-wrapper {
  text-align: right;
}

// 页面通用padding20px
.custom-padding {
  padding: 20px;
}

// 修改dialog-body 为20px padding
.el-dialog {
  & .el-dialog__body {
    padding: 20px;
  }
  &.dialog-small {
    .el-dialog__body {
      max-height: 70vh;
      overflow-y: auto;
    }
  }
}

// 详情页标题
.detail-title {
  font-size: 14px;
  font-family: 'PingFang-Bold';
  color: #606266;
  line-height: 16px;
}

// tab 标题样式
.el-tabs .el-tabs__item {
  font-size: 14px;
  color: #606266;
}

.el-tabs .el-tabs__item.is-active {
  font-family: 'PingFang-Bold';
  color: #0486fe;
}

// 表格链接样式
.erp-table-link {
  color: #236ce9;
  text-decoration: underline;
  cursor: pointer;
}

// 印刷效果图样式
.print-image {
  img {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
}

// 附件查看样式
.flie-list {
  height: 30px;
  line-height: 30px;
  padding-left: 15px;
  position: relative;
  display: flex;

  i {
    position: absolute;
    top: 8px;
    left: 0;
  }

  a {
    display: inline-block;
    height: 30px;

    span {
      max-width: 300px;
    }
  }

  span {
    display: inline-block;
    height: 30px;
  }
}

// 详情展示样式
.detail-style {
  width: 100%;
  padding: 0 10px;

  .line {
    margin: 10px 0;
    display: flex;

    .item {
      min-width: 240px;
      max-width: 480px;
      margin-right: 20px;

      em {
        font-style: normal;
        display: block;
        font-size: 14px;
        color: #666;
        line-height: 24px;
        padding: 10px 0;

        i {
          font-size: 16px;
          cursor: pointer;
        }
      }

      span {
        display: block;
        width: 100%;
        line-height: 24px;
        font-size: 16px;
        color: #000;
      }
    }

    .item.full {
      width: 100%;
    }
  }
}

// 详情通用样式
.custom-detail {
  margin: 0 0 10px;

  .el-row {
    display: flex;
    line-height: 24px;

    .el-col {
      width: 25%;
      margin-bottom: 10px;

      em {
        width: 75px;
        font-style: normal;
        display: inline-block;
        color: #000;
        vertical-align: top;
      }

      span {
        width: calc(100% - 75px);
        padding-right: 20px;
        display: inline-block;
        word-break: break-word;
      }
    }

    .w_60 em {
      width: 60px;
    }

    .w_60 span {
      width: calc(100% - 60px);
    }

    .w_90 em {
      width: 90px;
    }

    .w_90 span {
      width: calc(100% - 90px);
    }

    .w_105 em {
      width: 105px;
    }

    .w_105 span {
      width: calc(100% - 105px);
    }

    .w_130 em {
      width: 130px;
    }

    .w_130 span {
      width: calc(100% - 130px);
    }

    .w_150 em {
      width: 150px;
    }

    .w_150 span {
      width: calc(100% - 150px);
    }
  }
}

.custom-detail.en {
  .el-col {
    em {
      width: 150px;
    }

    span {
      width: calc(100% - 150px);
    }
  }
}

// 弹窗详情通用样式
.erpdialog-detail-style {
  width: 100%;
  padding: 0 10px;

  .line {
    margin: 10px 0;

    .item {
      em {
        font-style: normal;
        display: block;
        font-size: 14px;
        color: #666;
        line-height: 24px;
        padding: 10px 0;

        i {
          font-size: 16px;
          cursor: pointer;
        }
      }

      span {
        display: block;
        width: 100%;
        line-height: 24px;
        font-size: 16px;
        color: #000;
      }
    }

    .item.full {
      width: 100%;
    }
  }
}

// 动态table样式
.el-table table th.star div:before {
  content: '*';
  color: red;
}

.dyn-table {
  .cell {
    overflow: initial !important;
  }

  td {
    padding: 0 !important;
  }

  .el-form-item {
    margin: 0 !important;
    padding: 8px 0 !important;
  }
}

.input-table {
  .cell {
    overflow: initial !important;
  }

  td {
    padding: 0 !important;
  }

  .el-form-item {
    margin: 0 !important;
    padding: 16px 0 !important;
  }
}

// 控制费用申请收款方下拉项宽度
.el-select-dropdown {
  max-width: 1080px;
}

/********************首页时间样式*************************/
.index-card-time {
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #5e5e5e;
  line-height: 17px;
}

// 邮箱通知样式
.mail-notify {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 240px !important;
  background-color: (255, 255, 255, 0.8);

  .icon-unread {
    color: #409eff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 40px;
  }

  .el-notification__group {
    margin-left: 30px;
  }

  .el-notification__title {
    font-size: 14px;
    color: #303030;
    line-height: 1;
  }

  .el-notification__content {
    font-size: 14px;
    color: #409eff;
  }
}

// 列表主子产品样式
.el-table__row--level-1 {
  background: #f6f8f9 !important;
}

.main-split,
.child-split {
  height: 16px;
  width: 16px;
  line-height: 16px;
  font-size: 12px;
  border-radius: 2px;
  font-style: initial;
}

.main-split {
  background: #ffd597;
  border: 1px solid #ffbe73;
  color: #f59a23;
}

.child-split {
  background: #e1f3c2;
  border: 1px solid #c4e590;
  color: #70b603;
}

// 最多显示三行超过显示...
.limit-text {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
}

.el-form-item__label {
  word-wrap: break-word;
  word-break: keep-all;
}

// 文本换行样式
.pre-wrap {
  // white-space: pre-wrap;
  // word-break: break-word;
  margin: 0;
  padding: 0;

  p {
    line-height: 20px;
  }
}

.dialog-fixed {
  .el-dialog {
    position: absolute;
    margin-top: 0 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .el-dialog__body {
      height: calc(100% - 128px);
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
  }
}
