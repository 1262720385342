@import "~@/erp/styles/variables/variables.scss";































































.platform-product-detail {
  height: calc(100vh - 45px - 2 * 9px);
  padding: 9px;
  display: flex;
  flex-direction: column;
  .container {
    padding: 0 10px 60px;
    overflow: auto;
    flex: 1;
  }
}
