@import "~@/erp/styles/variables/variables.scss";


























































































































































































































































































































































































































































































.center {
  text-align: center;
}
.dialog-content {
  height: 60vh;
  overflow: hidden;
  overflow-y: auto;
  .el-form {
    width: 95%;
    margin: 0 auto;
  }
}

::v-deep {
  .el-select {
    width: 100%;
  }
  .logMgCls {
    .el-select__tags {
      > span {
        display: flex;
      }
    }
    .el-select__tags-text {
      display: inline-block;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .el-select .el-tag__close.el-icon-close {
      top: -5px !important;
    }
  }
  .el-textarea__inner {
    padding: 5px 50px 5px 10px;
  }
}
