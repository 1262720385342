@import "~@/erp/styles/variables/variables.scss";





























































































































































































































































































































.order-product {
  .container {
    height: 450px;
    overflow-y: auto;
  }
}

.mr15 {
  margin-right: 15px;
}

.search-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .left {
    .el-input {
      width: 250px;
      margin-right: 20px;
    }
  }

  .right {
    flex: 1;
    text-align: right;
  }
}

.search-wrapper .el-pagination {
  margin-top: 10px;
}
