@import "~@/erp/styles/variables/variables.scss";










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.w160 {
  width: 160px;
}
.w180 {
  width: 180px;
}
.sel-pro {
  i {
    font-size: 24px;
    cursor: pointer;
    vertical-align: middle;
    color: #409eff;
    margin-left: 5px;
  }
}
