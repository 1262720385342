@import "~@/erp/styles/variables/variables.scss";






































.text {
  display: inline-block;
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
}
