@import "~@/erp/styles/variables/variables.scss";























































































































.footer {
  .el-button+.el-button {
    margin-left: 30px;
  }
}

.ml15 {
  margin-left: 15px;
}

.loc-item {
  display: flex;
  align-items: center;
}

.mt20 {
  margin-top: 20px;
}

.pointer {
  cursor: pointer;
}


.el-col-6 {
  display: flex;
  align-items: center;
}
