@import "~@/erp/styles/variables/variables.scss";






























































.el-row {
  span {
    color: #666;
  }
  p {
    color: #000;
    font-size: 16px;
  }
}
