@import "~@/erp/styles/variables/variables.scss";

























































































































































.infinite-scroll {
  height: 100%;
  overflow-y: auto;
}
.payment-order-item {
  font-size: 14px;
  .payment-order-item--top {
    margin-bottom: 10px;
    color: #000000;
  }
  .payment-order-item--bottom {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    p {
      color: #5e5e5e;
      font-family: Regular;
      .label {
        margin-right: 5px;
      }
    }
  }
}
