@import "~@/erp/styles/variables/variables.scss";





































































































































































































































































































.detail-wrapper {
  max-height: 500px;
  overflow: auto;
  padding-right: 10px;
}
.el-row {
  margin-bottom: 10px;
  .el-col {
    display: flex;
    &.column {
      flex-direction: column;
    }
    span {
      display: inline-block;
      // font-size: 16px;
      &.label {
        color: #555555;
        margin-right: 10px;
      }
      &.text {
        color: #000000;
      }
    }
  }
}
.history-item-wrapper {
  margin-bottom: 20px;
  padding-bottom: 5px;
  &.line-dashed {
    border-bottom: 1px dashed #c0c4cc;
  }
  .history-item {
    display: flex;

    .history-item--left {
      display: flex;
      flex-direction: column;
      width: 150px;
      span {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .history-item--right {
      flex: 1;
    }
  }
}
.file-wrapper {
  margin-left: 150px;
}

.w160 {
  width: 160px;
}
.w180 {
  width: 180px;
}
