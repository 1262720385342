@import "~@/erp/styles/variables/variables.scss";








































































































































































































* {
  background: white;
}

.waiBox {
  width: 700px;
  margin: 0 auto;

  table {
    input {
      border: 0;
      width: 90%;
      padding-left: 10px;
    }
  }
}

.imgBox {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 30px;
}

.imgBox img:first-child {
  width: 290px;
  height: 45px;
}

.imgBox img:last-child {
  width: 234px;
  height: 32px;
  margin-left: 62px;
}

.txtBox {
  width: 217px;
  height: 74px;
  float: right;
  /* margin-left: 105px; */
  border: 1px solid #000000;
}

.clearfix:before,
.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

.clearfix {
  zoom: 1;
}

.txtBox p {
  text-align: center;
  font-size: 15px;
  font-family: 'PingFang Bold';
}

.txtBox p em {
  color: red;
}

.pTL {
  font-size: 22px !important;
  font-family: 'PingFang Bold';
  line-height: 37px;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}

/* SHIP TO */
.adreesDa {
  margin-bottom: 20px;
  overflow: hidden;
}

.shipto {
  /* margin-bottom: 20px; */
  float: left;
}

.contBox {
  width: 377px;
  height: 74px;
  border: 1px solid #000000;
  /*line-height: 70px;*/
}

.contBox p {
  font-size: 10px;
  font-family: 'PingFang Bold';
}

/* 表格 */
#tableExcel {
  border-collapse: collapse;
}

#tableExcel tr td {
  height: 20px;
}

#tableExcel tbody tr td {
  font-size: 10px;
  text-align: center;
}

#tableExcel tfoot tr td {
  text-align: center;
  font-family: 'PingFang Bold';
}

.ftTxt {
  margin-top: 50px;
  font-size: 10px;
  font-family: 'PingFang Bold';
}

.ftTxt p input {
  border: none;
  font-family: 'PingFang Bold';
}

.ftName {
  text-align: right;
}

.ftName input {
  height: 20px;
  border: 0px;
  border-bottom: 1px solid #000000;
}
