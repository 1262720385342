@import "~@/erp/styles/variables/variables.scss";


































































































































































































.configuration-list {
  ::v-deep .el-form-item {
    margin: 14px 20px;
    .el-form-item__error {
      width: 100%;
    }
  }
  .btn-box {
    width: 982px;
    text-align: center;
  }
}
