@import "~@/erp/styles/variables/variables.scss";




















































































































































































.wrapper {
  min-width: 1160px;
  font-family: PingFangSC-Regular, PingFang SC;
  .card {
    display: flex;
    padding: 20px;
    background-color: #fff;
    cursor: pointer;
    border-radius: 10px;
    &.card-empty {
      height: 118px;
    }
    &:hover {
      box-shadow: 0px 1px 5px 0px rgba(206, 206, 206, 0.5);
    }

    .content {
      display: flex;
      flex-direction: column;
      flex: 1;

      span {
        display: inline-block;
      }
      .number {
        display: flex;
        margin-bottom: 8px;
        align-items: baseline;
        font-size: 14px;
        display: flex;
        align-items: baseline;

        .count {
          height: 50px;
          font-size: 36px;

          font-weight: 600;
          color: #000000;
          line-height: 50px;
        }
        .dw {
          width: 14px;
          height: 20px;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
          margin-left: 6px;
        }
      }
      .name {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #5e5e5e;
        line-height: 20px;
      }
    }
  }
  .no-mr {
    margin-right: 0;
  }
  .jst {
    justify-content: center;
  }
}
