@import "~@/erp/styles/variables/variables.scss";





















































































































































































































































































.el-row-right-content {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  > .el-row {
    margin-bottom: 10px;
  }
}
.choose-row {
  width: 35px;
  height: 35px;
  border-radius: 4px;
  border: 1.5px dashed #1890ff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  .require-icon {
    position: absolute;
    display: inline-block;
    color: red;
    font-size: 20px;
    right: -4px;
    top: -2px;
    height: 10px;
    line-height: 10px;
    width: 10px;
    background: #fff;
  }
}
.user-name {
  white-space: nowrap;
}
.close {
  position: absolute;
  top: 0;
  right: 9px;
  color: #c0c4cc;
  cursor: pointer;
  font-size: 12px;
}
.user-area {
  width: 80px;
  overflow: hidden;
  p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.user-area:hover .close {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #46a6ff;
  text-align: center;
  line-height: 15px;
  color: #fff;
}
