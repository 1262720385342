@import "~@/erp/styles/variables/variables.scss";




















































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-select-dropdown {
  width: 1080px;
}

.custom-dialog {

  .el-input,
  .el-select {
    width: 100%;
  }

  .el-form-item {
    margin-bottom: 18px;
  }

  p {
    margin: 0 0 10px;
  }

  .el-tooltip {
    position: absolute;
    top: 9px;
    left: -19px;
    cursor: pointer;
  }
}

::v-deep {

  // .el-dialog__body {
  //   max-height: 650px;
  //   overflow-y: scroll;
  // }
  .el-form {
    max-height: 550px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .el-upload--picture-card {
    width: 100px;
    height: 100px;
  }

  .el-icon-plus {
    position: relative;
    top: -20px;
  }

  .el-upload-list__item {
    width: 100px;
    height: 100px;
  }

  .el-upload-list__item-thumbnail {
    width: 100px;
    height: 100px;
  }

  .el-upload-list__item-actions {
    width: 100px;
    height: 100px;
  }
}

.mb-10 {
  margin-bottom: 10px;
}
