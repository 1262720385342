@import "~@/erp/styles/variables/variables.scss";















































































































































































































































































































































































































































::v-deep {
  .page-header {
    .el-form-item {
      margin-bottom: 0;
    }
  }
}
