@import "~@/erp/styles/variables/variables.scss";






















































































































































































.pro-container {
  font-size: 13px;

  .wrapper {
    display: flex;
    align-items: center;
  }

  .mail-table {
    margin-top: 10px;
    width: 550px;
  }
}

.mail-center-tip {
  font-size: 12px;
  color: #7f7f7f;
}

.bottom {
  text-align: center;
  position: fixed;
  bottom: 20px;
  left: 50%;
}
