@import "~@/erp/styles/variables/variables.scss";














































































































































































































































































































































































































































































































































































































































































































































































































































































































.custom-dialog {
  .el-form-item {
    margin-bottom: 18px;
  }
  p {
    margin: 10px 0;
  }
  .product-table {
    overflow-x: auto;
    .custom-table {
      min-width: max-content;
      width: 100%;
      th {
        width: 120px;
      }
    }
  }
}
