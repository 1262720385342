@import "~@/erp/styles/variables/variables.scss";






















































































































.nav-tab {
  ::v-deep {
    .el-tabs__content {
      height: 100%;
      overflow-y: auto !important;
    }
    .el-tabs__item {
      height: 60px !important;
      line-height: 60px !important;
      padding: 0 40px;
    }
    .is-active {
      background-color: #ebf5ff;
    }
  }
}
