@import "~@/erp/styles/variables/variables.scss";































































































































































































































































































































.page-header {
  height: 33px;
}
::v-deep {
  .page-header {
    .el-form-item {
      margin-bottom: 0;
    }
  }
}
.pointer {
  cursor: pointer;
}

.link {
  text-decoration: underline;
  color: #1890ff;
}

.error-color {
  color: #f56c6c;
}
.success-color {
  color: #67c23a;
}
.warning-color {
  color: #e6a23c;
}
.war .pointer {
  cursor: pointer;
}

.custom-table-checkbox {
  .el-checkbox {
    display: block !important;
    margin: 8px 0 8px 10px !important;
  }
}
.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}
.el-tooltip__popper {
  max-width: 400px;
}
