@import "~@/erp/styles/variables/variables.scss";






















































































































































































































































































































































































.proSetting-container {
  display: flex;
  flex-direction: column;

  >.el-row {
    flex: 1;

    &+.el-row {
      margin-top: 10px;
    }

    >.el-col {
      height: 100%;
    }
  }
}

.public-col {
  height: 100%;
  border: 1px solid #ededed;
  padding: 10px;

  p {
    line-height: 32px;
  }
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
