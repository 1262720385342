@import "~@/erp/styles/variables/variables.scss";

































































































































































































































































































































































































































































































































































































































































































































































































































.track-list {
  box-sizing: border-box;
  flex: 2;

  .track-search {
    // background-color: #f0f0f0;
    padding: 10px 10px 0px 10px;

    ::v-deep {
      .el-form-item {
        margin-right: 20px;
      }
    }
  }

  .table-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .track-status {
    display: flex;
    align-items: center;

    .status-item {
      box-sizing: border-box;
      padding: 3px 5px;
      margin-right: 15px;
    }

    .status--active {
      border-bottom: 1px solid #1890ff;
      color: #1890ff;
    }

    .status-item:hover {
      cursor: pointer;
      color: #1890ff;
    }
  }

  .track-table {
    margin-top: 20px;

    .table-fixed {
      max-height: 400px !important;

      ::v-deep {
        th {
          height: 35% !important;
          max-height: 35px !important;
          line-height: 35px !important;
        }

        td {
          height: 35% !important;
          max-height: 35px !important;
          line-height: 35px !important;
        }
      }
    }

    .table-fixed-mini {
      max-height: 300px !important;
    }
  }
}

::v-deep {
  .masBox {
    height: 1000px !important;
  }

  .area .cell {
    width: auto !important;
  }
}
