@import "~@/erp/styles/variables/variables.scss";






















































































































































































































































































































































































































































































































































































































































































































































































































































































.oper-sticky {
  width: calc(100% - 105px);
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #ccc;
  height: 80px;
  align-items: center;
  background-color: #fff;
}

.pro-button {
  border-color: rgb(40, 130, 255);
  color: rgb(40, 130, 255);
  margin: 15px 0;
}

.add-box {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 145px);
  margin-bottom: 100px !important;

  &.audit-box {
    height: auto !important;
  }
}

em {
  color: #000;
}

.el-form-item {
  margin-bottom: 0px !important;
}

.lot-wrapper {
  display: flex;
  align-items: center;

  .el-input {
    flex: 1;
  }

  .el-icon-circle-plus-outline {
    margin-left: 5px;
    cursor: pointer;
    font-size: 20px;
    color: rgb(40, 130, 255);
  }

}

.left,
.right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: inline-block;
  display: inline-block;
  border: 1px solid #909399;
  z-index: 1000;
  border-radius: 50%;
}

.el-icon-caret-right,
.el-icon-caret-left {

  font-size: 36px;
  color: rgb(40, 130, 255);


}

.right {
  right: 20px;
}

.left {
  left: 7px;
}
