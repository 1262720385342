@import "~@/erp/styles/variables/variables.scss";




























.index-card-item-wrapper {
  padding: 8px 0;
  border-bottom: 1px dashed #eaeaea;
  font-size: 14px;
  color: #282c34;
  &:first-child {
    padding-top: 0;
  }
}
