@import "~@/erp/styles/variables/variables.scss";/**
 * @description vue过渡动画
 */

.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: $base-transition;
}

.fade-transform-enter {
  opacity: 0;
}

.fade-transform-leave-to {
  opacity: 0;
}

/**
 * @description 旋转动画
 */
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
