@import "~@/erp/styles/variables/variables.scss";











































































































































.delivery-period {
  border: 1px solid #ededed;
  padding: 10px;

  ::v-deep .el-radio {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .delivery-period-item {
    display: flex;
    align-items: center;

    &.ratio {
      margin: 20px 0;

      .tip {
        margin: 0 5px 0 -5px;
      }
    }

    ::v-deep .el-form-item {
      width: 60px;
      margin: 0 10px !important;

      .el-input__inner {
        padding: 0 4px;
        text-align: center;
      }
    }
  }

  .delivery-period-btn {
    ::v-deep .el-form-item {
      .el-form-item__content {
        text-align: center;
      }
    }
  }
}
