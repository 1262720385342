@import "~@/erp/styles/variables/variables.scss";






















































































































































































































































































































































































































































































































.cus-table {
  margin-top: 20px;
  .table-fixed {
    max-height: 400px !important;
    ::v-deep {
      th {
        height: 35% !important;
        max-height: 35px !important;
        line-height: 35px !important;
      }
      td {
        height: 35% !important;
        max-height: 35px !important;
        line-height: 35px !important;
      }
    }
  }
  .table-fixed-mini {
    max-height: 300px !important;
  }
  i {
    cursor: pointer;
    font-size: 14px;
    margin: 0 5px;
  }
}
::v-deep {
  .level .cell,
  .orderNumber .cell,
  .releatedCustomerEmail .cell,
  .closeTime .cell,
  .createTime .cell,
  .evaluationCode .cell {
    width: auto !important;
  }
  .el-table__fixed-right {
    background: white;
  }
}
