@import "~@/erp/styles/variables/variables.scss";















































































































.page-custom {
  height: 58px;
}
::v-deep {
  .el-pagination {
    margin: 0;
    padding-top: 18.5px;
    display: flex;
    justify-content: center;
  }
  .base-table-header {
    // 不可删除，会导致样式错乱
    .cell {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
