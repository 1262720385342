@import "~@/erp/styles/variables/variables.scss";
















































































































































































































































































































































































































.content {
  padding: 20px 20px 30px !important;
  .el-row {
    label {
      display: block;
      color: #666;
    }
    span {
      display: block;
      margin-top: 10px;
      font-size: 16px;
      color: #000;
    }
    p {
      margin-top: 10px;
      font-size: 16px;
      word-wrap: break-word;
    }
  }
  .flie-list span {
    font-size: 14px !important;
    color: #666 !important;
  }
}
