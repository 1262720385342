@import "~@/erp/styles/variables/variables.scss";




















.split-div {
  height: 1px;
  background-color: #dcdfe6;
  margin: 5px 0;
}
.ml-15 {
  margin-left: 15px;
}
