@import "~@/erp/styles/variables/variables.scss";





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.public-col {
  height: 43vh;
  border: 1px solid #ededed;
  padding: 10px;
  .el-table {
    height: calc(100% - 45px);
    overflow-y: auto;
    margin-bottom: 10px;
  }
  p {
    line-height: 32px;
  }
}
.row-bottom {
  margin-top: 2vh;
}
.public-col2-1 {
  height: 32vh;
}
.public-col2-2 {
  height: 10vh;
  margin-top: 1vh;
}
