@import "~@/erp/styles/variables/variables.scss";
















































































































.connection-dialog {
  .info-wrapper {
    margin: 10px 20px;
    li {
      list-style: none;
      display: flex;
      line-height: 36px;
    }
  }
}
