@import "~@/erp/styles/variables/variables.scss";




















































































































h4 {
  line-height: 32px;
  span {
    margin-left: 5px;
    color: #1890ff;
  }
}
