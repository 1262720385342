@import "~@/erp/styles/variables/variables.scss";













































































































































































































































* {
  background: white;
}
.pdfDom {
  width: 1170px;
  margin: 0 auto;
  background: white;
  position: absolute;
  top: 500vh;
  left: 0;
  z-index: -1;
  .t-left {
    text-align: left !important;
    padding-left: 20px;
  }
  h1 {
    position: relative;
    margin: 0 auto;
    height: 100px;
    width: 1170px;
    line-height: 100px;
    text-align: center;
    font-size: 32px;
    img {
      height: 80px;
      position: absolute;
      top: 20px;
      right: 0;
    }
  }
  .container {
    padding: 0 20px 20px;
    border: 1px solid rgba(234, 235, 237, 1);
    font-size: 16px;
    .box {
      margin-bottom: 100px;
      .top {
        display: flex;
        > div {
          height: 40px;
          line-height: 40px;
          display: inline-block;
          margin-right: 20px;
          font-family: 'PingFang Bold';
          span {
            font-weight: 500;
          }
        }
      }

      .layout-1 {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
        thead {
          width: 100%;
          height: 38px;
          line-height: 38px;
          border-right: none;
          background: #f5f7fa;
          th {
            border: 1px solid #ebeef5;
            color: #606266;
            text-align: center;
          }
        }
        tbody {
          tr {
            height: 50px;
          }
          td {
            min-height: 48px;
            height: 48px;
            line-height: 20px;
            text-align: center;
            border: 1px solid #ebeef5;
          }
        }
      }

      .layout-2 {
        width: 100%;
        margin-top: 20px;
        border: 1px solid #ebeef5;
        height: 48px;
        line-height: 48px;
        display: flex;
        > div {
          border-right: 1px solid #ebeef5;
        }
        > div:nth-child(2) {
          width: 54%;
          padding-left: 20px;
        }
        > div:last-child {
          width: 18%;
          border-right: none;
          padding-left: 20px;
        }
        .title {
          width: 14%;
          text-align: center;
          font-family: 'PingFang Bold';
          background: #f5f7fa;
        }
      }

      .layout-3 {
        width: 100%;
        border-collapse: collapse;
        thead {
          width: 100%;
          height: 38px;
          line-height: 38px;
          border-right: none;
          th {
            border: 1px solid #ebeef5;
            border-top: none;
            color: #606266;
            text-align: center;
            height: 38px;
          }
        }
        tbody {
          tr {
            height: 110px;
            min-height: 110px;
          }
          .high {
            height: 180px;
            min-height: 180px;
          }
          td {
            line-height: 20px;
            text-align: center;
            border: 1px solid #ebeef5;
          }
        }
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        line-height: 40px;
        > div:first-child {
          font-size: 14px;
        }
        > div:last-child {
          font-size: 16px;
          span {
            margin-left: 15px;
            color: red;
            font-family: 'PingFang Bold';
          }
        }
      }
    }
    .box:first-child {
      margin-bottom: 0;
    }
  }
}
