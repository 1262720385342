@import "~@/erp/styles/variables/variables.scss";
























































































































































































































.warning-title {
  display: inline-block;
  border-bottom: 1px solid red;
  box-sizing: border-box;
  padding: 5px 8px;
}
