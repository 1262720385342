@import "~@/erp/styles/variables/variables.scss";













































































































































.public-col {
  height: 43vh;
  .el-table {
    height: calc(100% - 45px);
    overflow-y: auto;
    margin-bottom: 10px;
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
  }
  p {
    line-height: 32px;
  }
}
