@import "~@/erp/styles/variables/variables.scss";



































































































































































.el-select {
  width: 100%;
}
.el-date-editor {
  width: 100%;
}
