@import "~@/erp/styles/variables/variables.scss";





























































































































.square {
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  margin-right: 3px;
}

.manage-tree-wrappper {
  height: 400px;
  overflow: auto;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

::v-deep .el-tree-node__content {
  height: 30px;
}

.el-button--mini.is-circle {
  padding: 3px;

}
