@import "~@/erp/styles/variables/variables.scss";




































































































.el-icon-warning-outline {
  color: #e6a23c;
  font-size: 30px;
  margin-right: 10px;
}
