@import "~@/erp/styles/variables/variables.scss";






































































































































































































.role-container {
  height: 100%;

  .table-row {
    height: calc(100% - 84px);
    overflow-y: auto;
  }
}
