@import "~@/erp/styles/variables/variables.scss";














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.line {
  height: 2px;
  background-color: #e4e7ed;
}
.conut-form-item {
  position: relative;
  top: -10px;
}
.row-over {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}
.base-table-height {
  max-height: 50vh;
  overflow: hidden;
  overflow-y: auto;
}
::v-deep .el-input-number--small .el-input__inner {
  padding: 0 15px !important;
  text-align: left;
}
::v-deep .el-input-number--small .el-input-number__increase,
::v-deep .el-input-number--small .el-input-number__decrease {
  display: none !important;
}
.link {
  color: #1890ff;
  cursor: pointer;
}
::v-deep .check-num .el-icon-circle-close {
  display: none;
}
.over-row {
  width: 100%;
  overflow: hidden;
  padding: 0 5px;
  text-overflow: ellipsis;
}
.text-left {
  color: #1890ff;
}
.el-tooltip__popper {
  max-width: 400px;
}
