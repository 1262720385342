@import "~@/erp/styles/variables/variables.scss";















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































::v-deep {
  .el-table__row .el-button {
    margin: 0 5px;
  }

  .el-icon-edit,
  .el-icon-sell,
  .el-icon-folder-checked {
    font-size: 16px;
  }

  .el-divider--horizontal {
    margin: 8px 0 !important;
  }

  .number {
    .cell {
      position: relative;

      .el-table__expand-icon {
        position: absolute;
        left: 5px;
        top: 2px;
      }

      em {
        position: absolute;
        top: 3px;
        right: 6px;
      }

      i {
        margin: 0;
      }

      .el-table__indent,
      .el-table__placeholder {
        display: none;
      }
    }
  }
}

.firm-tag {
  font-size: 10px;
  font-weight: 600;
  height: 16px;
  line-height: 14px;
  padding: 0 5px;
}

.tip_content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 5px 5px;
  min-width: 80px;
  line-height: 22px;
}

.img-wrap {
  position: relative;
  padding: 3px 0;
  width: 50px;
  margin: 0 auto;

  .el-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    display: block;
  }

  .el-badge {
    position: absolute;
    top: 3px;
    right: -10px;
    z-index: 2;
  }
}

.toggle-arrow {
  cursor: pointer;
  position: absolute;
  left: 9px;
  top: 5px;
}
