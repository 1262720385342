@import "~@/erp/styles/variables/variables.scss";


















































































































































































































































































































.custom-dialog {
  .container {
    min-height: 300px;
    .el-form-item {
      margin-bottom: 18px;
    }
    p {
      text-align: left;
      line-height: 24px;
    }
  }
}
