@import "~@/erp/styles/variables/variables.scss";




















































































































































































































































.search_form {
  padding: 0 20px 80px 20px;
  .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }
  .btn {
    padding: 20px 0;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: white;
  }
}
::v-deep {
  .el-form-item {
    margin-bottom: 18px !important;
  }
  .el-date-editor {
    width: 100%;
  }
}
