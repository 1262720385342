@import "~@/erp/styles/variables/variables.scss";


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.pro-container {
  .left-h6 {
    display: flex;
    font-size: 16px;
    font-family: 'PingFang Bold';
  }

  .left-h6::before {
    content: '';
    display: block;
    height: 16px;
    width: 2px;
    background: #282c34;
    margin-right: 5px;
    margin-top: 4px;
  }

  .right-content {
    height: 100%;
    border: 1px solid #e4e7ed;
    padding: 20px;
    border-radius: 3px;
  }

  .content {
    height: 100%;
    border: 1px solid #e4e7ed;
    border-radius: 3px;

    .content-head {
      height: 50px;
      line-height: 50px;
      background-color: #f2f2f2;
      width: 100%;
      padding-left: 10px;
    }
  }

  .cascader-demo {
    .el-input__inner::placeholder {
      color: #606266;
    }
  }
}
