@import "~@/erp/styles/variables/variables.scss";



















































































.image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  border-radius: 50%;
  i {
    color: #fff;
    &.fs-25 {
      font-size: 25px;
    }
  }
}
