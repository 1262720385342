@import "~@/erp/styles/variables/variables.scss";


































































































































































































































.footer {
  .el-button + .el-button {
    margin-left: 30px;
  }
}
.ml15 {
  margin-left: 15px;
}
.loc-item {
  display: flex;
  align-items: center;
}
.mt20 {
  margin-top: 20px;
}
.pointer {
  cursor: pointer;
}

.error-p {
  position: absolute;
  left: 249px;
  top: 40px;
  color: #ff4d4f;
  font-size: 12px;
}

::v-deep .loc-wrapper .el-input-number--small {
  width: 100px !important;
}

::v-deep .el-input-number--small .el-input__inner {
  padding: 0 10px !important;
}
::v-deep .el-input-number--small .el-input-number__increase,
::v-deep .el-input-number--small .el-input-number__decrease {
  display: none !important;
}
