@import "~@/erp/styles/variables/variables.scss";

























































































































































































































































































































































































::v-deep {
  .el-table__expanded-cell {
    padding: 0;
  }
  .el-table__body tr {
    min-height: 0px;
  }
  // .expanded + tr {
  //   display: none;
  // }
}
.jz {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-tag-other {
  position: absolute;
  right: 4px;
  z-index: 2;
  background-color: #fff7e8;
  border-color: #ffefd1;
  color: #ffae18;
}
