@import "~@/erp/styles/variables/variables.scss";





































































































































































































.dialog-div {
  max-height: 70vh;
  overflow: hidden;
}
.container {
  height: 55vh;
  overflow: hidden;
  overflow-y: auto;
}
::v-deep {
  .dialog-div {
    .w-el-row {
      width: 95% !important;
      margin: 0 auto !important;
    }
  }
}
