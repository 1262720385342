@import "~@/erp/styles/variables/variables.scss";

























































































































.container {
  width: 100%;
  overflow-x: auto;
  .el-form {
    width: 100%;
    display: flex;
    justify-content: center;
    .el-form-item {
      margin-right: 30px;
    }
  }
}
::v-deep {
  .condition-item {
    position: relative;
    .icon {
      position: absolute;
      top: 0;
      right: -22px;
      font-size: 24px;
    }
  }
}
