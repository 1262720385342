@import "~@/erp/styles/variables/variables.scss";















































































































































































































































































































































































































































































































































































.custom-container {
  .w-150 {
    width: 150px;
  }
  .w-350 {
    width: 350px;
  }
  .mr-10 {
    margin-right: 10px;
  }
  .mt-15 {
    margin-top: 15px;
  }
}
