@import "~@/erp/styles/variables/variables.scss";























































































































































































































































.required-symbol {
  &::before {
    content: '*';
    color: #ff4d4f;
  }
}
.disabled-demo {
  position: relative;
  &::before {
    content: '-';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
.warn-demo {
  ::v-deep .el-input__inner {
    border-color: rgba(217, 0, 27, 1);
    background-color: rgba(217, 0, 27, 0.09803921568627451);
  }
}
.lead-time-table {
  ::v-deep.el-table {
    .el-table__header {
      border-top: 1px solid #ebeef5;
    }
  }
}
