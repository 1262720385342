@import "~@/erp/styles/variables/variables.scss";





















































































































































































































































































































































































































































































































































.funciton-container {
  height: 100%;
  .content-box {
    height: calc(100% - 86px);
    .function-content-box {
      height: 100%;
    }
  }
  .operate {
    height: 50px;
    line-height: 50px;
    padding-left: 15px;
    border: 1px solid #d8d8d8;
    border-top: none;
  }
}
.el-row-border {
  border: 1px solid #ededed;
  height: 100%;
  border-radius: 3px;
  .el-row-header {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #d8d8d8;
    padding: 0 15px;
  }
  .function-content {
    height: 100%;
    ul {
      li {
        height: 40px;
        line-height: 40px;
        padding: 0 15px;
        cursor: pointer;
      }
      li.current {
        background: #f5f7fa;
        font-family: 'PingFang Bold';
        color: #1890ff;
      }
    }
    .function-content-right {
      background: #f5f7fa;
      height: calc(100% - 40px);
      min-height: 500px;
      padding: 10px 20px;
      overflow-y: auto;
      .floor {
        p {
          font-size: 14px;
          margin-bottom: 10px;
        }
        .floor-el-col {
          border: 1px solid #e4e7ed;
          .floor-row-border {
            background: #fff;
            .floor-row-border-left {
              padding: 10px;
              border-right: 1px solid #e4e7ed;
            }
            .floor-row-border-right {
              padding: 10px;
            }
          }
        }
        .floor-el-col + .floor-el-col {
          border-top: none;
        }
      }
      .floor + .floor {
        margin-top: 20px;
      }
    }
  }
}
.row-check-all {
  color: #1890ff;
  cursor: pointer;
}
.three-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-bottom: -5px;
  > .el-row {
    margin-left: 10px;
    margin-bottom: 5px;
  }
}
.no-power-text {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep {
  .el-tabs__content {
    height: calc(100% - 55px);
  }
}
