@import "~@/erp/styles/variables/variables.scss";



















































































































































































































































.track-set {
  .submit {
    display: flex;
    justify-content: center;
    // position: sticky;
    // bottom: 40px;
    margin: 80px 0;
  }
}
