@import "~@/erp/styles/variables/variables.scss";








































































































































































































































































































































































































.normal-wrapper {
  height: 100%;
  .el-table {
    margin-top: 15px;
    height: calc(100% - 100px);
    overflow: auto;
  }
}
.el-radio {
  display: list-item;
  list-style: none;
  margin: 20px 0px;
}
.mt20 {
  margin-top: 20px;
}
.my-p {
  display: inline-block;
  margin-left: 20px;
  color: rgb(96, 98, 102);
  & .edit {
    color: rgb(24, 144, 255);
  }
}
