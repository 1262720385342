@import "~@/erp/styles/variables/variables.scss";






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.dea-with {
  padding: 0 10px;
  height: calc(100vh - 45px - 0px - 9px * 2);
  overflow-y: auto;

  .disable-color {
    color: #c0c4cc;
  }

  .order-content {
    padding: 0 10px 0px;

    .el-divider {
      margin: 20px 0;
      background-color: #ececec;
    }

    .text-sty {
      text-align: left;

      p {
        color: #606266;
        line-height: 20px;
      }
    }

    .el-input,
    .el-select {
      width: 100%;
    }

    .el-form-item {
      margin-bottom: 0;
    }

    .product-list {
      .product-table {
        /deep/ {
          .cell {
            overflow: initial;

            .el-form-item__content {
              padding: 5px 0;

              .el-form-item__error {
                top: 42px;
              }
            }
          }
          .special-form-item  .el-form-item__error {
            top: 60px !important;
          }
        }
      }

      .total-money {
        line-height: 40px;
        text-align: right;

        span {
          font-family: 'PingFang Bold';
          font-size: 18px;
          margin-left: 5px;
        }
      }
    }

    .icon-fenpei {
      font-size: 24px;
      color: #409eff;
      vertical-align: middle;
      cursor: pointer;
    }

    .accessory {
      position: relative;
      padding-left: 10px;
    }


    .tag-wrap {
      position: relative;
      display: flex;
      justify-content: center;

      .el-tag {
        position: absolute;
        top: -10px;
        right: 0;
        z-index: 2;

        &.el-tag-other {
          background-color: #fff7e8;
          border-color: #ffefd1;
          color: #ffae18;
        }
      }

      .el-image {
        width: 64px !important;
        height: 64px !important;
      }
    }
  }

  .address-btns {
    margin: 10px 0 20px;
    text-align: center;

    span {
      margin: 0 10px;
      color: #409eff;
      cursor: pointer;
    }
  }

  h4 {
    margin: 20px 0 10px;
    font-family: 'PingFang-Bold';
  }

  .oper-btn {
    position: sticky;
    bottom: 0;
    text-align: center;
    background: white;
    padding: 10px 0;
  }

  .bind-btn {
    width: 28px;
    height: 28px;
    text-align: center;

    i {
      font-size: 12px;
    }
  }
  .client-remark{
  .client-remark-wrap{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color:#515a6e;
    span{
      width: 70px;
      flex-shrink: 0;
    }
    p{
      width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
    .el-button--text{
      text-decoration: underline;
    }
  }
}
.remark-container{
  .el-divider{
    margin: 0 !important;
  }
}
}
