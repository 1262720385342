@import "~@/erp/styles/variables/variables.scss";












































































































































































































































































































.dialog-content {
  // height: 40vh;
  overflow: hidden;
  overflow-y: auto;

  .el-form {
    width: 95%;
    margin: 0 auto;
  }
}

::v-deep {
  .el-select {
    width: 100%;
  }
}

.pointer {
  height: 40px;
  width: 40px;
}
