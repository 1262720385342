@import "~@/erp/styles/variables/variables.scss";









































































.content {
  padding: 10px;
}
.reply {
  margin-top: 20px;
}
