@import "~@/erp/styles/variables/variables.scss";






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.address {
  display: inline-block;
  vertical-align: middle;
  width: 80%;
  min-height: 20px;
  outline: 0;
  border: none;
  font-size: 13px;
  font-family: LiSong Pro Light;
  font-weight: normal;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-user-modify: read-write-plaintext-only;
}
[contentEditable='true']:empty:not(:focus):before {
  content: attr(data-text);
}

input {
  outline: none;
  background: none;
}
select {
  /*Chrome和Firefox里面的边框是不一样的，所以复写了一下*/
  border-top: 0px #000;
  border-left: 0px #000;
  border-right: 0px #000;
  border-bottom: 1px solid #000000;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 14px;
  font-weight: bold;
  width: 205px;
  outline: none;
  background: none;
}
/*清除ie的默认选择框样式清除，隐藏下拉箭头*/
select::-ms-expand {
  display: none;
}

body {
  font-family: LiSong Pro Light;
}
* {
  padding: 0;
  margin: 0;
  list-style: none;
}

/* 清除浮动 */
.clearfix:before,
.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

.clearfix {
  zoom: 1;
}

.zhubox {
  width: 700px;
  margin: 0 auto;
}

.header {
  text-align: center;
  font-size: 14px;
  position: relative;
  margin-bottom: 40px;
}

.dingD {
  position: absolute;
  right: 20px;
  top: 46px;
}
.dingD2 {
  float: right;
  display: inline-block;
}
.dingD3 {
  position: absolute;
  right: 20px;
  top: 64px;
}

.zthead {
  font-size: 14px;
}

/* 供货方 */
.Supplier,
.Purchaser {
  width: 100%;
  float: left;
}
p,
h4 {
  min-height: 18px;
}
.Supplier > p {
  line-height: 25px;
  margin-bottom: 10px;
  font-weight: bold;
}
.Supplier > p > span {
  display: inline-block;
  min-width: 200px;
  height: 30px;
  border-bottom: 1px solid #000000;
  line-height: 25px;
  padding-right: 5px;
  vertical-align: middle;
}
.Supplier > p > input {
  display: inline-block;
  min-width: 200px;
  height: 30px;
  border: 0;
  border-bottom: 1px solid #000000;
  line-height: 25px;
  padding-right: 5px;
  vertical-align: middle;
}

.Purchaser {
  float: right;
}

.Purchaser p {
  line-height: 25px;
}

/* 日期 */
.contDate {
  width: 100%;
  line-height: 23px;
  text-indent: 20px;
}

.contTime {
  float: left;
}

.pchTime {
  width: 300px;
  float: right;
}

.pchTime1 {
  width: 210px;
  float: right;
}

/* 表格 */
#tableExcel {
  border-collapse: collapse;
  text-align: center;
  font-size: 12px;
}

#tableExcel tr td {
  width: 120px;
  height: 20px;
}

/* 合计 */
.Total {
  width: 300px;
  height: 40px;
  float: left;
}

.Deposit {
  width: 300px;
  height: 40px;
  float: right;
}

/* 要求 */
.Require {
  height: 50px;
  border: 1px solid #000000;
}

.reqTxt1 {
  display: inline-block;
  width: 70px;
  height: 50px;
  border-right: 1px solid #000000;
  text-align: center;
  line-height: 50px;
  vertical-align: top;
}

.reqTxt {
  display: inline-block;
  width: 600px;
}

.reqTxt2 {
  display: inline-block;
  width: 73px;
  vertical-align: top;
}

/* 唛头图片 */
.zhum,
.cem {
  width: 320px;
  float: left;
}

.cem {
  float: right;
}

.zhumCont,
.cemCont {
  width: 100%;
  height: 70px;
  border: 1px solid #000000;
}

.ftName {
  float: right;
  width: 350px;
}

.ftName1 {
  width: 350px;
  float: left;
}

.ftName input {
  height: 20px;
  border: 0;
}

.ftName1 input {
  height: 20px;
  border: 0;
}
/* 图片 */
.proImgb {
  margin-top: 20px;
  display: inline-block;
}
.proImgb > h2 {
  margin-bottom: 20px;
  text-align: center;
}
.proImgb > ul > li {
  width: 49%;
  min-height: 50%;
  float: left;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}
.proImgb > ul > li:nth-child(odd) {
  margin-right: 10px;
}
.proImgb > ul > li > img {
  width: 100%;
}
.proImgb > ul > li > h3 {
  text-align: center;
  line-height: 37px;
}

/* 公共类 */
.fsz {
  font-size: 12px;
}
.fsz > p {
  line-height: 20px;
}
.fsz1 {
  font-size: 13px;
}

.mt {
  margin-top: 10px;
}

.fw {
  font-weight: bold;
}
.spanSty {
  display: inline-block;
  min-width: 10px;
  height: 26px;
  line-height: 26px;
  border: none;
  vertical-align: middle;
  text-align: center;
}
.qzPsty {
  margin-top: 20px;
}
.plhSty {
  line-height: 25px;
}
.ft_iptW {
  width: 75%;
}
table.aaa-table {
  td {
    border: 1px solid gray;
    border-top: none;
    padding: 5px;
    min-height: 35px;
    &.item1 {
      border-right: none;
    }
  }
}

table.item-table {
  td {
    // border: 1px solid gray;
  }
}
.fs14 {
  font-size: 14px;
}
