@import "~@/erp/styles/variables/variables.scss";







































































































































































































































































































































































































































































.price_el {
  border-bottom: 1px solid #f5f5f5;
}

.el-divider--horizontal {
  margin: 30px 0 28px 0 !important;

  .f_s_12 {
    color: #909399;
  }
}

::v-deep {
  .el-select {
    width: 100%;
  }

  .el-textarea__inner {
    padding: 5px 50px 5px 10px;
  }
}
