@import "~@/erp/styles/variables/variables.scss";


















































































































































































































































































































































































































































.sortable-ghost {
  opacity: 0.8;
  color: #fff !important;
  background: #42b983 !important;
}
.dialog-content {
  height: 45vh;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 50px;
  .el-form {
    width: 95%;
    margin: 0 auto;
  }
}
::v-deep {
  .el-select {
    width: 100% !important;
  }
  .el-radio {
    margin-right: 15px;
  }
}
