@import "~@/erp/styles/variables/variables.scss";











































































































































































































































































































































































































































































































































































































































































































































































































































.mail-write {
  height: 100%;
}
.write-box {
  height: 100%;
}
.ml-20 {
  margin-left: 40px;
}
.write-board {
  padding: 10px 20px 20px;
  box-sizing: border-box;
  background: #fff;
  box-sizing: border-box;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  .title-btn {
    flex-shrink: 0;
    width: 82px;
    background: #f8fbff;
    border-radius: 3px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    color: #303030;
    height: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .mail-base-item {
    padding: 10px 0 0;
    display: flex;
    line-height: 30px;
    border-bottom: 1px solid #ebeef5;
    position: relative;
    /deep/ .el-input__inner {
      border: none !important;
      height: 30px !important;
      line-height: 30px !important;
    }
    .mail-base-item_inner {
      flex: 1;
      /deep/ .el-input__inner {
        width: 100%;
        border: none !important;
        height: 30px !important;
        line-height: 30px !important;
      }
    }
    .right-btns {
      position: absolute;
      right: 0;
      top: 0;
      height: 30px;
      line-height: 30px;
    }
  }
  .mail-attach {
    padding: 10px 0;
    & > div:first-child {
      line-height: 16px;
      cursor: pointer;
      i {
        color: #fa6400;
        font-size: 10px;
      }
      span {
        margin-left: 10px;
        font-size: 12px;
        color: #303030;
      }
    }
    .attach-files {
      display: flex;
      flex-wrap: wrap;
      & > div {
        height: 30px;
        background: #f5f7fa;
        border-radius: 3px;
        border: 1px solid #ebeef5;
        padding: 0 12px;
        margin-top: 10px;
        margin-right: 20px;
        line-height: 30px;
        display: flex;
        & > i {
          line-height: 30px;
        }
        & > span {
          margin-right: 10px;
        }
        & > .file-name {
          max-width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        & > i:first-child {
          font-size: 12px;
          color: #fa6400;
          margin-right: 10px;
        }
        & > i:last-child {
          cursor: pointer;
        }
      }
    }
  }
  .ueditor-write {
    flex: 1;
    overflow-y: auto;
    #edui1 {
      height: 100%;
    }
  }
}
.board-bottom {
  height: 60px;
  background: #ffffff;
  box-shadow: 0px -2px 4px 0px rgba(223, 223, 223, 0.5);
  border-radius: 0px 0px 3px 3px;
  padding: 14px 21px;
  border-bottom: 2px solid #f8fbff;
  display: flex;
  justify-content: space-between;
}

.mail-success {
  background: #fff;
  height: 100%;
  padding-top: 85px;
  text-align: center;
  & > .success-icon {
    i {
      font-size: 60px;
      color: #2eb797;
    }
  }
  .mail-success-tip1 {
    font-size: 18px;
    color: #303030;
    margin: 24px auto 12px;
  }
  .mail-success-tip2 {
    font-size: 12px;
    color: #777777;
    margin-bottom: 32px;
  }
}
