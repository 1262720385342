@import "~@/erp/styles/variables/variables.scss";


























































.mail-page {
  height: 100%;
  display: flex;

  &>div {
    height: 100%;
  }

  &>div:first-child {

    flex-shrink: 0;
  }

  &>div:last-child {
    flex: 1;
    width: 0;
  }
}
