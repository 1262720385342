@import "~@/erp/styles/variables/variables.scss";














































































































































































































































































































































































































































.list-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tab-cont {
  flex-shrink: 0;
  height: 40px;
}

.mail-list {
  flex: 1;
  overflow-y: auto;

  .no-data {
    text-align: center;
    color: #999;
    line-height: 40px;
  }

  .item {
    border-bottom: 1px solid #f5f7fa;
    padding: 6px 4px;
    background-color: #fff;
    cursor: pointer;

    &.active,
    &:hover {
      background-color: #f8f8f8;
    }
  }

  .item-head {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .mark {
      font-size: 12px;
      color: #fff;
      line-height: 16px;
      min-width: 30px;
      text-align: center;
      border-radius: 2px;
      margin-right: 10px;

      &.receive {
        background-color: #71aaff;
      }

      &.send {
        background-color: #facd91;
      }
    }

    .name {
      font-size: 14px;
      color: #333;
      line-height: 22px;
      flex: 1;
      display: inline-block;

      .icon-qizhi {
        color: #0486fe;
        margin-left: 5px;
      }
    }

    .email-account {
      font-size: 14px;
      color: #999999 !important;
      font-weight: normal !important;
      line-height: 22px;
      margin-right: 5px;
    }

    .time {
      font-size: 12px;
      color: #999;
      line-height: 22px;
    }
  }

  .item-body {
    display: flex;

    .dt {
      min-width: 30px;
      margin-right: 10px;
      text-align: right;

      i {
        color: #f7b500;

        &.mail-icon {
          display: block;
        }

        &.looked {
          color: #d9d9d9;
        }

        &.replyed {
          color: #2eb797;
        }

        &.send {
          color: #3abc38;
        }
      }
    }

    .dd {
      flex: 1;
      width: 200px;

      .file {
        color: #fa6400;
      }

      .dd-item {
        display: flex;
        align-items: center;
      }
    }

    .summary {
      flex: 1;
      font-size: 14px;
      color: #333;
      line-height: 22px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.pr50 {
        padding-right: 50px;
      }

      &.pr100 {
        padding-right: 100px;
      }
    }

    .mail-title {
      font-weight: bold;
    }

    .desc {
      font-size: 14px;
      color: #999;
      line-height: 22px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .label-list {
    overflow: hidden;

    li {
      float: left;
      margin-right: 10px;
      margin-top: 4px;
      display: flex;
      align-items: center;
      height: 20px;
      font-size: 12px;
      color: #fff;
      padding: 0 4px;
      border-radius: 2px;
      max-width: 100%;

      .label {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .remove-icon {
        margin-left: 4px;
        cursor: pointer;
        display: none;
      }

      &:hover {
        .remove-icon {
          display: block;
        }
      }
    }
  }
}

.search-pagination {
  flex-shrink: 0;
  height: 40px;
  background: #fff;
  border-top: 1px solid #f5f7fa;
  display: flex;
  align-items: center;
  padding: 0 20px;

  ::v-deep .el-select {
    width: 60px;
    margin-left: 2px;

    .el-input__inner {
      padding: 0 4px;
    }

    .el-input__suffix {
      display: none;
    }
  }

  .page-current {
    display: flex;
    width: 60px;
    flex: 1;
    align-items: center;
    justify-content: center;

    .page-num {
      padding: 0 10px;
    }
  }

  .jump {
    ::v-deep .el-input {
      width: 32px;
      margin-left: 2px;
      margin-right: 2px;

      .el-input__inner {
        padding-left: 4px;
        padding-right: 4px;
        text-align: center;
      }
    }
  }
}

i.fenpei {
  display: block;
  font-size: 14px;
  line-height: 16px;
  color: #d9d9d9 !important;
}
