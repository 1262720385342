@import "~@/erp/styles/variables/variables.scss";







































































































































































































































































::v-deep.table-container {
  .page-header {
    padding-top: 10px;
  }
  .product {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
