@import "~@/erp/styles/variables/variables.scss";










































































































































































































































































































































































.el-col-5 {
  width: 20% !important;
}

.pro-pic {
  height: $base-table-height;

  .pro-content {
    overflow-y: auto;

    .card-content {
      .card-img {
        position: relative;
        width: 100%;
        height: 209px;
        overflow: hidden;
        border-radius: 3px 3px 0 0;

        .el-image {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }
        }

        .card-operate {
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .audit-type {
          position: absolute;
          right: 0;
          top: 0;
          border-radius: 0 3px 0 0;
        }
      }

      .card-img:hover {
        .el-image {
          img {
            transform: scale(1.05);
          }
        }
      }

      .card-msg {
        padding: 10px 15px;

        >.el-row+.el-row {
          margin-top: 5px;
        }

        .pro-type {
          color: #999;
        }

        .pro-nameEn,
        .pro-nameCn {
          text-align: justify;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .pro-code {
          color: #1890ff;
        }
      }
    }
  }
}

::v-deep {

  .el-card.is-hover-shadow:hover,
  .el-card.is-hover-shadow:focus {
    cursor: pointer;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  }

  .image-slot {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ededed;

    img {
      width: 152px !important;
      height: 50px !important;
      color: red;
    }
  }
}
