@import "~@/erp/styles/variables/variables.scss";








































































































.el-radio-group {
  p {
    margin-bottom: 15px;
    padding-left: 20px;
  }
}
::v-deep {
  .el-tabs__content {
    height: 100%;
  }
}
.leave {
  color: #f56c6c;
  white-space: nowrap;
}
.bottom {
  text-align: center;
  position: fixed;
  bottom: 20px;
  left: 50%;
}
.title {
  margin-bottom: 20px;
  color: #000;
  font-size: 15px;

  .info {
    color: #999;
  }
}
