@import "~@/erp/styles/variables/variables.scss";






























































































































































.hideAdd {
  /deep/ .el-upload--picture-card {
    display: none;
  }
}
