@import "~@/erp/styles/variables/variables.scss";
















































































































































































































































































































































.header-main-title {
  color: #555;
  font-size: 18px;
}
.header-sub-title {
  color: #666;
  font-size: 14px;
  margin-left: 20px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
::v-deep {
  .is-active {
    background-color: #fff !important;
  }
  .select-user {
    .el-tag {
      & + .el-tag {
        margin-left: 2px;
      }
      .el-select__tags-text {
        max-width: 28px;
      }
    }
    .el-tag__close {
      display: none;
    }
  }
}
.time-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > i {
    margin-left: 20px;
    cursor: pointer;
    color: #ff4d4f;
  }
}
.weekDayHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  > i {
    color: #ff4d4f;
    &:last-of-type {
      cursor: pointer;
      font-size: 18px;
      margin-left: 10px;
    }
  }
}
