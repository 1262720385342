@import "~@/erp/styles/variables/variables.scss";




























































.dialog-content {
  height: 70vh;
  .compare-row {
    height: 100%;
    > .el-col {
      height: 100%;
      border: 1px solid #ededed;
      .tip-row {
        width: 100%;
        line-height: 25px;
        text-align: center;
        background: #ededed;
        font-family: 'PingFang Bold';
      }
      .detail-content {
        height: calc(100% - 25px);
        padding: 10px;
        overflow-y: auto;
      }
    }
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
