@import "~@/erp/styles/variables/variables.scss";

















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-drawer__body {
  overflow: hidden;
  box-sizing: border-box;
  .el-steps--vertical {
    height: auto;
  }
}
.wrapper {
  padding: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .top {
    display: flex;
    justify-content: space-between;
    height: 45px;
    align-items: flex-start;
    .middle {
      width: 476px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-x: hidden;
      i {
        cursor: pointer;
        padding: 0 5px;
        color: #409eff;
        &.disabled {
          cursor: not-allowed;
          color: gray;
        }
      }
    }

    .right {
      width: 120px;
      display: flex;
      flex-direction: column;
      padding-top: 22px;
    }
    .left {
      width: 80px;
    }
    button {
      padding: 2px 3px;
      color: #000000;
      background: none;
      border: 1px solid #eee;
      font-size: 12px;
      cursor: pointer;
      border-radius: 3px;
      &.back {
        margin-right: 10px;
      }
      &.save {
        background: #409eff;
        color: white;
      }
    }
  }

  .node-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    color: #000000 !important;
    width: 500px;
    .left {
      width: 130px;
    }
    .middle {
      margin-right: 5px;
      flex: 1;
    }
    .right {
      margin-right: 5px;
      width: 120px;
    }
  }
  .bottom {
    height: calc(100vh - 95px);
    overflow: auto;
    .no {
      position: absolute;
      z-index: 3;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      background-color: #409eff;
      font-size: 14px;
      color: white;
      background-color: #409eff;
      font-size: 14px;
      color: white;
      border-radius: 24px;
      top: 0;
      left: 0;
      text-align: center;
    }
  }

  .ml5 {
    margin-right: 5px;
  }
}
.no-line {
  text-decoration: none;
}
.red {
  color: red;
  font-size: 12px;
  text-align: center;
}
.sel {
  cursor: pointer;
}
.no-show {
  visibility: hidden;
}
.mask {
  position: absolute;
  top: 72px;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
  z-index: 9999999;
}
.mask-span {
  display: inline-block;
  font-size: 59px;
  color: red;
  z-index: 10000002;
  top: 220px;
  right: 10px;
  position: absolute;
  transform: rotate(45deg);
}
