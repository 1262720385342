@import "~@/erp/styles/variables/variables.scss";



























































































































































































































.sort {
  text-align: center;
  cursor: all-scroll;
  i {
    font-size: 18px;
  }
}
::v-deep {
  .list-table {
    height: calc(100vh - 200px);
    border-left: none;
    overflow-y: auto;
  }
  .el-pagination {
    height: 36px;
  }
}
