@import "~@/erp/styles/variables/variables.scss";











































































































































































.oper {
  padding: 20px 10px;
  height: calc(100vh - 45px);
  overflow-y: scroll;
}
