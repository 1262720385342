@import "~@/erp/styles/variables/variables.scss";



























































































































































































































































.main-height {
  height: calc(100% - 56px);
}
.page-header {
  height: 33px;
}
::v-deep {
  .page-header {
    .el-form-item {
      margin-bottom: 0;
    }
  }
}
