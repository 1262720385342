@import "~@/erp/styles/variables/variables.scss";


































.fields-wrapper {
  display: inline-block;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
