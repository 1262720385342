@import "~@/erp/styles/variables/variables.scss";





































































































.pro-container {
  ::v-deep {
    .el-tabs__item {
      height: 60px !important;
      line-height: 60px !important;
      padding: 0 40px;
    }
    .is-active {
      background-color: #ebf5ff;
    }
  }
}
