@import "~@/erp/styles/variables/variables.scss";



















































































































.remote-select {
  width: 100%;
  /deep/ .el-select-dropdown__list {
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    li {
      max-width: 470px;
    }
  }
}
