@import "~@/erp/styles/variables/variables.scss";

































































































































































































































#edui1 {
  height: 100%;
}
