@import "~@/erp/styles/variables/variables.scss";






































































































































.base-table {
  height: 100%;
  .table-custom {
    height: calc(100% - 52px);
  }
  .page-custom {
    height: 52px;
    overflow: hidden;
  }
  ::v-deep {
    .el-pagination {
      margin: 10px 0;
      display: flex;
      justify-content: center;
    }
    .el-table__row {
      height: 45px !important;
    }
    // .el-table {
    //   border-left: none;
    // }
    // .base-table-header {
    //   .cell {
    //     white-space: nowrap;
    //     text-overflow: ellipsis;
    //     overflow: hidden;
    //   }
    // }
    // .no-border {
    //   border-right: 1px solid transparent !important;
    // }

    // 固定列底部边线去除
    .el-table__fixed::before,
    .el-table__fixed-right::before {
      height: 0;
    }
  }
}
