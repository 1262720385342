@import "~@/erp/styles/variables/variables.scss";































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































::v-deep {
  .el-table__row .el-button {
    margin: 0 5px;
  }
}
