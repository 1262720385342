@import "~@/erp/styles/variables/variables.scss";



































.index-empty-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
