@import "~@/erp/styles/variables/variables.scss";



















































































































































































































































































































































































































.pro-container {
  .left-h6 {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: 'PingFang Bold';
  }
  .left-h6::before {
    content: '';
    display: block;
    height: 16px;
    width: 2px;
    background: #282c34;
    margin-right: 5px;
    margin-top: 4px;
  }
  .right-content {
    height: 100%;
    border: 1px solid #e4e7ed;
    padding: 20px;
    border-radius: 3px;
    .calcu {
      margin-left: 110px;
      p {
        label {
          font-size: 12px;
          color: #999;
        }
      }
    }
    .num-price-step {
      ::v-deep {
        .el-form-item__label {
          font-size: 16px;
          margin-right: 20px;
        }
      }
    }
  }
}
