@import "~@/erp/styles/variables/variables.scss";















































































































































































































































.order-synchro {
  .synchro-title {
    display: inline-block;
    border-bottom: 1px solid red;
    box-sizing: border-box;
    padding: 5px 8px;
  }
  .synchro-ctn {
    display: flex;
    flex-direction: column;
  }
  .set-box {
    border: 1px solid #e2e2e2;
    box-sizing: border-box;
    padding: 10px 10px 10px 10px;
    margin-top: 10px;
    .set-money {
      margin-top: 10px;
    }
  }
}
