@import "~@/erp/styles/variables/variables.scss";





















































































































.container {
  width: 100%;
  overflow-x: auto;
  .el-form {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
