@import "~@/erp/styles/variables/variables.scss";






































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-form {
  padding: 0 10px 0 0;

  .el-select {
    width: 100%;
  }

  .item-fee {
    display: flex;

    .item {
      flex: 1;
    }
  }
}

.custom-container {
  .el-divider--horizontal {
    margin-bottom: 15px;
  }

  .product-info {
    display: flex;
    padding: 10px;
    text-align: left;

    .pro-item {
      width: 100%;
      white-space: pre-wrap;
      margin-bottom: 5px;
    }

    .left {
      flex: 1;
      // cursor: pointer;
    }

    p {
      display: flex;
      flex-direction: column;

      .el-tag {
        margin-bottom: 5px;
        max-width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.whi {
  white-space: pre-wrap;
  text-align: left;
}

.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}

.el-icon-arrow-down {
  font-size: 20px;
  font-weight: bolder;
}
.export-content{
  p{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    i{
      font-size: 20px;
      color: rgb(236, 142, 18);
      margin-right: 4px;
    }
  }
  div{
    display: flex;
    align-items: center;
    padding-left: 25px;
    span{
      margin-right: 8px;
    }
  }
}
