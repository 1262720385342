@import "~@/erp/styles/variables/variables.scss";































































































































































































































































































































































































































































































































.num-sty {
  p {
    margin-bottom: 5px;

    label {
      width: 95px;
      text-align: right;
      display: inline-block;
      padding-right: 10px;
    }
  }

  /deep/ .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
    display: none;
  }
}

.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.discount-sty {
  position: relative;
  text-align: left;

  .el-input {
    width: 70px;
  }

  .el-button {
    position: absolute;
    top: 8px;
    right: -5px;
    z-index: 2;
    height: 28px;
    width: 28px;
    margin: 0;

    /deep/ i {
      font-size: 16px;
      margin: -3px;
    }
  }
}

::v-deep {
  .el-dialog__body {
    padding: 5px 20px 20px;
  }

  .el-form-item__label {
    color: #303133;
  }

  .el-form-item--small.el-form-item {
    margin-bottom: 10px;
  }
}
