@import "~@/erp/styles/variables/variables.scss";































































































































































































































































































::v-deep {
  .el-table {
    height: calc(100vh - 200px);
    border-left: none;
    overflow-y: auto;
  }
  .el-pagination {
    height: 36px;
  }
}
