@import "~@/erp/styles/variables/variables.scss";






































































































































































.operate {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #ededed;
  padding: 20px;
}
