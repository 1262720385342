@import "~@/erp/styles/variables/variables.scss";





















































































































































































































































































































































































































































































































































































































































.page-header {
  height: 33px;
}
::v-deep {
  .page-header {
    .el-form-item {
      margin-bottom: 0;
    }
  }
}
.divider {
  height: 1px;
  background-color: #f5f7fa;
  margin: 9px 0 15px 0;
}
.calcu-p {
  font-size: 12px;
  color: #999;
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
.el-dialog-div {
  height: 60vh;
  overflow: hidden;
  overflow-y: auto;
}
::v-deep {
  .el-dialog-div {
    .el-form {
      width: 95% !important;
      margin: 0 auto !important;
    }
  }
}
.pointer {
  cursor: pointer;
}
.operation {
  display: flex;
  justify-content: space-evenly;
  i {
    font-size: 12px;
  }
}
.ml-10 {
  margin-left: 10px;
}
