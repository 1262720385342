@import "~@/erp/styles/variables/variables.scss";



























































































































.procurement-demand-item {
  font-size: 14px;
  .procurement-demand-item--top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .top-left {
      font-size: 14px;
      font-family: Semibold;
      color: #000000;
    }
    .top-right {
      font-size: 12px;
      color: #5e5e5e;
    }
  }
  .procurement-demand-item--bottom {
    font-size: 12px;
    p {
      color: #5e5e5e;
      font-family: Regular;
      .label {
        margin-right: 5px;
      }
    }
  }
}
