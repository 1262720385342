@import "~@/erp/styles/variables/variables.scss";

























































































































































































































.el-dialog__wrapper {
  display: flex;
  /deep/.el-dialog {
    margin: auto !important;
  }
}
/deep/ .justment-expenses-dialog {
  .el-dialog__footer {
    text-align: center;
  }
  // 调整原因宽度
  .el-form > .el-form-item:first-of-type {
    width: 500px;
  }
  .el-table {
    .cell {
      text-align: center;
    }
    // 表格头部的间距
    tr {
      height: auto;
      th.el-table__cell {
        padding: 2px 0;
        height: auto;
      }
    }
    // 调整前 位置
    .el-table__body-wrapper {
      .el-table__expanded-cell {
        padding-left: 847px;
        .gutter {
          display: none;
        }
      }
    }
    .amount {
      display: flex;
      justify-content: center;
      &.red {
        color: red;
      }
    }
    // 去掉底部边距
    .el-form-item {
      margin-bottom: 0;
      .el-form-item__content {
        position: static;
        .el-form-item__error {
          top: inherit;
          left: inherit;
          padding-top: 3px;
        }
      }
    }
  }
}
