@import "~@/erp/styles/variables/variables.scss";










































































































































































































































































































































































































































































































































.img-wrap {
  position: relative;
  padding: 3px 0;
  width: 50px;
  margin: 0 auto;
  .el-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    display: block;
  }
  .el-badge {
    position: absolute;
    top: 3px;
    right: -10px;
    z-index: 2;
  }
}
