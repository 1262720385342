@import "~@/erp/styles/variables/variables.scss";














































































































































































.weclome-wrapper {
  display: flex;
  min-width: 1160px;
  justify-content: space-between;
  height: 25px;
  margin-bottom: 15px;

  .index-setting {
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .el-image {
      height: 15px;
      width: 15px;
    }
  }

  .name {
    .welcome {
      height: 20px;
      font-size: 22px;
      font-weight: 600;
      color: #000000;
      line-height: 22px;
      margin-right: 10px;
    }

    .time {
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
    }
  }

  .fs-18 {
    font-size: 18px;
    margin-right: 10px;
  }

  div.item {
    padding: 15px;
  }

  .tip {
    font-size: 12px;
    font-weight: lighter;
    color: #8c95a9;
    padding-left: 15px;
  }

  .tip-error {
    color: red;
  }
}
