@import "~@/erp/styles/variables/variables.scss";














































































































































































































































































































.top {
  margin-bottom: 20px;
}
.table-wrapper {
  margin-top: 15px;
}
