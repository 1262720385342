@import "~@/erp/styles/variables/variables.scss";


































































































































































































































































































































.config-table {
  margin-top: 20px;
  .table-fixed {
    max-height: 550px !important;
    ::v-deep {
      // .el-table {
      //   height: 600px !important;
      // }
      //   th {
      //     height: 35% !important;
      //     max-height: 35px !important;
      //     line-height: 35px !important;
      //   }
      //   td {
      //     height: 35% !important;
      //     max-height: 35px !important;
      //     line-height: 35px !important;
      //   }
      .table-custom {
        height: 100%;
        .el-table {
          height: 100% !important;
        }
      }
      .el-tooltip {
        width: auto !important;
      }
    }
  }
}
