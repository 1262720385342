@import "~@/erp/styles/variables/variables.scss";












































































































































































.title {
  margin-bottom: 20px;
  color: #000;
  font-size: 15px;

  .info {
    color: #999;
  }
}
