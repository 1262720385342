@import "~@/erp/styles/variables/variables.scss";







































































































































.label-item {
  display: flex;
  align-items: center;

  .label-mask {
    display: inline-block;
    height: 10px;
    width: 10px;
    margin-right: 10px;
  }

  &.active,
  &:hover {
    cursor: pointer;
    background-color: #f5f7fa;
  }
}

::v-deep {
  .el-tree {
    padding-bottom: 10px !important;
    margin-left: 15px;
  }
}

::v-deep .el-tree-node__content {
  height: 26px !important;
}

::v-deep {
  .el-collapse-item__header {
    padding-left: 21px !important;
  }

  .el-collapse-item__content {
    padding-bottom: 0px;
  }
}

.mark-search-wrapper {
  padding-left: 21px;
  padding-right: 8px;
  margin-bottom: 10px;
}
