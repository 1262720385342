@import "~@/erp/styles/variables/variables.scss";

















































































































































































































::v-deep {
  .el-tag {
    margin-bottom: 10px;
    margin-left: 10px;
  }
}
