@import "~@/erp/styles/variables/variables.scss";


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.ratio_input {
  width: 130px;
}

.pro-container {
  .left-h6 {
    display: flex;
    font-size: 16px;
    font-family: 'PingFang Bold';
  }

  .left-h6::before {
    content: '';
    display: block;
    height: 16px;
    width: 2px;
    background: #282c34;
    margin-right: 5px;
    margin-top: 4px;
  }

  .right-content {
    height: 100%;
    border: 1px solid #e4e7ed;
    padding: 20px;
    border-radius: 3px;
  }

  .content {
    height: 100%;
    border: 1px solid #e4e7ed;
    border-radius: 3px;

    .content-head {
      height: 50px;
      line-height: 50px;
      background-color: #f2f2f2;
      width: 100%;
      padding-left: 10px;
    }

    .panel-item {
      padding: 20px 10px 0;
    }

    .panle-item-title {
      padding: 10px 20px;

      .left-h6 {
        line-height: 30px;
        margin-bottom: 20px;

        &::before {
          content: '';
          display: block;
          height: 26px;
          width: 4px;
          background: rgba(2, 125, 180, 1);
          margin-right: 5px;
        }
      }

      .outnumber-panle {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        position: relative;

        ::v-deep .el-input {
          width: 100px;
          margin: 0 10px;
        }

        .box {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          ::v-deep .el-input {
            width: 100px;
            margin: 0 10px;
          }
        }

        .calculation-cycle {
          position: absolute;
          right: 0;
          top: 0px;
        }
      }
    }
  }

  ::v-deep {
    .el-table {
      .el-table__body td {
        height: 86px;

        .el-form-item {
          margin: 18px 0;
        }
      }
    }
  }
}
