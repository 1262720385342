@import "~@/erp/styles/variables/variables.scss";


























































































































































































































































































































































































































































































































































































































































































































































































.top-sty {
  display: flex;
  justify-content: flex-end;
  .el-checkbox {
    margin: 5px 10px;
  }
}
::v-deep {
  .el-table__row .el-button {
    margin: 0 5px;
  }
  .costAdjustNumber .cell,
  .productCn .cell,
  .lotNo .cell {
    width: auto !important;
  }
  .page-header {
    .el-input {
      width: 160px;
    }
  }
  .iconfont {
    font-size: 14px;
  }
}
