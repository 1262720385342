@import "~@/erp/styles/variables/variables.scss";





























































































































































::v-deep {
  .el-dialog {
    max-height: 85vh;
    overflow-y: scroll;
    .el-dialog__header {
      position: sticky;
      top: 0;
      background-color: #ffffff;
      z-index: 1;
    }
    .el-dialog__footer {
      position: sticky;
      bottom: 0px;
      background-color: #ffffff;
      z-index: 1;
    }
  }
  .imgs-sty{
    height: auto;
  }
}
