@import "~@/erp/styles/variables/variables.scss";
















































































































































































































































































































































































































































































































































































































.custom-container {

  // 产品编码
  .prod_code {
    color: #409eff;
    text-decoration: underline;
    cursor: pointer;
  }
}

//自定义显示
.custom-table-checkbox {
  .el-checkbox {
    display: block !important;
    margin: 8px 0 8px 10px !important;
  }
}

.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}

::v-deep {
  .el-table--border th.gutter:last-of-type {
    display: table-cell !important;
  }
}
