@import "~@/erp/styles/variables/variables.scss";


























































































































































































































































.w-230 {
  width: 150px;
}
.w-100 {
  width: 100px;
}
.w-120 {
  width: 120px;
}
.w-130 {
  width: 140px;
}
.w-140 {
  width: 140px;
}
.w-150 {
  width: 150px;
}
.w-230 {
  width: 230px;
}
