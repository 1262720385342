@import "~@/erp/styles/variables/variables.scss";



















































































































































































































































































































































































































































































.order-node {
  .node-title {
    display: inline-block;
    border-bottom: 1px solid red;
    box-sizing: border-box;
    padding: 5px 8px;
  }
}
.popperStyle {
  display: inline-block;
  width: 350px !important;
  line-height: 18px;
}
::v-deep {
  .el-form-item {
    margin-top: 18px;
  }
}
