@import "~@/erp/styles/variables/variables.scss";
































































































































































































































































































































































































































































































































































































































































































































































































































.bom {
  width: 100%;
  height: 100%;
  padding-bottom: 70px;
  position: relative;
  .L {
    width: 58%;
    padding: 0 10px 0 20px;
    border-right: 1px solid #ededed;
  }
  .R {
    width: 42%;
    padding: 0 20px;
  }
  .btns {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    text-align: right;
    padding: 14px 20px;
    border-top: 1px solid #ededed;
  }
  .el-tabs {
    .el-button {
      height: 34px !important;
    }
    .el-table {
      height: calc(100vh - 280px);
      overflow-y: auto;
    }
    .el-pagination {
      height: 40px;
    }
  }
  .el-form {
    height: calc(100vh - 149px);
    overflow-y: auto;
  }

  .img-wrap {
    position: relative;
    padding: 3px 0;
    width: 50px;
    margin: 0 auto;
    .el-image {
      width: 50px;
      height: 50px;
      object-fit: cover;
      display: block;
    }
    .el-badge {
      position: absolute;
      top: 3px;
      right: -10px;
      z-index: 2;
    }
  }
}
