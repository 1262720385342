@import "~@/erp/styles/variables/variables.scss";


















































































































































































































































































































































































































































































































































.audit-top {
  width: 90%;
  margin: 0 auto;
}
.audit-main {
  width: 90%;
  height: calc(100% - 217px);
  margin: 0 auto;
  margin-top: 20px;
  .audit-node {
    width: 100%;
    height: calc(100% - 46px);
    overflow-x: auto;
    padding-top: 50px;
    .label-color {
      color: #777474;
    }
  }
}
.choose-user-box {
  border: 1px solid #ededed;
  padding: 15px 10px;
  height: calc(100% - 152px);
  margin-top: 15px;
  border-radius: 2px;
  overflow-y: auto;
}
.pro-footer {
  position: fixed;
  bottom: 0;
  width: 86%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;
  background: #fff;
}

::v-deep {
  .el-timeline {
    display: flex;
    .el-card {
      width: 300px;
      height: 400px;
      margin-top: 40px;
      position: relative;
      .el-card__body {
        padding: 20px 15px;
        height: 100%;
      }
      .del-node {
        position: absolute;
        top: 5px;
        right: 15px;
        cursor: pointer;
      }
    }
    .el-timeline-item:last-child {
      .el-timeline-item__node {
        display: none;
      }
    }
  }
  .el-timeline-item__tail {
    position: absolute;
    top: 5px;
    left: 4px;
    width: 100%;
    height: 1px;
    background: #ededed;
  }
  .el-timeline-item {
    padding-right: 30px;
  }

  .el-timeline-item__node--normal {
    left: 48%;
    width: 15px;
    height: 15px;
    top: -3px;
  }
  .el-timeline-item__content {
    margin-top: 20px;
  }
  .el-timeline-item__dot {
    top: -9px;
  }
  .el-timeline {
    li.el-timeline-item:first-child {
      padding-right: 90px !important;
    }
  }
}
