@import "~@/erp/styles/variables/variables.scss";



















































.site-msg-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 300px;
  overflow: hidden;
  width: 400px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: rgba(226, 238, 207, 1);
  overflow: auto;
  color: #303133;
  transition: 0.3s;
  padding: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  h3 {
    text-align: center;
  }
  .main {
    margin-top: 10px;
    max-height: 200px;
    overflow: auto;
    div {
      margin-bottom: 10px;
    }
  }
  .close {
    color: red;
    font-size: 16px;
    position: absolute;
    right: 15px;
    font-weight: 700;
    top: 15px;
    cursor: pointer;
  }
}
