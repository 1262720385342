@import "~@/erp/styles/variables/variables.scss";






























































































































































































































































































































































































































































































































































































































































































































































































































































































































.page-header {
  height: 33px;
}
::v-deep {
  .page-header {
    .el-form-item {
      margin-bottom: 0;
    }
  }
}
.divider {
  height: 1px;
  background-color: #f5f7fa;
  margin: 9px 0 15px 0;
}
.pointer {
  cursor: pointer;
}

.link {
  text-decoration: underline;
  color: #1890ff;
}
.progress {
  padding: 15px 20px;
  .progress-wrapper {
    display: flex;

    & .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      & .line {
        flex: 1;
        width: 1px;
        background-color: #c0c4cc;
        margin-top: 5px;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      & span {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
}
.error-color {
  color: #f56c6c;
}
.success-color {
  color: #67c23a;
}
.warning-color {
  color: #e6a23c;
}
.war .pointer {
  cursor: pointer;
}
//自定义显示
.custom-table-checkbox {
  .el-checkbox {
    display: block !important;
    margin: 8px 0 8px 10px !important;
  }
}
.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}
.el-tooltip__popper {
  max-width: 400px;
}
