@import "~@/erp/styles/variables/variables.scss";






























































































































































































































































































































































































































































.ticket-management{
  padding: 10px;
  .status-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    // ::v-deep{
    //   input[aria-hidden="true"]{
    //     display: none !important;
    //   }
    // }
  }
}
