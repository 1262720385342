@import "~@/erp/styles/variables/variables.scss";





































































.custom-container{
  width: calc(100% - 400px);
}
