@import "~@/erp/styles/variables/variables.scss";



















































































































































































.custom-container.track-container {
  height: 100vh !important;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
}
.top-wrapper {
  flex: 3;
  overflow-y: hidden;
}

.bottom-wrapper {
  flex: 2;
  overflow: hidden;
}
