@import "~@/erp/styles/variables/variables.scss";


























































































































































































































.dialog-content {
  .el-form {
    width: 95%;
    margin: 0 auto;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
  .el-dialog {
    border-radius: 4px;
  }
  .el-dialog__header {
    padding: 30px 30px 10px;
    .el-dialog__title {
      font-size: 20px;
    }
  }
  .el-form-item--small.el-form-item {
    margin-bottom: 30px;
  }
  .el-dialog__headerbtn {
    font-size: 30px;
    right: 30px;
  }
}
