@import "~@/erp/styles/variables/variables.scss";













































































.bottom {
  text-align: center;
  position: fixed;
  bottom: 20px;
  left: 50%;
}
