@import "~@/erp/styles/variables/variables.scss";































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.dialog-content {
  height: 60vh;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 20px;

  .el-form {
    label {
      white-space: nowrap;

      i {
        color: red;
      }
    }
  }
}

label {
  white-space: nowrap;
}

.spec-row {
  >.el-row {
    margin-left: -10px;
  }

  .el-tag {
    margin-left: 10px;
    margin-bottom: 5px;
  }
}

.empty-content {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// 规格

::v-deep {
  .el-select {
    width: 100%;
  }
}

h4 {
  font-weight: normal;
}

.pro-container {
  margin-bottom: 50px;
}

.product_info_table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    height: 50px;
    text-align: center;
    border: 1px solid #ebeef5;
  }

  th {
    background-color: #f5f7fa;
  }
}

.price-red {
  color: red;
}

.c_pointer.active {
  background-color: #e8f4ff;
  border-color: #d1e9ff;
  color: #1890ff;
}

.calcu-result {
  p+p {
    margin-left: 35px;
  }

  span {
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
  }

  .specical-span {
    font-weight: normal;
    color: #666;
  }
}

.address-row {
  h4 {
    white-space: nowrap;
    margin-right: 10px;
  }
}

::v-deep {
  .el-checkbox-group {
    .el-checkbox-button+.el-checkbox-button {
      margin-left: 10px;
      border-left: 1px solid #f2f2f2;
    }

    .el-checkbox-button__inner {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      background-color: #f2f2f2;
      border-color: rgba(228, 228, 228, 0.93);
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    color: #1890ff;
    background-color: #e8f4ff;
    border-color: #e8f4ff;
    box-shadow: -1px 0 0 0 #e8f4ff;
  }

  .el-dialog__footer {
    text-align: center;
  }
}

.custom-table {
  overflow: auto;

  p+p {
    margin-top: 3px;
  }
}

.tooltip-content {
  max-height: 350px;
  overflow: hidden;
  overflow-y: auto;
  padding: 15px;

  p {
    font-size: 14px;
  }

  p+p {
    margin-top: 8px;
  }
}

.custom-table {
  td {
    .row-charge {
      height: 115px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}

.testData-row {
  max-height: 370px;
  overflow-y: auto;
}
