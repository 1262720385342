@import "~@/erp/styles/variables/variables.scss";


























































































































































































































































































































































































































































































































































































































































































































































.public-col {
  // height: 40vh;
  border: 1px solid #ededed;
  padding: 10px;
  p {
    line-height: 32px;
  }
}
.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row-bottom {
  margin-top: 2vh;
}
::v-deep {
  .el-table__body-wrapper {
    height: 300px;
    overflow-y: scroll;
  }
}
