@import "~@/erp/styles/variables/variables.scss";































































































.container {
  padding: 10px 30px;
  .rule {
    margin-left: 15px;
    height: calc(100vh - 220px);
    .el-input {
      width: 150px;
      margin: 0 10px;
    }
  }
  .oper-btn {
    position: sticky;
    bottom: 0;
    text-align: center;
    background: white;
    padding: 10px 0;
  }
}
::v-deep {
  .el-input-group__prepend,
  .el-input-group__append {
    background: none;
    padding: 0 10px;
  }
}
