@import "~@/erp/styles/variables/variables.scss";

























































































































































































































































































































































































































































































































































































































































































































































































































































































































.tag-wrap {
  position: relative;
  display: flex;
  justify-content: center;

  .disable-color {
    color: #c0c4cc;
  }

  .el-tag {
    position: absolute;
    top: -10px;
    right: 0;
    z-index: 2;
  }

  .el-image {
    width: 50px;
    height: 50px;
    border-radius: 2px;
  }
}

.total-money {
  line-height: 40px;
  text-align: right;

  span {
    font-family: 'PingFang Bold';
    font-size: 18px;
    margin-left: 5px;
  }
}

.table-container {
  display: flex;
  justify-content: space-between;

  .l-table {
    width: 69%;
    height: min-content;
  }

  .r-table {
    width: 30%;
    height: min-content;
  }
}

.public-table {
  border: 1px solid #ebeef5 !important;
}

.line-style {
  display: flex;
  justify-content: flex-start;
  height: 32px;
  line-height: 32px;
  margin-top: 20px;
}

.tl {
  text-align: left;
}

.fileAnnex-span {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  background: #9e9e9e;
  color: #fff !important;
  border-radius: 50%;
  margin-left: 5px;
  font-size: 12px;
}
