@import "~@/erp/styles/variables/variables.scss";





























































































































































































































































































































































































































.table-container {
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  .loc-tree-wrapper {
    flex: 1;
    overflow-y: auto;
    width: 60%;
    max-width: 560px;

    adding: 10px;
    border: 1px solid #eee;
    border-radius: 10px;

    padding: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    .el-tree {
      width: 100%;
    }
  }
  .el-tree {
    width: 100%;
  }
  .page-header {
    flex: 1;
    align-items: flex-start;
    margin: 15px;
  }
}
.loc {
  width: 150px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: inline-block;
  position: relative;
  & .el-icon-error {
    position: absolute;
    right: -5px;
    top: -5px;
    color: red;
    cursor: pointer;
    visibility: hidden;
  }
  &:hover {
    & .el-icon-error {
      visibility: visible;
    }
  }
}
.text-center {
  text-align: center;
}
.opt-wrapper {
}

.mr5 {
  margin-right: 5px;
}
.btn-wrappper {
  text-align: center;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;

  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  overflow: hidden;
  .node-content {
    width: calc(100% - 180px);
    .node-label {
      width: 100%;
      display: inline-block;
      vertical-align: bottom;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15px;
      font-weight: 500;
    }
  }
  .el-button {
    font-size: 15px;
  }
}
