@import "~@/erp/styles/variables/variables.scss";


























































































































.dialog-content {
  .el-form {
    width: 95%;
    margin: 0 auto;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
  .custom-item {
    .el-form-item__label:before {
      content: '' !important;
    }
  }
}
.tips-color {
  color: #999;
}
.no-wrap {
  white-space: nowrap;
}
.row-box {
  span {
    white-space: nowrap;
  }
}
.sort-box {
  display: flex;
  align-items: center;
  span {
    margin-right: 20px;
  }
  ::v-deep.el-select {
    width: 240px;
  }
}
