@import "~@/erp/styles/variables/variables.scss";















































































.condition {
  display: flex;
  justify-content: space-between;
  .condition-item {
    width: 49%;
  }
}
.target-box {
  border: 1px solid black;
  margin-top: 15px;
  box-sizing: border-box;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  .target {
    margin: 4px;
  }
}
.conditon-title {
  color: red;
  border-bottom: 1px solid red;
  display: inline-block;
  padding: 5px 10px;
}
.together {
  margin-top: 15px;

  .together-item {
    display: flex;
    align-items: center;
  }
}
