@import "~@/erp/styles/variables/variables.scss";






































































































































































































































































































































































































.table-container {
  padding: 20px !important;
  & .title {
    display: flex;
    align-items: center;
  }
  & .detail-fields {
    // background-color: #f5f7fa;
    .el-row {
      margin: 20px 0;
      & .label {
        color: #282c34;
        width: 75px;
        text-align: right;
        display: inline-block;
        margin-right: 15px;
      }
      & .content {
        color: #000016;
      }
    }
  }
}
.header {
  font-size: 18px;
  color: #606266;
  font-family: 'PingFang Bold';
}

.mr15 {
  margin-right: 15px;
}
.ml15 {
  margin-left: 15px;
}
.mt20 {
  margin-top: 20px;
}
.pointer {
  cursor: pointer;
}
