@import "~@/erp/styles/variables/variables.scss";






































































































































.img-wrap {
  position: relative;
  padding: 5px 0;
  width: 50px;
  margin: 0 auto;

  .el-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    display: block;
  }

  .el-badge {
    position: absolute;
    top: 5px;
    right: -10px;
    z-index: 2;
  }
}

.img-container {
  display: flex;
  height: 600px;

  i {
    font-size: 50px;
    cursor: pointer;
    width: 50px;
    line-height: 600px;
  }

  i:hover {
    color: #409eff;
  }

  .C {
    width: 100%;

    ::v-deep {
      .el-image {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

::v-deep {
  .el-dialog__title {
    text-align: left;
    display: block;
  }

  .el-dialog__body {
    padding: 10px;
  }
}
