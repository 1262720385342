@import "~@/erp/styles/variables/variables.scss";











































































































































































































































































































































































































































































































.table-container {
  background: #fff;
  padding: 20px !important;
  height: 100vh;
  & .title {
    display: flex;
    align-items: center;
  }
  & .detail-fields {
    // background-color: #f5f7fa;
    .el-row {
      margin: 15px 0;
      & .label {
        color: #282c34;
        width: 130px !important;
        display: inline-block;
        margin-right: 15px;
      }
      & .content {
        color: #000016;
      }
    }
  }
}
.header {
  font-size: 14px;
  color: #303030;
  font-family: 'PingFang Bold';
}
.fs14 {
  font-size: 14px;
  font-family: 'PingFang Bold';
  color: #282c34;
}
.mr15 {
  margin-right: 15px;
}
.mt30 {
  margin-top: 30px;
}
.pointer {
  cursor: pointer;
}
.mb20 {
  margin-bottom: 20px;
}

.loc-content {
  max-height: 320px;
  overflow: auto;
}

.center {
  text-align: center;
}

.print-wrapper {
  width: 900px;
  margin: 0 auto;
  padding: 30px 0;

  table td {
    border: 1px solid #f0f4fb;
    padding: 14px 16px;
    color: #303030;
    box-sizing: border-box;

    &.label {
      font-size: 14px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-family: 'PingFang Bold';
      color: #303030;
    }
    &.title {
      font-family: MicrosoftYaHei;
      color: #777777;
    }
    &.bg {
      background-color: #f8fbff;
      -webkit-print-color-adjust: exact;
    }
    &.lineh-22 {
      line-height: 22px;
    }
  }
}
