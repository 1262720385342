@import "~@/erp/styles/variables/variables.scss";






















































































































































































































































































































































































































































































































































































































































































































































































































.custom-table-checkbox {
  .el-checkbox {
    display: block !important;
    margin: 8px 0 8px 10px !important;
  }
}
.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}
.width-200 {
  width: 200px;
}
::v-deep {
  .page-header-left {
    .el-input,
    .el-select {
      width: 130px;
      margin-right: 10px;
      margin-bottom: 5px;
    }
  }
}
