@import "~@/erp/styles/variables/variables.scss";
























































































































































































































.search-wrapper {
  margin-top: 15px;
}
.w150 {
  width: 150px;
}
.w160 {
  width: 160px;
}
.w130 {
  width: 130px;
}
.mr10 {
  margin-right: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
