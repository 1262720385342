@import "~@/erp/styles/variables/variables.scss";



































































































































































































































































































































































































































































































































































































































































































































.required-symbol {
  &::before {
    content: '*';
    color: #ff4d4f;
  }
}

.profit-rate {
  &::after {
    content: '%';
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }

  .el-input__inner {
    padding: 0 18px 0 15px;
  }
}

.print-form-table {
  ::v-deep.el-table {
    .el-table__header {
      border-top: 1px solid #ebeef5;
    }
  }
}
.select-box {
  display: flex;
  align-items: center;
  ::v-deep.el-tooltip {
    margin-left: 2px;
  }
}
