@import "~@/erp/styles/variables/variables.scss";






























































































































































































































































































































@page {
  size: A6;
}

.container {
  min-height: 100vh;
  height: 100vh;
  padding: 9px;
  background: #fff;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
    color 0.1s, font-size 0s;
  overflow-y: auto;
  // overflow: hidden;
}

.mt_box {
  width: 700px;
  margin: 0 auto 20px;
  font-size: 28px;
  position: relative;

  .qrcode {
    position: absolute;
    top: 120px;
    right: 40px;
  }
}

.print-content {
  letter-spacing: 1px !important;
  color: #000000 !important;
  font-weight: bold !important;
  position: relative !important;
  font-family: 'PingFang', Arial, 'Microsoft YaHei', sans-serif !important;

  .qrcode {
    position: absolute;
    top: 120px;
    right: 0;
  }
}

.mt_box .text {
  line-height: 38px;

  font-size: 28px;
  color: #000000;
  font-weight: bold;
}

.mt_box .text input {
  width: 360px;
  height: 38px;
  line-height: 38px;
  font-size: 28px;
  border: none;
  background: none;
  font-weight: bold;
}

.mt_box .text span:first-child {
  font-size: 28px;
  color: #000000;
  font-weight: bold;
}

.liId {
  list-style: none;
}

.p-span {
  position: relative;
}

.c-span {
  position: absolute;
  left: 50px;
  z-index: -1;
  user-select: none;
}

.product-img-wrapper {
  border: 1px solid #ededed;
  border-radius: 4px;
  cursor: pointer;

  position: relative;
  transition: all .3s ease-in-out;
  height: 400px;
  width: 400px;
  margin-bottom: 10px;

  &:hover {
    .el-icon-delete {
      display: inline-block !important;
    }

  }

  .v-responsive {
    transform: translate3d(0, -50%, 0);
    border-radius: 4px;
    max-height: 100%;
    max-width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    position: absolute;
    top: 50%;
    display: flex;
    width: 100% !important;

    .v-responsive__sizer {
      padding-bottom: 100%;
    }

    img {
      object-fit: contain;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
      display: block;
    }

    .el-icon-delete {
      position: absolute;
      top: 0px;
      right: 0px;
      font-size: 20px;
      display: none;
      color: red;

    }
  }
}
