@import "~@/erp/styles/variables/variables.scss";










































































































































































































































































































































































































































































































































































































































































































































































































































































































.pro-container {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;

  .page-title {
    font-size: 16px;
  }

  .pro-content {
    width: 95%;
    margin: 0 auto;
    margin-bottom: 100px;
  }

  .pro-footer {
    position: fixed;
    width: 90%;
    bottom: 0;
    text-align: center;
    padding: 20px 0;
    z-index: 999;
    background: #fff;
  }
}
