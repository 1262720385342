@import "~@/erp/styles/variables/variables.scss";




























































































































































































































































































































































































































































































































































































































































































































































.email-drawer {
  height: 100%;
  overflow: hidden;
  padding: 10px 15px;

  .email-header {
    padding-bottom: 20px;
    border-bottom: 1px solid #ededed;
  }
  .enail-content {
    height: 79%;
    border-right: 1px solid #ededed;
    .email-content-left {
      height: 100%;
      padding-right: 15px;
      border-right: 1px solid #ededed;
    }
    .search-container {
      margin-top: 30px;
      height: calc(100% - 85px);
      overflow-y: auto;
      .search-item-header {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        background: #f5f7fa;
        font-size: 12px;
        color: #303030;
        line-height: 36px;
        cursor: pointer;
        i {
          line-height: 36px;
        }
      }
      .search-item {
        width: 100%;
        padding: 7px 20px;
        background: #fff;
        font-size: 12px;
        color: #303030;
        line-height: 30px;
        position: relative;
        cursor: pointer;
        border-bottom: 1px solid #f5f7fa;
        &::before {
          content: '';
          width: 4px;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: #0486fe;
          opacity: 0;
        }
        & > div:first-child {
          width: 100%;
          display: flex;
          justify-content: space-between;
          & > div:first-child {
            .mail-item-title {
              margin-left: 10px;
              font-size: 12px;
              color: #303030;
              font-family: 'PingFang Bold';
            }
            i {
              font-size: 12px;
              color: #0486fe;
              margin-left: 5px;
            }
          }
        }
        & > div:last-child {
          display: flex;
          & > div:first-child {
            margin-right: 20px;
            i {
              color: #f7b500;
              &.looked {
                color: #d9d9d9;
              }
              &.replyed {
                color: #2eb797;
              }
              &.send {
                color: #3abc38;
              }
            }
          }

          & > div:nth-child(2) {
            flex: 1;
            width: 0;
            p {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              i {
                font-size: 12px;
                margin-right: 5px;
                &.file {
                  color: #fa6400;
                }
                &.warning {
                  color: #e02020;
                }
              }
            }
          }
        }
        & > span {
          margin-left: 15px;
        }
        &.active {
          background: #f6faff;
          &::before {
            opacity: 1;
            transition: 0.3s 0.1s;
          }
          transition: 0.3s 0.1s;
        }
        &:hover {
          background: #f6faff;
          &::before {
            opacity: 1;
            transition: 0.3s 0.1s;
          }
          transition: 0.3s 0.1s;
        }
        .mail-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .search-pagination {
      height: 98px;
      background: #fff;
      border-top: 1px solid #f5f7fa;
      & > div {
        padding: 20px;
        line-height: 36px;
        display: flex;
        justify-content: space-between;
        .page-size {
          width: 100px;
        }
        & > div {
          display: flex;
          justify-content: flex-end;
          .el-button {
            margin: 0 10px;
          }
          .el-input {
            width: 60px;
            margin: 0 6px;
            /deep/ .el-input__inner {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

// 右侧
.email-content-right {
  overflow: auto;
  height: 100%;
}
.mail-main-title {
  font-size: 18px;
  font-weight: 700;
  color: #303030;
  margin-bottom: 20px;
}
.operate-btns {
  width: 260px;
  margin-left: 30px;
}
.base-item {
  display: flex;
  margin-bottom: 10px;
  line-height: 30px;
  &.myremark {
    & > div:last-child {
      display: flex;
      .ml-5 {
        margin-left: 5px;
      }
    }
  }

  .remark-text {
    flex: 1;
    width: 0;
    display: inline-block !important;
    & > .weight {
      color: #303030;
      word-wrap: break-word;
    }
  }
  & > div:first-child {
    width: 82px;
    height: 30px;
    background: #f8fbff;
    border-radius: 3px;
    text-align: center;
    line-height: 30px;
    margin-right: 10px;
  }
  & > div:last-child {
    flex: 1;
    width: 0;
    word-wrap: break-word;
    font-size: 12px;
    color: #777;
    .weight {
      color: #303030;
    }
    .star {
      color: #fa6400;
    }
  }
}
.mail-detail-html {
  padding: 40px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #ebeef5;
  flex: 1;
}

// 附件
.mail-detail-attach {
  padding: 20px;
  box-sizing: border-box;
  & > div:nth-child(1) {
    i {
      color: #fa6400;
      font-size: 12px;
    }
    & > span {
      font-size: 12px;
      font-family: 'PingFang Bold';
      color: #303030;
      margin: 0 10px;
    }
  }
  & > div:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    & > div {
      padding: 0 12px;
      background: #f5f7fa;
      border-radius: 3px;
      border: 1px solid #ebeef5;
      font-size: 12px;
      margin-right: 10px;
      line-height: 30px;
      margin-bottom: 10px;
      cursor: pointer;
      width: 200px;
      display: flex;
      i {
        color: #fa6400;
        font-size: 12px;
      }
      .title {
        flex: 1;
        width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #303030;
        line-height: 30px;
        margin: 0 10px;
      }
      .size {
        color: #777;
      }
    }
  }
}
.btn {
  position: fixed;
  bottom: 0;
  height: auto;
  width: 100%;
  margin-top: 20px;
  padding: 15px 20px;
  box-shadow: 0px -2px 4px 0px rgba(223, 223, 223, 0.5);
}
.no-mail {
  width: 100%;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  position: relative;
  & > div {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 100px;
      height: 100px;
    }
    p {
      margin-top: 33px;
      color: #777;
      font-size: 14px;
    }
  }
}
