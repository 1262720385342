@import "~@/erp/styles/variables/variables.scss";







































































































































































































































































































































































































































































































































.left-col {
  height: 90vh;
  overflow: auto;
}
.text-hidden {
  width: 60%;
}
.right-col {
  height: 100%;
  padding: 0 20px;
  .table-box {
    height: calc(100% - 62px);
    overflow-y: auto;
  }
  .test-row {
    height: calc(100% - 52px);
    overflow-y: auto;
  }
}
.user-avatar {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  background-size: 100% 100%;
  background: #ededed;
  margin-right: 5px;
}

::v-deep {
  .el-image {
    width: 30px !important;
    height: 30px !important;
    margin-right: 5px;
    img {
      display: inline-block;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border-radius: 50%;
      background-size: 100% 100%;
      background: #ededed;
      margin-right: 5px;
    }
  }
  .el-tree-node__content {
    height: 35px;
    padding: 0 10px;
  }
  .custom-tree-node {
    span:first-child {
      font-size: 14px;
    }
  }
  .right-col-top {
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .el-tree {
    .is-current {
      > .el-tree-node__content {
        background: #ededed;
      }
    }
  }
  .el-select {
    width: 100%;
  }
  .el-tree-node__content:hover {
    background: #ededed;
  }
  .pagination-container {
    padding: 10px;
  }
  .el-pagination {
    margin-top: 0 !important;
  }
  input[type='number'] {
    -moz-appearance: textfield !important;
  }
  .custom-tree-node {
    width: 100%;
    overflow: hidden;
  }
}
.row-name {
  width: 85%;
}
.text-tooltip {
  overflow: hidden;
}
.test-span {
  flex: 1;
}
