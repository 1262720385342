@import "~@/erp/styles/variables/variables.scss";




























































































































































































































































































































































































































































































































































































































































































































































.main-height {
  height: calc(100% - 56px);
}
//自定义显示
.custom-table-checkbox {
  .el-checkbox {
    display: block !important;
    margin: 8px 0 8px 10px !important;
  }
}
.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}
.width-200 {
  width: 200px;
}
.supplierName-row {
  .supplierName-p {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
  }
}
