@import "~@/erp/styles/variables/variables.scss";














































































































































































































































































.dialog-content {
  .el-form {
    width: 95%;
    margin: 0 auto;
  }

  .box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    line-height: 32px;

    i {
      font-size: 16px;
      cursor: pointer;
      margin-left: 3px;
    }
  }

  p {
    text-indent: 80px;
    color: #e6a341;
  }

  .grade {
    margin-left: 80px;
    width: 300px;
  }
}

::v-deep {
  .el-select {
    width: 100%;
  }

  .box {
    .el-input {
      width: 300px;
    }
  }
}
