@import "~@/erp/styles/variables/variables.scss";






























































































































































































































































































































.config-table {
  .table-fixed {
    max-height: 600px !important;
    ::v-deep {
      .table-custom {
        height: 100%;
        .el-table {
          height: 100% !important;
        }
      }
      .el-tooltip {
        width: auto !important;
      }
    }
  }
}
