@import "~@/erp/styles/variables/variables.scss";








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.top-wrapper {
  display: flex;
  justify-content: space-between;
}

.mb10 {
  margin-bottom: 10px;
}

.text-ellipsis {
  width: 100%;

  span {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.custom-table-checkbox {
  .el-checkbox {
    display: block !important;
    margin: 8px 0 8px 10px !important;
  }
}

.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}

.width-200 {
  width: 200px;
}

.prod-code {
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
}

.black-span {
  white-space: nowrap;
  margin-right: 10px;
}

.search-wrapper {
  margin-top: 15px;
}

.w150 {
  width: 150px;
}

.w160 {
  width: 160px;
}

.w260 {
  width: 310px;
}

.w130 {
  width: 130px;
}

.mr10 {
  margin-right: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.precls {
  color: #303133;
  font-family: 'PingFang', Arial, 'Microsoft YaHei', sans-serif;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
