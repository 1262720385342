@import "~@/erp/styles/variables/variables.scss";






























































































.inquiry-quotation-item {
  .content {
    display: flex;

    .left {
      display: flex;
      flex-direction: column;
      color: #000;
      flex: 1;

      .top {
        margin-bottom: 4px;

        span {}
      }

      .bottom {
        color: #5e5e5e;
        font-size: 12px;

        .user {
          margin-right: 7px;
        }
      }
    }

    .right {
      padding-left: 10px;
      display: flex;
      align-items: flex-start;
    }
  }
}
