@import "~@/erp/styles/variables/variables.scss";









































































































































































































































































































































































































































































































































































































































.product-row {
  width: 100%;
  height: 100%;
  .rule-form {
    width: 100%;
    height: calc(100% - 50px);
    overflow: hidden;
    overflow-y: auto;
    .floor {
      display: flex;
      .floor-left {
        width: 10%;
        text-align: center;
        h6 {
          font-size: 16px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        h6:before {
          content: '';
          display: inline-block;
          width: 2px;
          height: 16px;
          background: #000;
          margin-right: 5px;
        }
      }
      .floor-right {
        flex: 1;
        padding-left: 30px;
        border: 1px solid #ededed;
        padding: 30px;
        padding-left: 0;
        margin-right: 15px;
      }
    }
  }
  .operate {
    position: absolute;
    height: 50px;
    line-height: 50px;
    width: 100%;
    text-align: center;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
}
