/*可以在这里添加你自己的css*/
/* .view>p:first-of-type{
  min-height: 100px;
} */
.view hr{
  display: block;
  margin: 10px 0;
  border: 0;
  width: 300px;
  border-top: 1px dashed #ccc;
}
.view section{
  margin-bottom: 20px;
}
#edui_fixedlayer{
  z-index: 9999 !important;
}
.el-form-item--small .edui-toolbar{
  line-height: 1.5;
}
.edui-editor-iframeholder {
  width: 100% !important;
}