@import "~@/erp/styles/variables/variables.scss";


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.center {
  text-align: center;
}

.dialog-content {
  height: calc(100% - 100px);
  overflow: auto;

  .el-form {
    width: 90%;
    margin: 0 auto;
  }
}

::v-deep {
  .el-select {
    width: 100%;
  }

  .logMgCls {
    .el-select__tags {
      > span {
        display: flex;
      }
    }

    .el-select__tags-text {
      display: inline-block;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .el-select .el-tag__close.el-icon-close {
      top: -5px !important;
    }
  }

  .el-textarea__inner {
    padding: 5px 50px 5px 10px;
  }
}

.nobt {
  border-top: none;
}

.wrapper2wrapper {
  padding: 10px 0;

  span {
    cursor: pointer;
    color: #1890ff;
  }
}

.down-wrapper {
  position: absolute;
  top: 40px;
  width: 100%;
  height: 200px;
  overflow: auto;
  padding: 10px;
  z-index: 9999;
  min-width: 200px;
  background: #fff;

  div {
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    span {
      flex: 1;
    }
  }
}

.suffix-tip {
  color: red;
  margin: 10px 0 0 0;
}

.title-p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-left: -32px;
}

.buttom-wrapper {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.red {
  color: #ff4d4f;
  margin-right: 5px;
}

::v-deep.required-icon {
  .el-form-item__label {
    &::before {
      content: '*';
      color: #ff4d4f;
      margin-right: 4px;
    }
  }
}
