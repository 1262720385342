@import "~@/erp/styles/variables/variables.scss";








































































































































































































.receive-item {
  text-indent: 0;
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  box-sizing: border-box;
  height: 36px;
  & > div:first-child {
    flex: 1;
    line-height: 36px;
    font-size: 14px;
    color: #303030;
    cursor: pointer;
    display: flex;
    .iconfont {
      color: #999;
    }
    .doc-item {
      margin-left: 14px;
      text-indent: 0;
      width: 120px;
      display: flex;
      align-items: center;
      .doc-name {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .warning-text {
        color: #e02020;
        margin-left: 0;
      }
    }
    .el-input {
      margin-left: 14px;
    }
    /deep/ .el-input__suffix {
      right: 1px;
    }
    /deep/ .el-input__inner {
      height: 24px;
      padding: 0 2px !important;
    }
  }
  & > .edit-btn {
    flex-shrink: 0;
    display: none;
    font-size: 14px;
    i {
      &.warning {
        margin-right: 6px;
        color: #e02020;
      }
    }
  }
  &.active {
    background: #fff;
    & > div:first-child {
      color: #0486fe;
      .iconfont {
        color: inherit;
      }
    }
  }
  &:hover {
    background: #fff;
    & > div:first-child {
      color: #0486fe;
      .iconfont {
        color: inherit;
      }
    }
    & > .edit-btn {
      display: block;
      i {
        font-size: 10px;
      }
    }
  }
  .sub-btns {
    flex-shrink: 0;
    text-indent: 0;
    margin-left: 1px;
    display: flex;
    & > div {
      padding: 0 2px;
      width: 20px;
      i {
        line-height: 36px;
        font-size: 16px;
      }
    }
  }
}
