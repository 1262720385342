@import "~@/erp/styles/variables/variables.scss";






















































































































































































































































































































































































































































































































































































































































































































































































































































.table-container {
  display: flex;
  flex-direction: column;
  > .el-row {
    flex: 1;
    > .el-col {
      height: 100%;
    }
  }
}
.public-col {
  border: 1px solid #ededed;
  padding: 10px;
  height: 100%;
  .el-row {
    margin-bottom: 20px;
    height: 30px;
  }
}
