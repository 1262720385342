@import "~@/erp/styles/variables/variables.scss";






























































































































































































.mt20 {
  margin-top: 20px;
}

.footer {
  margin-top: 15px;
}
