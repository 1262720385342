@import "~@/erp/styles/variables/variables.scss";





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.page-header {
  height: 33px;
}

::v-deep {
  .page-header {
    .el-form-item {
      margin-bottom: 0;
    }
  }
}
.divider {
  height: 1px;
  background-color: #f5f7fa;
  margin: 9px 0 15px 0;
}

.mb30 {
  margin-bottom: 30px;
}
.mt30 {
  margin-top: 30px;
}

.mb20 {
  margin-bottom: 20px;
}
.mt20 {
  margin-top: 20px;
}
.mb15 {
  margin-bottom: 15px;
}
.mt15 {
  margin-top: 15px;
}
.table-container {
  padding: 0 15px 20px !important;
}
.operation {
  display: flex;
  justify-content: space-evenly;
  i {
    font-size: 20px;
  }
}
.pointer {
  cursor: pointer;
}
.error-info {
  color: #ff4d4f;
  font-size: 12px;
  line-height: 1;
  position: relative;
  padding-top: 5px;
}

::v-deep .el-input-number--small {
  width: 100px !important;
}
::v-deep .el-input-number--small .el-input__inner {
  padding: 0 10px !important;
}
::v-deep .el-input-number--small .el-input-number__increase,
::v-deep .el-input-number--small .el-input-number__decrease {
  display: none !important;
}
::v-deep .el-input-number--small.error .el-input__inner {
  border-color: #ff4d4f;
}
