@import "~@/erp/styles/variables/variables.scss";

























































































































































































































































.card-item {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f0f4fb;
  // margin-bottom: 15px;
  cursor: pointer;
  .name {
    font-weight: 400;
    color: #282c34;
    font-size: 12px;
    display: inline-block;
    height: 17px;
    line-height: 17px;
    margin-top: 5px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 3px;
    text-align: center;
  }
  &:hover {
    box-shadow: 0px 1px 5px 0px rgba(206, 206, 206, 0.5);
    .name {
      color: #1890ff;
    }
  }
}
