@import "~@/erp/styles/variables/variables.scss";


































































































































































































.search .el-input {
  width: 300px;
}
::v-deep {
  .el-dialog__body {
    padding: 5px 20px 20px;
  }
}
