@import "~@/erp/styles/variables/variables.scss";






















































































































































































































































































.content {
  padding: 0 20px 0px;

  .detail-header {
    >.el-col {
      p {
        margin-bottom: 14px;
      }

      >span {
        font-size: 16px;
        color: #000;
      }
    }
  }

  .remark {
    >.el-col {
      p {
        margin-bottom: 14px;
      }

      >span {
        font-size: 16px;
        color: #000;
      }
    }
  }

  /deep/ .el-divider--horizontal {
    margin: 30px 0;
  }
}

::v-deep {
  .el-dialog__header {
    .el-dialog__title {
      font-size: 14px;
      color: #6f7279;
    }
  }

  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .el-dialog__footer {
    text-align: center;
  }

  .labelStyle {
    label {
      text-align: left;
    }

    .el-form-item__content {
      margin-left: 0 !important;
    }
  }

  .el-form-item--small.el-form-item {
    margin-bottom: 10px;
  }

  .el-table .el-form-item__content {
    margin-left: 0 !important;
  }
}
