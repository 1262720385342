@import "~@/erp/styles/variables/variables.scss";
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.tool-bar-wrapper {
  ::v-deep {
    .el-input-group__prepend {
      background-color: #fff;

      .el-select {
        width: 80px;

        .el-input__inner {
          padding-left: 5px;
        }
      }
    }

    .search-input {
      .el-input__inner {
        height: 34px;
      }

      .el-input-group__prepend {
        padding: 0 20px !important;
      }
    }
  }
}

.color-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: inline-block;
    padding: 0 4px;
    border-radius: 2px;
    border-color: transparent;
    height: 20px;
    line-height: 20px;
    margin-right: 10px;
    color: #fff;
  }
}

.popup {
  width: 400px;

  .checkbox-group {
    max-height: 200px;
    overflow-y: auto;
    border-bottom: 1px solid #ebebeb;

    .el-checkbox {
      display: block;
      margin-bottom: 10px;
    }
  }

  .other-item {
    line-height: 30px;
    color: #666;
    border-bottom: 1px solid #ebebeb;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: #f8f8f8;
      color: #333;
    }
  }
}

.mail-drop {
  max-height: 300px;
  overflow-y: auto;
}

.tool-bar-wrapper {

  background: #fff;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  align-items: center;

  .tool-bar {
    width: 70%;
    flex: 1;
    padding: 12px 20px;
    background: #fff;
    height: 58px;
    box-sizing: border-box;
    display: flex;
  }

  .btn {
    margin-right: 20px;
    line-height: 32px;
    cursor: pointer;
    display: flex;
    white-space: nowrap;
    user-select: none;

    &.qingjia-btn {
      margin-right: 10px;
    }

    span {
      margin-left: 5px;
      line-height: 32px;
      font-size: 14px;
      color: #333;
    }

    .iconfont {
      font-size: 16px;
      color: #ccc;
    }

    .el-dropdown {
      font-size: 14px;
    }

    .icon-inbox,
    .icon-fenpei1,
    .icon-qingjia,
    .icon-send-fill,
    .icon-n-fenpai,
    .icon-renling {
      color: #1890ff;
    }
  }

  .ml-5 {
    margin-left: 5px;
  }

  .btn-search {
    margin-left: 10px;
  }


}

.mark-search-wrapper {
  margin-bottom: 10px;
}

.tree-wrapper {
  max-height: 200px;
  overflow: auto;
  width: 100%;
}

::v-deep {
  .my-tag {
    color: #fff;
    border: none;

    .el-tag__close {
      color: #fff;
    }
  }
}
