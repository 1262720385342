@import "~@/erp/styles/variables/variables.scss";


























































































































.dialog-content {
  .el-form {
    width: 95%;
    margin: 0 auto;
  }
}
.tips {
  color: #999;
  font-size: 12px;
  padding-left: 12px;
}
::v-deep {
  .el-select {
    width: 100%;
  }
  .el-rate {
    width: 100%;
    margin-top: 6px;
  }
}
