@import "~@/erp/styles/variables/variables.scss";
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.page-header {
  box-sizing: content-box;

  /deep/ .el-form-item--small {
    margin-bottom: 0;
  }
}

.main-height {
  height: calc(100% - 100px);
}

.table-height-box {
  height: calc(100% - 4px);
  overflow: auto;
  overflow-y: auto;
}

//自定义显示
.custom-table-checkbox {
  .el-checkbox {
    display: block !important;
    margin: 8px 0 8px 10px !important;
  }
}

.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}

.width-300 {
  width: 300px;
}

.subject-dialog-content {
  .el-select--small {
    width: 360px;
  }
}

.reject-dialog-content {
  display: flex;

  .el-input--small {
    width: 360px;
    margin-left: 20px;
  }
}

.dot-span {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  font-size: 12px;
  background: #409eff;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 5px;
}

::v-deep {
  .credit {
    .el-table__body-wrapper {
      height: 650px !important;
    }

    .el-table {
      overflow: auto !important;
      height: 700px !important;
    }
  }
}
