@import "~@/erp/styles/variables/variables.scss";












































































.site-msg-wrapper {
  .main {
    margin-top: 10px;
    max-height: 200px;
    overflow: auto;
    div {
      margin-bottom: 10px;
    }
  }
}
.noline {
  text-decoration: none;
}
