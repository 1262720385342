@import "~@/erp/styles/variables/variables.scss";













































































































































































































































































.progress {
  padding-bottom: 70px;

  .main {
    padding: 0 20px;

    .step {
      padding-left: 20px;
      border-left: 2px solid #e4e7ed;
      position: relative;
      margin-bottom: 8px;

      .title {
        font-size: 14px;
        font-family: 'PingFang Bold';
        color: #303133;
      }

      .container {
        margin-top: 12px;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        font-size: 14px;

        .l-c {
          width: 70%;

          p {
            color: rgb(123, 119, 119);
            margin-bottom: 10px;
          }
        }

        .r-c {
          width: 30%;
          text-align: right;
        }
      }
    }

    .step::before {
      position: absolute;
      content: '';
      width: 12px;
      height: 12px;
      left: -7px;
      right: 0;
      border-radius: 50%;
      background: rgb(70, 166, 255);
    }
  }

  .btn {
    box-shadow: 0px -2px 4px 0px rgba(223, 223, 223, 0.5);
    padding: 15px 20px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: white;
  }
}
