@import "~@/erp/styles/variables/variables.scss";





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.table-search {
  .print-position {
    margin-left: 0 !important;
  }
  .return-discount {
    /deep/.el-form-item__content {
      display: flex;
      align-items: center;
      .el-tooltip {
        margin-left: 5px;
      }
    }
  }
}
.location-info {
  width: 128px;
  display: flex;
  align-content: center;
  border: 1px solid #dcdfe6;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  .location-btn {
    width: 40px;
    height: 16px;
    text-align: center;
    background: #f5f7fa;
    color: #606266;
    cursor: pointer;
    font-size: 13px;
    line-height: 16px;
    position: absolute;
    right: 0;
    top: 0;
    border-left: 1px solid #dcdfe6;
    &.location-btn-reduce {
      top: 16px;
    }
    &.location-btn-add {
      border-bottom: 1px solid #dcdfe6;
    }
  }
  .location-cont {
    width: 88px;
    flex-shrink: 0;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    text-align: center;
  }
}
