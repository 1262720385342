@import "~@/erp/styles/variables/variables.scss";






























.kanpan-empty-wrapper {
  display: flex;
  align-items: center;
  .fs-42 {
    color: #999999;
    font-size: 14px;
    font-size: 23px;
  }
}
