@import "~@/erp/styles/variables/variables.scss";
































































































































































































































































































































































































































































.dialog-content {
  min-height: 300px;
  .floor2,
  .floor3,
  .floor4 {
    margin: 25px 0;
    padding-top: 25px;
    border-top: 1px solid #ededed;
  }
}
.tag-row-content {
  margin-left: -10px;
  .el-tag {
    margin-bottom: 10px;
    margin-left: 10px;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
}
