@import "~@/erp/styles/variables/variables.scss";








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.proSetting-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  >.el-row {
    /* flex: 1; */
    min-height: 430px;

    &+.el-row {
      margin-top: 10px;
    }

    >.el-col {
      height: 100%;
    }
  }
}

.public-col {
  height: 100%;
  border: 1px solid #ededed;
  padding: 10px;
  display: flex;
  flex-direction: column;

  p {
    line-height: 32px;

    &.tip {
      margin-top: 30px;
    }

    .invalid-day {
      font-size: 20px;
      color: #333;
      margin: 0 4px;
    }
  }

}

.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-bottom {
  margin-top: 2vh;
}

.clerk-setting {
  margin: 15px 0;
}

// 关键词table
.keywordTable {
  /deep/ td.el-table__cell > .cell {
    white-space: pre; // 识别出每个空格
  }
}
