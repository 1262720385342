@import "~@/erp/styles/variables/variables.scss";

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.page-header {
  /deep/ .el-button,
  /deep/ .el-form {
    margin-bottom: 10px;
  }
}
.no-underline {
  text-decoration: none;
}

.tip-content {
  line-height: 18px;

  .el-tag--small {
    height: 12px;
    line-height: 12px;
    font-size: 12px;
    padding: 0;
    background: none;
    border: none;
  }
}

.tip-content + .tip-content {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px dashed #999;
}

.cancellation-dialog {
  .container {
    margin-bottom: 20px;

    .el-input-number {
      width: 120px;
      margin: 0 5px;
    }

    .el-checkbox-group {
      line-height: 20px;
    }

    p {
      line-height: 20px;
    }
  }
}

.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}

.expand-all {
  cursor: pointer;
  padding: 5px;
  transition: 0.2s;

  &.active {
    transform: rotate(90deg);
  }
}

::v-deep {
  .el-table__row .el-button {
    margin: 0 5px;
  }
}

.colorblue {
  color: #1890ff;
  cursor: pointer;
}

.settleTip {
  max-width: 600px;
}

.statusTag {
  text-align: left;
  color: #333333;

  .textBg {
    box-sizing: border-box;
    padding: 1px 1px;
    margin-right: 5px;
    font-size: calc(12px * 0.8);
    color: #fff;
    border-radius: 2px;
  }
}
