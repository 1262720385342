@import "~@/erp/styles/variables/variables.scss";













































































































.ticket-management-edit{
  .ticket-management-edit-cont{
    padding: 0 20px;
  }
  .file-box{
    padding:20px;
  }
  .pro-footer {
    position: fixed;
    width: 90%;
    bottom: 0;
    text-align: center;
    padding: 20px 0;
    z-index: 999;
    background: #fff;
  }
}
