@import "~@/erp/styles/variables/variables.scss";

























































































































































































































































































































































































































































































.person-center {
  .content-box {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    background-color: #f6f8f9;
    overflow-y: auto;
    padding-bottom: 80px;
    .blank-content {
      width: 60%;
      // height: calc(100vh - 720px);
      background: #fff;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      padding: 15px 30px;
      border-radius: 6px;
      // overflow-y: auto;
      .blank-content-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
      }
    }
    .content {
      width: 60%;
      height: 650px;
      background: #fff;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      padding: 15px 30px;
      border-radius: 6px;
      margin-bottom: 10px;
      .header {
        border-bottom: 1px solid #ededed;
        padding-bottom: 15px;
        margin-bottom: 25px;
        .title {
          font-family: 'PingFang Bold';
        }
      }
      .user-img {
        .user-name-content {
          .name {
            font-size: 15px;
            color: #000;
          }
          .span-tag {
            background: #ff854c;
            color: #fff;
            font-size: 12px;
            padding: 3px 5px;
            border-radius: 3px;
          }
        }
      }
      .other-msg {
        padding-top: 22px;
        padding-bottom: 22px;
        border-bottom: 1px solid #ededed;
        span {
          margin-left: 15px;
        }
      }
      .other-msg-floor {
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }
  }
}
.headeurl {
  position: relative;
  cursor: pointer;
  .mock {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
    line-height: 80px;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    display: none;
  }
}
.headeurl:hover {
  .mock {
    display: block;
  }
}
.user-avatar {
  display: inline-block;
  width: 80px;
  height: 80px;

  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  background-size: 100% 100%;
  border: 1px solid #ededed;
}
.w80 {
  width: 80%;
}
