@import "~@/erp/styles/variables/variables.scss";


























































































































.reset-password {
  .box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    line-height: 32px;

    .text {
      width: 80px;
      text-align: right;
      font-family: 'PingFang Bold';
    }

    i {
      font-size: 16px;
      cursor: pointer;
      margin-left: 3px;
    }
  }

  p {
    text-indent: 90px;
    color: #e6a341;
    font-size: 14px;
  }

  .grade {
    margin-left: 90px;
    width: 382px;
  }
}

::v-deep {
  .el-input {
    width: 380px;
  }

  .el-form-item__label {
    ::before {
      display: none;
    }
  }
}
