@import "~@/erp/styles/variables/variables.scss";












































































































































































































.dialog-content {
  .el-row {
    span {
      color: #666;
    }
    p {
      font-size: 16px;
      color: #000;
      margin-top: 10px;
    }
  }
  .row-line {
    padding-bottom: 20px;
    border-bottom: 1px solid #eaebed;
  }
  .step-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    thead {
      background: #f5f7fa;
    }
    td {
      height: 38px;
      line-height: 38px;
      text-align: center;
      padding: 0 10px;
      border: 1px solid #ebeef5;
    }
  }
}
.w70 {
  width: 70px;
}
