@import "~@/erp/styles/variables/variables.scss";
























































































































































































































































































































































































































































































.box {
  width: 100%;
  height: 100%;
}

.mail-detail {
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .assign-icon {
    text-align: right;

    i {
      cursor: pointer;
      color: #1890ff;
    }
  }

  .mail-detail-base {
    border-bottom: 1px solid #f5f7fa;
    box-sizing: border-box;

    .title-box {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      margin-bottom: 10px;

      .title {
        font-size: 18px;
        font-weight: bold;
        color: #303030;
        max-width: 150em;
        word-break: break-all;
      }

      .order-num {
        padding: 0 3px;
        white-space: nowrap;
        color: #1890ff;
        background: #e8f4ff;
        font-size: 14px;
        margin-left: 10px;

        .icon-close {
          color: #ccc;
        }
      }

      .label-item {
        display: inline-block;
        margin-left: 10px;
        height: 20px;
        font-size: 12px;
        color: #fff;
        padding: 0 4px;
        border-radius: 2px;
        max-width: 100%;
        line-height: 20px;

        .label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          vertical-align: middle;
        }

        .remove-icon {
          margin-left: 4px;
          cursor: pointer;
          display: none;
          vertical-align: middle;
        }

        &:hover {
          .remove-icon {
            display: inline-block;
          }
        }
      }
    }

    .operate-btns {
      margin-left: 20px;

      .el-button {
        width: 28px;
        height: 28px;
        padding: 4px;
        font-size: 18px;
      }
    }

    .info-wrapper {
      display: flex;

      .info-main {
        flex: 1;
        width: 200px;
        margin-bottom: 10px;
      }

      .info-simple {
        font-size: 14px;
        color: #303030;
        line-height: 24px;
        word-break: break-all;

        .desc {
          color: #999;
        }
      }

      .btn-key {
        font-size: 14px;
        color: #999;
        line-height: 24px;
        cursor: pointer;

        &:hover {
          color: #1890ff;
        }
      }
    }

    .base-item {
      display: flex;
      margin-bottom: 10px;
      line-height: 24px;

      dt {
        width: 82px;
        height: 24px;
        background: #f8fbff;
        border-radius: 3px;
        text-align: center;
        line-height: 24px;
        margin-right: 10px;
      }

      dd {
        flex: 1;
        width: 0;
        word-wrap: break-word;
        font-size: 12px;
        color: #777;

        .weight {
          color: #303030;
        }

        .star {
          color: #fa6400;
        }
      }
    }

    .myremark {
      background-color: #f8f8f8;
      padding: 4px 6px;
      margin-top: 6px;

      .remark-text {
        display: flex;
        align-items: flex-end;

        .weight {
          font-size: 14px;
          line-height: 28px;
          word-wrap: break-word;
          flex: 1;
          width: 200px;
        }
      }

      .remark-form {
        display: flex;
        align-items: center;

        .input {
          flex: 1;
          margin-right: 10px;
        }
      }
    }
  }

  .mail-iframe {
    flex: 1;
    height: 500px;
  }

  .mail-detail-attach {
    padding: 20px;
    box-sizing: border-box;

    .attach-title {
      margin-bottom: 10px;

      i {
        color: #fa6400;
        font-size: 12px;
      }

      &>span {
        font-size: 12px;
        font-weight: bold;
        color: #303030;
        margin: 0 10px;
      }
    }

    .attach-list {
      display: flex;
      flex-wrap: wrap;

      &>div {
        padding: 0 12px;
        background: #f5f7fa;
        border-radius: 3px;
        border: 1px solid #f5f7fa;
        font-size: 12px;
        margin-right: 10px;
        line-height: 30px;
        margin-bottom: 10px;
        cursor: pointer;
        width: 200px;
        display: flex;

        i {
          color: #fa6400;
          font-size: 12px;
        }

        .title {
          flex: 1;
          width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #303030;
          line-height: 30px;
          margin-left: 10px;
        }

        .preview {
          color: #1890ff;
          margin-left: 10px;
        }

        .size {
          color: #777;
          margin-left: 10px;
        }
      }
    }
  }

  .mail-deatil-bottom {
    overflow-y: auto;
    max-height: 120px;
  }
}

.no-mail {
  width: 100%;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  position: relative;

  &>div {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 100px;
      height: 100px;
    }

    p {
      margin-top: 33px;
      color: #777;
      font-size: 14px;
    }
  }
}
