@import "~@/erp/styles/variables/variables.scss";



















































































































































































































::v-deep {
  .el-input-group__prepend {
    background-color: #fff;
    .el-select {
      width: 80px;
      .el-input__inner {
        padding-left: 5px;
      }
    }
  }
  .el-range-separator {
    width: 2em;
  }
}
.tool-bar {
  display: flex;
  align-items: center;
  > .el-select {
    margin-right: 10px;
  }
  ::v-deep .type-select {
    .el-input-group__prepend {
      padding: 0 20px !important;
    }
  }
}
