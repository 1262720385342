@import "~@/erp/styles/variables/variables.scss";


























i {
  cursor: pointer;
}
