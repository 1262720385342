@import "~@/erp/styles/variables/variables.scss";





































.inspection-container {
  padding: 0 !important;
  .el-header {
    border-bottom: 1px solid #f5f7fa;
    display: flex;
    align-items: center;
    height: 50px !important;
  }
  .el-main {
    border-left: 1px solid #f5f7fa;
  }
  .el-container .el-container {
    height: 500px;
  }
}
