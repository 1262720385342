@import "~@/erp/styles/variables/variables.scss";





































































































































































































































































































































::v-deep {
  .el-dialog__body {
    padding: 0 5px 20px 20px;
    .detail-style {
      .el-button {
        padding: 0px 9px;
        margin-left: 5px;
        span {
          font-size: 12px;
        }
      }
    }
  }
}
.container {
  min-height: 360px;
  max-height: 600px;
  overflow-y: scroll;
  padding-right: 5px;
  i {
    font-size: 16px;
    color: #ffba00;
    font-weight: 600;
  }
  .detail-style {
    padding: 0;
    .item {
      min-width: 200px;
      max-width: none;
      em {
        padding: 0;
      }
      span {
        font-size: 14px;
      }
    }
  }
  .img-wrap {
    position: relative;
    .el-image {
      width: 56px;
      height: 56px;
      object-fit: cover;
      display: block;
      margin: 0 auto;
      border-radius: 2px;
    }
    .el-badge {
      position: absolute;
      top: -4px;
      right: 12px;
      z-index: 2;
    }
  }
  .el-divider {
    margin: 30px 0;
  }
  > div:last-child .el-divider {
    display: none;
  }
}
