@import "~@/erp/styles/variables/variables.scss";










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.main-height {
  height: calc(100% - 56px);
}
.tab-sapn {
  p {
    flex: 1;
    text-align: center;
    span {
      display: inline-block;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      padding: 0 10px;
      text-align: center;
      cursor: pointer;
    }
    .current {
      color: #409eff;
      border-bottom: 2px solid #409eff;
    }
  }
}

.text-ellipsis {
  width: 100%;
  span {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
//自定义显示
.custom-table-checkbox {
  .el-checkbox {
    display: block !important;
    margin: 8px 0 8px 10px !important;
  }
}
.save-btn {
  border-top: 1px solid #ececec;
  padding-top: 10px;
  text-align: center;
}
.width-200 {
  width: 200px;
}
// 编码、公司名称
.prod-code {
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
}
.black-span {
  white-space: nowrap;
  margin-right: 10px;
}
