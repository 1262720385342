@import "~@/erp/styles/variables/variables.scss";


































































































































































































































































































































.custom-container {
  overflow-y: hidden !important;
  position: relative;
}
