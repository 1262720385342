@import "~@/erp/styles/variables/variables.scss";










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.pro-container {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  .pro-footer {
    position: fixed;
    width: 90%;
    bottom: 0;
    text-align: center;
    padding: 20px 0;
    z-index: 999;
    background: #fff;
  }
  .bor {
    border: 1px solid #e4e7ed;
    border-radius: 2px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  .divider-line {
    width: 100%;
    height: 0;
    border-top: 1px dashed #e4e7ed;
  }
  .platform-panel-title {
    font-weight: normal;
    padding-left: 20px;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    border-bottom: 1px solid #e4e7ed;
    background: #f5f7fa;
  }
  .pro-content {
    width: 95%;
    margin: 0 auto;
    margin-bottom: 100px;
    .themes-demo {
      ::v-deep .el-form-item__content {
        .el-select__tags-text {
          display: inline-block;
          max-width: 100%;
          overflow: unset;
          text-overflow: unset;
        }
      }
    }
    .left-h6 {
      display: flex;
      font-size: 16px;
      align-items: center;
      font-family: 'PingFang Bold';
      padding: 0 10px;
      &::before {
        content: '';
        display: block;
        height: 16px;
        width: 2px;
        background: #282c34;
        margin-right: 5px;
      }
    }
    .right-content {
      height: 100%;
      padding: 20px;
      border: none;
      .platform-info {
        display: flex;
      }
      .platform-info-tab {
        width: 60px;
        flex-shrink: 1;
        min-height: 500px;
        // border-right: 1px solid #e4e7ed;
        .platform-info-tab-item {
          height: 40px;
          line-height: 40px;
          border-right: 2px solid transparent;
          margin-right: -1px;
          color: #999;
          &.active {
            color: rgb(24, 144, 255);
            border-right-color: rgb(24, 144, 255);
          }
        }
      }
      .platform-panel {
        width: calc(100% - 60px);
        flex: 1;
        padding-left: 20px;
        padding-top: 10px;
        .basic-information-cont {
          padding: 20px 20px 0 0;
          .working-days {
            display: flex;
            align-items: center;
            ::v-deep.el-input {
              width: 60px;
            }
            .working-days-text {
              margin-bottom: 18px;
              margin-left: 10px;
            }
            .max-prodTime {
              ::v-deep.el-form-item__label {
                &::before {
                  display: none;
                }
              }
            }
          }
        }
        .print-information-box {
          .setup-charge-top-box {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            padding-right: 20px;
            .setup-charge-tit {
              width: 100px;
              text-align: center;
            }
          }
          .print-information-cont {
            padding-right: 20px;
            .refresh-box {
              ::v-deep.el-button {
                margin: -6px 0 0 10px;
                i {
                  font-size: 24px;
                }
              }
            }
          }
        }
        .other-information {
          .other-information-cont {
            padding-top: 18px;
            .other-information-input {
              padding-right: 50px;
              ::v-deep.el-select {
                width: 100%;
              }
              ::v-deep.el-input {
                width: 100%;
              }
            }
            .otehrInfo-tags {
              display: inline-block;
              width: 25%;
              &.highlight {
                ::v-deep.el-checkbox__label {
                  color: rgb(217, 0, 27);
                }
              }
            }
          }
        }
        .image-information-cont {
          padding: 20px;
          .image-information-panel {
            width: 100%;
            display: flex;
            border-radius: 2px;
            position: relative;
            box-sizing: border-box;
            .image-information-panle-box {
              display: flex;
              max-width: calc(100% - 150px);
              overflow-x: auto;
            }
            .bor-right {
              border-right: 1px solid #e4e7ed;
              border-left: none !important;
            }
            .bor-left {
              border-left: 1px solid #e4e7ed !important;
            }
            .drag-box {
              display: flex;
              &.cursor-scroll {
                .image-information-item {
                  cursor: all-scroll;
                }
              }
            }
            .image-information-item {
              position: relative;
              .upload-limit {
                position: absolute;
                right: 0;
                top: 2px;
                z-index: 2;
                cursor: pointer;
                color: red;
              }
              ::v-deep.el-form-item {
                margin: 0;
                width: 100%;
                height: 100%;
                .el-form-item__content {
                  width: 100%;
                  height: 100%;
                  line-height: 26px !important;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                  .el-input {
                    .el-input__inner {
                      padding: 0 4px;
                      line-height: 26px !important;
                      height: 26px !important;
                    }
                  }
                }
              }
              .tit-bg {
                background: #f5f7fa;
                line-height: 26px;
                border-left: 1px solid #e4e7ed;
              }
              .image-information-item-image {
                width: 101px;
                height: 102px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 1px solid #e4e7ed;
                border-left: 1px solid #e4e7ed;
                border-top: 1px solid #e4e7ed;
                box-sizing: border-box;
                padding: 12px;
                position: relative;
                .upload-mask {
                  position: absolute;
                  left: 12px;
                  top: 12px;
                  right: 12px;
                  bottom: 12px;
                  color: #fff;
                  opacity: 0;
                  font-size: 20px;
                  background-color: rgba(0, 0, 0, 0.5);
                  transition: opacity 0.3s;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  z-index: 3;
                  cursor: default;
                  .upload-item-delete {
                    margin-left: 10px;
                    cursor: pointer;
                  }
                  .upload-item-repeta {
                    margin-left: 10px;
                    cursor: pointer;
                  }
                  .upload-item-preview {
                    cursor: pointer;
                  }
                  &:hover {
                    opacity: 1;
                  }
                }
                .imgage-uploader {
                  width: 100%;
                  height: 100%;
                  ::v-deep.el-upload {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                }
              }
              .image-information-item-caption {
                width: 101px;
                height: 32px;
                box-sizing: border-box;
                padding: 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 1px solid #e4e7ed;
                border-left: 1px solid #e4e7ed;
              }
              .image-information-item-is-primary {
                width: 101px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 1px solid #e4e7ed;
                border-left: 1px solid #e4e7ed;
              }
              .image-information-item-has-logo {
                width: 101px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-left: 1px solid #e4e7ed;
                border-bottom: 1px solid #e4e7ed;
              }
            }
          }
        }
        .basic-information {
          .price-information-cont {
            padding-top: 5px;
            padding-bottom: 15px;
            .pricecode-tooltip {
              font-size: 20px;
              cursor: pointer;
            }
            .quantity-info-box {
              display: flex;
              align-items: center;
              margin-top: 15px;
              .quantity-info-label {
                width: 140px;
                flex-shrink: 0;
                padding: 0 10px;
                text-align: right;
                margin-bottom: 5px;
                // &.catalog-price-lable {
                //   margin-bottom: 18px;
                // }
              }
              .quantity-info-cont {
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                padding-right: 10px;
                &.catalog-price-box {
                  ::v-deep.el-form-item {
                    margin-bottom: 0;
                    .el-form-item__error {
                      padding-top: 0;
                    }
                  }
                }
                ::v-deep.el-checkbox-group {
                  display: flex;
                  flex-wrap: wrap;
                  .el-checkbox {
                    width: 120px;
                    height: 32px;
                    border: 1px solid #e4e7ed;
                    margin-right: 10px;
                    border-radius: 2px;
                    box-sizing: border-box;
                    padding: 0 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 5px;
                    .el-checkbox__input {
                      margin-top: 2px;
                    }
                    .el-checkbox__label {
                      line-height: 32px;
                    }
                  }
                }
                ::v-deep.el-input {
                  width: 120px;
                  height: 32px;
                  padding: 0;
                  margin-right: 10px;
                  margin-bottom: 5px;
                  box-sizing: border-box;
                  .el-input__inner {
                    width: 120px;
                    height: 32px;
                    line-height: 32px;
                    padding: 0 4px;
                    box-sizing: border-box;
                  }
                }
              }
              &.price-includes-box {
                .quantity-info-cont {
                  ::v-deep.el-input {
                    width: 120px;
                    .el-input__inner {
                      width: 120px;
                    }
                  }
                  ::v-deep.el-checkbox {
                    margin-top: 4px;
                  }
                }
              }
            }
            .options-box {
              padding: 20px 10px 20px 0;
              .add-options-box {
                display: flex;
                align-items: center;
                .add-options-tit {
                  width: 80px;
                  text-align: center;
                  flex-shrink: 0;
                }
                .add-options-btn {
                  flex-shrink: 0;
                  margin-right: 10px;
                }
              }
              .options-item {
                .options-item-top {
                  padding: 20px 80px 0;
                  width: 450px;
                }
                .options-item-cont {
                  display: flex;
                  align-items: center;
                  overflow-x: auto;
                  .delete-box {
                    flex-shrink: 0;
                    width: 80px;
                    text-align: center;
                    color: #ff4d4f;
                    .iconfont {
                      font-size: 28px;
                      cursor: pointer;
                    }
                    &.disable {
                      opacity: 0.3;
                      .iconfont {
                        font-size: 28px;
                        cursor: default;
                      }
                    }
                  }
                  .ptions-item-table {
                    ::v-deep.el-table__cell {
                      padding: 0;
                    }
                    ::v-deep.el-form-item {
                      margin: 14px 0;
                      .el-input__inner {
                        padding: 0 5px;
                      }
                    }
                    .table-delete-btn {
                      color: #666;
                      &.disable {
                        opacity: 0.5;
                      }
                    }
                    ::v-deep.el-button {
                      text-decoration: underline;
                      margin: 10px 0;
                    }
                  }
                  .ptions-item-info {
                    align-self: flex-start;
                    padding: 0 20px;
                    .quantity-info-box {
                      margin-top: 0;
                    }
                    .quantity-info-cont {
                      margin-left: 10px;
                    }
                    .ptions-item-radio-box {
                      display: flex;
                      align-items: center;
                      margin-top: 10px;
                      ::v-deep.el-radio {
                        display: flex;
                        align-items: center;
                        .el-radio__label {
                          display: flex;
                          align-items: center;
                          .el-tooltip {
                            margin-left: 5px;
                          }
                        }
                      }
                    }
                  }
                }
                .divider-line-box {
                  display: flex;
                  align-items: center;
                  .divider-line-left {
                    width: 80px;
                    height: 0;
                    flex-shrink: 0;
                  }
                }
              }
              .setup-charge-box {
                margin-top: 12px;
                .setup-charge-top-box {
                  display: flex;
                  align-items: center;
                  margin-bottom: 20px;
                  .setup-charge-tit {
                    width: 140px;
                    text-align: center;
                  }
                }
                .setup-charge-cont {
                  padding: 0 20px;
                  ::v-deep.el-form-item__content {
                    display: flex;
                    align-items: center;
                    .setup-charge-cont-input {
                      max-width: 180px;
                    }
                    .setup-charge-cont-v {
                      width: 60px;
                      margin: 0 5px;
                      .el-input__inner {
                        padding: 0 5px;
                      }
                    }
                  }
                  .run-chgs-box {
                    display: flex;
                    align-items: center;
                    ::v-deep.el-input {
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
