@import "~@/erp/styles/variables/variables.scss";




































































































































































































































.default-el-col {
  ::v-deep .el-form-item__content {
    margin-left: 10px !important;
  }
}
.profit-cont-title {
  display: flex;
  align-items: center;
  .left-h6 {
    display: flex;
    font-size: 16px;
    font-family: 'PingFang Bold';
    line-height: 30px;
    margin-right: 10px;
    &::before {
      content: '';
      display: block;
      height: 26px;
      width: 4px;
      background: rgba(2, 125, 180, 1);
      margin-right: 5px;
    }
  }
}
.step-panle {
  display: flex;
  align-items: center;
  ::v-deep .el-form-item {
    margin-bottom: 0;
  }
  .symbol {
    margin-left: 10px;
  }
}
