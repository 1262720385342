@import "~@/erp/styles/variables/variables.scss";






























































































































.customer-follow-reminder-item {
  font-size: 14px;
  .reminder-item--top {
    color: #282c34;
    margin-bottom: 6px;
    font-size: 14px;
  }
  .reminder-item--bottom {
    font-size: 12px;
    color: #5e5e5e;
    .mr-5 {
      margin-right: 5px;
    }
  }
}
