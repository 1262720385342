@import "~@/erp/styles/variables/variables.scss";



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.mr15 {
  margin-right: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.el-table {
  width: 100%;
  margin-bottom: 20px;
}
.main-height {
  height: calc(100% - 52px);
}
.table-height-box {
  height: calc(100% - 4px);
  overflow: hidden;
  overflow-y: auto;
}
.col {
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
}
.upper-limit {
  /* border: 1px solid #ededed; */
  /* padding: 10px; */
  /* margin-bottom: 20px; */
  .desc {
    line-height: 32px;
    font-size: 14px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    strong {
      margin-right: 10px;
    }
    /* > span {
      flex: 1;
    } */
  }
}
.public-col {
  border: 1px solid #ededed;
  padding: 10px;
  flex: 1;
  max-height: 80vh;
  overflow: hidden;
  overflow-y: auto;

  p {
    line-height: 32px;
  }
}
.public-col2 {
  border: 1px solid #ededed;
  padding: 10px;
  p {
    line-height: 32px;
  }
}
::v-deep {
  .el-tag {
    border: none;
  }
  .el-tag--plain {
    background: none;
  }
}
.pl10 {
  padding-left: 10px;
}
ul {
  list-style: none;
}
.mt20 {
  margin-top: 20px;
}
