@import "~@/erp/styles/variables/variables.scss";





























































































































































































































































.search_form .el-date-editor--datetimerange.el-input__inner {
  width: 100%;
}
::v-deep {
  .el-date-editor {
    width: 100%;
  }
  .el-divider {
    margin: 30px 0 28px 0 !important;
  }
  .el-drawer__body {
    height: calc(100% - 44px);
  }
}
