@import "~@/erp/styles/variables/variables.scss";











































































































































































.change-wrapper {
  display: flex;
  margin-top: 10px;
  margin-left: 10px;
  align-items: center;
  p {
    margin-right: 10px;
  }
  i {
    margin: 0 5px;
  }
}
.mr15 {
  margin-right: 15px;
}
