@import "~@/erp/styles/variables/variables.scss";















































































































































































































































































































































































































::v-deep {
  .el-cascader,
  .el-select {
    width: 100%;
  }
}
