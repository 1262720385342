@import "~@/erp/styles/variables/variables.scss";




















































































































.box-card {
  background-color: #fff;
  padding: 20px;
  padding-top: 16px;
  padding-bottom: 0;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  box-sizing: border-box;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #eaeaea;
    .title {
      height: 22px;
      font-size: 14px;
      font-weight: 600;
      color: #282c34;
      line-height: 22px;
    }
  }
  .role-tabs {
    margin: 15px 0;
    margin-bottom: 0;
    margin-right: 8px;
    box-sizing: border-box;
    border-bottom: 1px solid #eaeaea;
    span.text {
      display: inline-block;
      border-top: 1px solid #eaeaea;
      padding: 2px 5px;
      cursor: pointer;
      color: #999999;
      &.active {
        background-color: #dff0ff;
        color: #236ce9;
      }
    }
    .role-tabs--me {
      border-top-left-radius: 8px;
      border-left: 1px solid #eaeaea;
    }
    .role-tabs--group {
      border-top-right-radius: 8px;
      border-right: 1px solid #eaeaea;
    }
    .count-tag {
      border-radius: 17px;
      background: #ffeaea;
      border: 1px solid #ffcccc;
      color: #f56c6c;
      display: inline-block;
      padding: 0 8px;
      text-align: center;
      white-space: nowrap;
      border: 1px solid #fff;
      transform: scale(0.83);
      span {
        display: inline-block;
        font-size: 12px;
        line-height: 12px;
      }
      &.active {
        background-color: #f56c6c;
        color: #fff;
      }
    }
  }
  .card-content {
    height: 253px;
    margin-right: -8px;
    padding-right: 8px;

    padding-top: 15px;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .operation {
    i.icon-yidongdao {
      color: #666;
    }
    i.icon-yichu {
      color: #f56c6c;
    }
    .handle {
      margin-right: 15px;
    }
    i {
      font-size: 14px;
      cursor: pointer;
    }
  }
}
